import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Modal,
  Badge,
} from "@mui/material";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { message, Tooltip } from "antd";
import linkContext from "../../context/links/linkContext";
import { FaVideo } from "react-icons/fa";
import { FaLinkSlash } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { MdLogout, MdDashboard, MdOutlineReport } from "react-icons/md";
import Cookies from "js-cookie";
import LinksPopover from "./LinksPopover";
import { TbSpeakerphone } from "react-icons/tb";
import { FaPlus } from "react-icons/fa6";
import AddFeaturesModal from "../NewFeaturesUpdates/AddFeaturesModal";
import ShowingNewFeaturesModal from "../NewFeaturesUpdates/ShowingNewFeaturesModal";
import ReportAnIssueModal from "./ReportAnIssueModal";

const Navbar = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(linkContext);
  let {
    userDetails,
    previewUrl,
    user,
    badgeCount,
    resetBadgeCount,
    getAllNewFeaturesData,
  } = context;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(null);
  const [openFeatureModal, setOpenFeatureModal] = useState(false);
  const [openReportAnIssue, setOpenReportAnIssue] = useState(false);

  const extractUserId = (url) => {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  };

  const userName = extractUserId(previewUrl);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenUpdateModal = (event) => {
    setOpenUpdateModal(event.currentTarget);
  };
  const handleCloseUpdateModal = () => setOpenUpdateModal(null);

  const handleOpenFeatureModal = () => {
    setOpenFeatureModal(true);
  };

  const handleCloseFeatureModal = () => {
    setOpenFeatureModal(false);
  };

  const openPopover = Boolean(openUpdateModal);
  const id = openPopover ? "simple-popover" : undefined;

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminToken");
    Cookies.remove("cbxtree");
    Cookies.remove("userInfo");
    success("Logout successfully");
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  useEffect(() => {
    userDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (e) => {
    e.stopPropagation();
    navigate("/link");
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (window.googleTranslateElementInit) {
      window.googleTranslateElementInit();
    }
  }, []);

  return (
    <Box>
      {contextHolder}

      <Box
        className="navMainContainer"
        sx={{
          width: "100%",
          p: "10px 0px",
          // position: "fixed",
          position: "sticky",
          top: 0,
          zIndex: 999,
          background: "#fff",
        }}
      >
        <Box
          sx={{
            width: "95%",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Typography className="comName" fontSize={"28px"}>
                CBX<span style={{ color: "#0FB3E2" }}>TREE</span>
              </Typography>
            </Link>
          </Box>

          {/* {location.pathname === "/" && !token && (
            <Box display={"flex"} alignItems={"center"} gap={"25px"}>
              <a
                href="#features"
                onClick={scrollToSection}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className="navItems">Features</Typography>
              </a>
              <a
                href="#reviews"
                onClick={scrollToSection}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className="navItems">Reviews</Typography>
              </a>
              <a
                href="#faqs"
                onClick={scrollToSection}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography className="navItems">FAQ’s</Typography>
              </a>
            </Box>
          )} */}
          <Box></Box>

          {token && location.pathname !== "/users" && (
            <Box display={"flex"} alignItems={"center"} gap={"25px"}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FaVideo style={{ cursor: "pointer" }} />
                <Typography
                  className="navItems"
                  onClick={handleOpen}
                  sx={{ fontSize: "16px" }}
                >
                  Instruction Video
                </Typography>
              </Box>

              <Box
                onClick={(e) => handleNavigate(e)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  position: "relative",
                  zIndex: 100,
                }}
              >
                <FaLinkSlash
                  style={{
                    color: location.pathname === "/link" ? "#00bce0" : "",
                  }}
                />

                <Typography
                  onClick={(e) => handleNavigate(e)}
                  className={
                    location.pathname === "/link" ? "active" : "navItems"
                  }
                >
                  Links
                </Typography>
                <LinksPopover userName={userName} isHovered={isHovered} />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Link
                  to="/settings"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <IoMdSettings
                    style={{
                      marginBottom: "2px",
                      color: location.pathname === "/settings" ? "#00bce0" : "",
                    }}
                  />
                </Link>
                <Link
                  to="/settings"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    className={
                      location.pathname === "/settings" ? "active" : "navItems"
                    }
                  >
                    Settings
                  </Typography>
                </Link>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Link
                  to="/dashboard"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <MdDashboard
                    style={{
                      marginBottom: "2px",
                      color:
                        location.pathname === "/dashboard" ? "#00bce0" : "",
                    }}
                  />
                </Link>
                <Link
                  to="/dashboard"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    className={
                      location.pathname === "/dashboard" ? "active" : "navItems"
                    }
                  >
                    Dashboard
                  </Typography>
                </Link>
              </Box>
            </Box>
          )}

          {!localStorage.getItem("token") &&
          !localStorage.getItem("adminToken") ? (
            <Box display={"flex"} alignItems={"center"} gap={"20px"}>
              {/* <LanguageSelector /> */}
              <div id="google_translate_element"></div>
              <Link to="/login">
                <Button
                  sx={{
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  className={
                    location.pathname === "/login"
                      ? "activeLogIn"
                      : "navLoginInItem"
                  }
                >
                  Log in
                </Button>
              </Link>
              <Link to="/signup">
                <Button
                  sx={{ fontSize: "18px" }}
                  className={
                    location.pathname === "/signup"
                      ? "signup-active"
                      : "signButton"
                  }
                >
                  Sign up for free
                </Button>
              </Link>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Tooltip title="Report an issue">
                  <IconButton
                    onClick={() => setOpenReportAnIssue(true)}
                    sx={{
                      background: "#00bce0",
                      color: "#fff",
                      "&:hover": { background: "#00bce0" },
                    }}
                    size="small"
                  >
                    <MdOutlineReport />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="See New Features">
                  <Badge
                    onClick={() => {
                      resetBadgeCount();
                      getAllNewFeaturesData();
                    }}
                    badgeContent={badgeCount}
                    color="primary"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#f52bff",
                        color: "white",
                      },
                    }}
                  >
                    <IconButton
                      aria-describedby={id}
                      onClick={handleOpenUpdateModal}
                      sx={{
                        background: "#00bce0",
                        color: "#fff",
                        "&:hover": { background: "#00bce0" },
                      }}
                      size="small"
                    >
                      <TbSpeakerphone />
                    </IconButton>
                  </Badge>
                </Tooltip>
                {/* Showing new feature modal */}
                <ShowingNewFeaturesModal
                  openUpdateModal={openUpdateModal}
                  handleCloseUpdateModal={handleCloseUpdateModal}
                  openPopover={openPopover}
                  id={id}
                />
              </Box>
              <div id="google_translate_element"></div>
              {token && location.pathname === "/users" && (
                <Box>
                  <Button
                    onClick={handleOpenFeatureModal}
                    sx={{ fontSize: "16px" }}
                    startIcon={
                      <FaPlus size={16} style={{ color: "#00bce0" }} />
                    }
                    className="signup-active"
                  >
                    Add Updates
                  </Button>
                </Box>
              )}
              <Button
                sx={{ fontSize: "16px" }}
                startIcon={<MdLogout size={16} style={{ color: "#00bce0" }} />}
                onClick={handleLogout}
                className="signup-active"
              >
                Logout
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {/* Small screen  */}
      <Box
        className="responsieveContainer"
        sx={{
          width: "100%",
          p: "10px 0px",
          // position: "fixed",
          position: "sticky",
          top: 0,
          zIndex: 999,
          background: "#fff",
        }}
      >
        <Box
          sx={{
            width: "95%",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Typography className="comName" fontSize={"28px"}>
                {/* CBX<span style={{ color: "#0FB3E2" }}>TREE</span> */}
                CBXTREE
              </Typography>
            </Link>
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div id="google_translate_element"></div>

              <IconButton onClick={handleClick}>
                <img
                  style={{ width: "18px", height: "18px" }}
                  src="/HomePage/hamber.png"
                  alt="Menu iocn"
                />
              </IconButton>
            </Box>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "19px",
                  padding: "10px 10px",
                  border: "1px solid rgba(183, 175, 208, 0.16)",
                  boxShadow: "27px 10px 74px 0px rgba(167, 175, 193, 0.26)",
                },
              }}
            >
              {/* {location.pathname === "/" && !token && (
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="#features"
                  onClick={scrollToSection}
                >
                  <MenuItem className="nav-items">Features</MenuItem>
                </a>
              )} */}
              {/* {location.pathname === "/" && !token && (
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="#reviews"
                  onClick={scrollToSection}
                >
                  <MenuItem className="nav-items">Reviews</MenuItem>
                </a>
              )} */}
              {/* {location.pathname === "/" && !token && (
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="#faqs"
                  onClick={scrollToSection}
                >
                  <MenuItem className="nav-items">FAQ’s</MenuItem>
                </a>
              )} */}

              {token && location.pathname !== "/users" && (
                <MenuItem onClick={handleOpen} className="nav-items">
                  Instruction Video
                </MenuItem>
              )}
              {token && location.pathname !== "/users" && (
                <Link
                  to="/link"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {" "}
                  <MenuItem className="nav-items">Links</MenuItem>{" "}
                </Link>
              )}
              {token && location.pathname !== "/users" && (
                <Link
                  to="/settings"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {" "}
                  <MenuItem className="nav-items"> Settings</MenuItem>{" "}
                </Link>
              )}

              {token && location.pathname !== "/users" && (
                <Link
                  to="/dashboard"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {" "}
                  <MenuItem className="nav-items"> Dashboard</MenuItem>{" "}
                </Link>
              )}

              {!localStorage.getItem("token") &&
              !localStorage.getItem("adminToken") ? (
                <>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem className="nav-items">Log in</MenuItem>
                  </Link>
                  <Link
                    to="/signup"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem className="signupMenu">
                      {" "}
                      Sign up for free
                    </MenuItem>
                  </Link>
                </>
              ) : (
                <>
                  {token && location.pathname === "/users" && (
                    <MenuItem
                      sx={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontSize: "18px",
                      }}
                      className="logouttt"
                      onClick={handleOpenFeatureModal}
                    >
                      <FaPlus size={18} style={{ color: "#00bce0" }} />
                      Add Updates
                    </MenuItem>
                  )}

                  <MenuItem
                    sx={{
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontSize: "18px",
                    }}
                    className="logouttt"
                    onClick={handleLogout}
                  >
                    <MdLogout size={18} style={{ color: "#00bce0" }} />
                    Logout
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
        </Box>
      </Box>

      {/* Instruction video modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="instructionVideoBox"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            borderRadius: "5px",
          }}
        >
          <iframe
            width="100%"
            height="450"
            // src="https://drive.google.com/file/d/1-8eCgCnC8SWfiDefqEekBvkE8R6XrMsI/preview"
            src="https://drive.google.com/file/d/1QKnzt5cKbZRL_kiQn0p1UGYdVLVTP8ed/preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Instruction Video"
          ></iframe>
        </Box>
      </Modal>

      {/* Feature update modal */}
      <AddFeaturesModal
        openFeatureModal={openFeatureModal}
        handleCloseFeatureModal={handleCloseFeatureModal}
      />

      {/* Rrport an issue */}
      <ReportAnIssueModal
        open={openReportAnIssue}
        setOpen={setOpenReportAnIssue}
      />
    </Box>
  );
};

export default Navbar;
