import { Box, Button, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import linkContext from "../../context/links/linkContext";

const NotLivePage = () => {
  const context = useContext(linkContext);
  const location = useLocation().pathname.split("/");
  const param = location[location.length - 1];
  let { user, viewUserDetails } = context;

  useEffect(() => {
    viewUserDetails(param);
  }, [param]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          p: 1,
          background: "#4D8733",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "22px",
            fontStyle: "italic",
            mr: 2,
            color: "#fff",
            fontWeight: "500",
          }}
        >
          Ceoitbox
        </Typography>{" "}
        <img
          style={{ width: "40px", height: "40px" }}
          src="logo.png"
          alt="Logo"
        />
      </Box>
      <Box
        sx={{
          minHeight: "80vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "block",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "450px",
                  maxWidth: "100%",
                }}
                src="https://t4.ftcdn.net/jpg/01/70/92/11/240_F_170921152_cvyisxSnlNspO5RtlIxuPkrX70IlXmlz.jpg"
                alt="Error img"
              />
            </Box>

            <Typography
              className="infoText"
              sx={{
                fontSize: "20px",
                pt: " 10px",
                mb: "20px",
                fontWeight: "500",
                textAlign: "center",
                color: "gray",
                fontStyle: "italic",
              }}
            >
              Apologies, your cbxtree isn't live; it may be moved or temporarily
              down. Check back later
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {user?.role === "Admin" ? (
              <Link to="/dashboard">
                <Button
                  sx={{
                    background: "#4D8733",
                    p: "5px 20px",
                    "&:hover": { background: "#3d6929" },
                  }}
                  variant="contained"
                >
                  Go To Back
                </Button>
              </Link>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotLivePage;
