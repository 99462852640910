import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Tooltip } from "antd";
import { GoInfo } from "react-icons/go";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const AddDomainSection = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleDomainClick = () => {
    if (token) {
      navigate("/settings");
    } else {
      navigate("/login");
    }
  };
  return (
    <Box sx={{ position: "relative", top: "40px", zIndex: 100 }}>
      <Box
        className="addDomainMainContainer"
        sx={{
          width: "100%",
          height: "478px",
          backgroundImage: "url('/HomePage/uniqueLinkBg.png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          border: "1px solid transparent",
          position: "relative",
          zIndex: 999,
        }}
      >
        <Box
          className="domainContainer"
          sx={{
            width: "80%",
            height: "auto",
            margin: "auto",
            flexShrink: "0",
            mt: "130px",
          }}
        >
          <Box display={"flex"} alignItems={"center"} gap={"25px"}>
            <Typography fontSize={"48px"} className="domainHeading">
              <span className="ownText">Own</span> Your Unique Link
            </Typography>
            <Tooltip
              title="A custom domain is a personalized website address , instead of
              using a generic URL, to give your CBX tree a distinct,
              professional online presence."
            >
              <Box className="noteBox">
                <GoInfo color="#fff" size={20} />
              </Box>
            </Tooltip>
          </Box>
          <Box
            className="domainInputBox"
            sx={{
              background: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "110px",
            }}
          >
            <input
              className="domainInput"
              type="text"
              placeholder="Create a Custom Domain for Your CBXTREE and Enhance Your Brand Identity"
            />
            <input
              style={{ display: "none" }}
              className="domainSSInput"
              type="text"
              placeholder="Enter a Custom Domain"
            />
            <Button
              onClick={handleDomainClick}
              className="getDomainBtn"
              endIcon={<MdChevronRight />}
            >
              {" "}
              Get Your Custom Domain
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddDomainSection;
