import React from "react";
import { DeleteFilled } from "@ant-design/icons";

const SocialLinkEdit = ({ linktype, linkurl, linkid, onDeleteClick }) => {
  const SocialLinkImages = {
    Linkedin: "linkedin.png",
    Facebook: "facebook.png",
    Instagram: "instagram.png",
    Twitter: "twitter.png",
    YouTube: "youtube.png",
    Other: "other.png",
  };

  return (
    <div
      key={linktype}
      style={{ position: "relative", marginRight: "10px", marginTop: "10px" }}
    >
      <div
        className="d-inline-block p-2"
        style={{ backgroundColor: "rgb(210 215 221)", borderRadius: "8px" }}
      >
        <a href={linkurl} target="_blank" rel="noreferrer">
          <img
            src={SocialLinkImages[linktype]}
            style={{ width: "40px", height: "40px" }}
            alt={linktype}
          />
        </a>
      </div>
      <button
        onClick={() => onDeleteClick(linkid)}
        className="btn btn-outline-secondary"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          padding: "0",
          background: "none",
          border: "none",
        }}
      >
        <DeleteFilled style={{ color: "#fb0000e3" }} />
      </button>
    </div>
  );
};

export default SocialLinkEdit;
