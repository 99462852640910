import React, { useState, useContext, useEffect } from "react";
import SocialLinkEdit from "./SocialLinkEdit";
import linkContext from "../context/links/linkContext";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "antd";
import { message } from "antd";

const AddSocialLink = (props) => {
  const context = useContext(linkContext);
  const { settings, getSettings, updateSocialLinks, deleteSL, previewUrl } =
    context;
  const [add, setAdd] = useState(false);
  const [links, setLinks] = useState({ linkType: "", linkUrl: "" });
  const [socialLinks, setSocialLinks] = useState([]);
  const [mainSettings, setMainSettings] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  function extractUserId(url) {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  }

  const userName = extractUserId(previewUrl);

  useEffect(() => {
    if (settings) {
      const data = settings?.filter((item) => item.userid === userName);
      setMainSettings(data[0]);
    }
  }, [settings, userName]);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (socialLinks.length < 8) {
      console.log(links.linkUrl, links.linkType.toLowerCase());
      // Check if the linkType string is present in the linkUrl string
      if (
        !links.linkUrl.toLowerCase().includes(links.linkType.toLowerCase()) &&
        links.linkType.toLowerCase() !== "other"
      ) {
        error("Link Type and Link URL doesnit match.");
        // props.showAlert("Link Type and Link URL doesnit match.", "danger");
        return;
      }

      const updatedLinks = [...socialLinks, links];
      updateSocialLinks(updatedLinks, props);
      setAdd(false);
      setLinks({ linkType: "", linkUrl: "" }); // Reset links after adding
    } else {
      error("You can add a maximum of 8 social links.");
      // props.showAlert("You can add a maximum of 8 social links.", "danger");
    }
  };

  const handleDeleteSL = (id) => {
    deleteSL(id, props);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setLinks((prevLinks) => ({ ...prevLinks, [name]: value }));
  };

  const handleAdd = () => {
    setAdd(!add);
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSocialLinks(mainSettings?.socialLinks || []);
  }, [mainSettings]);

  const isSubmitDisabled = !(links.linkType && links.linkUrl);

  return (
    <div>
      {contextHolder}
      <div
        className="d-flex justify-content-center my-2"
        style={{
          backgroundColor: "#4F8B33",
          color: "#fff",
          borderRadius: "20px",
          cursor: "pointer",
        }}
        onClick={handleAdd}
      >
        <span className="my-2">
          {add ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-regular fa-plus"></i>
          )}{" "}
          Add Social Link
        </span>
      </div>

      {add && (
        <form>
          <div className="input-group mb-3 socialMediaIconDiv">
            <span>
              <select
                className="form-select w-auto selectTag"
                name="linkType"
                aria-label="Default select example"
                onChange={onChange}
                value={links.linkType}
              >
                <option value="" disabled>
                  Select Link Type
                </option>
                <option value="Linkedin">Linkedin</option>
                <option value="Twitter">Twitter</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="YouTube">YouTube</option>
                <option value="Other">Other</option>
              </select>
            </span>

            <input
              type="text"
              className="form-control socialInput"
              name="linkUrl"
              aria-label="Text input with dropdown button"
              onChange={onChange}
              placeholder="Enter Link URL"
              value={links.linkUrl}
            />
            <button
              id="addSocialIconBtn"
              style={{
                background: "#4F8B33",
                border: "none",
                borderRadius: "4px",
              }}
              type="button"
              className={`btn ${
                isSubmitDisabled ? "btn-secondary" : "btn-primary "
              }`}
              onClick={handleClick}
              disabled={isSubmitDisabled}
            >
              ADD
            </button>
            <span
              style={{ margin: "5px", position: "relative", zIndex: "999" }}
            >
              <Tooltip title="Add or update your social links">
                <FaInfoCircle size={14} cursor={"pointer"} color="#4F8B33" />
              </Tooltip>
            </span>
          </div>
        </form>
      )}

      <div className="d-flex justify-content-center">
        {socialLinks.length > 0 &&
          socialLinks.map((socialLink) => (
            <SocialLinkEdit
              key={socialLink._id}
              linktype={socialLink.linkType}
              linkurl={socialLink.linkUrl}
              linkid={socialLink._id}
              onDeleteClick={handleDeleteSL}
            />
          ))}
      </div>
    </div>
  );
};

export default AddSocialLink;
