import React, { useState } from "react";
import { Typography, Box } from "@mui/material";

const PasswordCell = ({ password }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      sx={{
        cursor: "pointer",
        "& > *": {
          transition: "color 0.3s ease-in-out",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography
        sx={{
          fontWeight: 450,
          fontSize: "14px",
          color: isHovered ? "#333" : "#333",
          backgroundColor: isHovered ? "transparent" : "transparent",
          transition: "background-color 0.3s ease-in-out",
        }}
      >
        {/* {password ? (isHovered ? password : "****") : "--"} */}
        {typeof password === "string" && password.length > 0
          ? isHovered
            ? password
            : "****"
          : "--"}
      </Typography>
    </Box>
  );
};

export default PasswordCell;
