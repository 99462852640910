import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ShowNavbar = ({ children }) => {
  const location = useLocation();
  const [showNavbar, setShowNavbar] = useState(() => {
    const pathname = location.pathname;
    const locationArr = [
      "signup",
      "login",
      "users",
      "sendotp",
      "resetpassword",
      "link",
      "dashboard",
      "settings",
    ];
    return pathname === "/" || locationArr.includes(pathname.split("/")[1]);
  });

  useEffect(() => {
    const pathname = location.pathname;
    const locationArr = [
      "signup",
      "login",
      "users",
      "sendotp",
      "resetpassword",
      "link",
      "dashboard",
      "settings",
    ];
    if (pathname === "/" || locationArr.includes(pathname.split("/")[1])) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  }, [location]);

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
      {showNavbar && children}
    </div>
  );
};

export default ShowNavbar;
