import React, { useContext, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import "./OurUser.css";
import linkContext from "../../../context/links/linkContext";
import { motion, useInView } from "framer-motion";

const headingVariants = {
  hidden: { opacity: 0, x: 150 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      type: "spring",
      stiffness: 50,
      delay: 0.5,
    },
  },
};

const OurUser = () => {
  const context = useContext(linkContext);
  let { getLinksCount, linkCount, settingsCount, getSettingsCount } = context;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const formatNumber = (num) => {
    if (num >= 1000 && num < 1000000) {
      return (Math.floor(num / 100) / 10).toFixed(1) + "k+";
    } else if (num >= 1000000) {
      return (Math.floor(num / 100000) / 10).toFixed(1) + "M+";
    }
    return num.toString();
  };

  const formatNumberCbxtree = (num) => {
    if (num >= 500 && num < 1000) {
      return Math.floor(num / 100) * 100 + "+";
    } else if (num >= 1000 && num < 1000000) {
      return (Math.floor(num / 100) / 10).toFixed(1) + "k+";
    } else if (num >= 1000000) {
      return (Math.floor(num / 100000) / 10).toFixed(1) + "M+";
    }
    return num.toString();
  };

  useEffect(() => {
    getLinksCount();
    getSettingsCount();
  }, []);

  return (
    <>
      <Box
        sx={{
          p: "50px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "80px",
          flexWrap: "wrap",
        }}
        ref={ref}
      >
        <Box
          sx={{
            minWidth: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" className="our-heading-text">
            {formatNumberCbxtree(settingsCount)}
          </Typography>
          <motion.div
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={headingVariants}
          >
            <Typography className="our-sub-text">CBXTREE created</Typography>
          </motion.div>
        </Box>
        <Box
          sx={{
            minWidth: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" className="our-heading-text">
            {formatNumber(linkCount)}
          </Typography>
          <motion.div
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={headingVariants}
          >
            <Typography className="our-sub-text">Links managed</Typography>
          </motion.div>
        </Box>
        <Box
          sx={{
            minWidth: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" className="our-heading-text">
            2.35x
          </Typography>
          <motion.div
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={headingVariants}
          >
            <Typography className="our-sub-text">
              Increased engagement
            </Typography>
          </motion.div>
        </Box>
      </Box>
    </>
  );
};

export default OurUser;
