import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleClcik = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  return (
    <Box sx={{ minHeight: "100vh", width: "100%", position: "relative" }}>
      <Box
        id="reviews"
        sx={{
          width: "100%",
          height: "300px",
          flexShrink: 0,
          background: "#fff",
          backgroundImage: "url('/notFoundBg.png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          border: "1px solid transparent",
          // transparent
          p: "50px 0px 0px 50px",
        }}
      >
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Button
            sx={{
              textTransform: "none",
              borderRadius: "220px",
              background: "#6B33E9",
              height: "46px",
              padding: "0px 30px",
              color: "#fff",
              fontWeight: 500,
              "&:hover": {
                background: "#7C4EF0",
              },
            }}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="12"
                viewBox="0 0 23 12"
                fill="none"
              >
                <path
                  d="M0.96967 5.46967C0.676777 5.76256 0.676777 6.23744 0.96967 6.53033L5.74264 11.3033C6.03553 11.5962 6.51041 11.5962 6.8033 11.3033C7.09619 11.0104 7.09619 10.5355 6.8033 10.2426L2.56066 6L6.8033 1.75736C7.09619 1.46447 7.09619 0.989593 6.8033 0.696699C6.51041 0.403806 6.03553 0.403806 5.74264 0.696699L0.96967 5.46967ZM22.5 5.25H1.5V6.75H22.5V5.25Z"
                  fill="white"
                />
              </svg>
            }
          >
            {" "}
            Back
          </Button>{" "}
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          position: "relative",
          bottom: "100px",
        }}
      >
        <img src="/error.png" alt="Wrong url" />
        <Typography
          sx={{
            color: "#6B33E9",
            textAlign: "center",
            fontSize: "38.781px",
            fontWeight: 500,
            letterSpacing: "0.388px",
            p: "0px 10px",
          }}
        >
          Oops!
        </Typography>
        <Typography
          sx={{
            color: "#0A0932)",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: "0.16px",
          }}
        >
          The link that you entered is not available. Please check the link
          again and modify the link.
        </Typography>
        <Button
          onClick={handleClcik}
          sx={{
            textTransform: "none",
            borderRadius: "91.551px",
            border: "1.237px solid  #00BCE0",
            background: "#FFF",
            padding: "10px 30px",
            mt: 2,
          }}
          endIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="none"
            >
              <path
                d="M1.68555 1.13043L5.05104 5.03089C5.20238 5.20628 5.20238 5.46606 5.05104 5.64145L1.68555 9.5419"
                stroke="#161616"
                stroke-width="1.86922"
                stroke-linecap="round"
              />
            </svg>
          }
        >
          Create your own TREE with amazing options
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
