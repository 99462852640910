import React from "react";
import {
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
} from "react-icons/fa";
import { SlSocialDribbble } from "react-icons/sl";
import { FaYoutube } from "react-icons/fa";

const SocialLink = (props) => {
  const { linktype, linkurl, viewsetting } = props;
  const SocialLink = [
    [
      "Linkedin",
      <FaLinkedinIn
        size={30.6}
        color={`${
          viewsetting?.paginationSetting?.socialIconColor ||
          viewsetting?.headersSettings?.fontColor ||
          ""
        }`}
      />,
    ],
    [
      "YouTube",
      <FaYoutube
        size={30.6}
        color={`${
          viewsetting?.paginationSetting?.socialIconColor ||
          viewsetting?.headersSettings?.fontColor ||
          ""
        }`}
      />,
    ],
    [
      "Facebook",
      <FaFacebookF
        size={30.6}
        color={`${
          viewsetting?.paginationSetting?.socialIconColor ||
          viewsetting?.headersSettings?.fontColor ||
          ""
        }`}
      />,
    ],
    [
      "Instagram",
      <FaInstagram
        size={30.6}
        color={`${
          viewsetting?.paginationSetting?.socialIconColor ||
          viewsetting?.headersSettings?.fontColor ||
          ""
        }`}
      />,
    ],
    [
      "Twitter",
      <FaTwitter
        size={30.6}
        color={`${
          viewsetting?.paginationSetting?.socialIconColor ||
          viewsetting?.headersSettings?.fontColor ||
          ""
        }`}
      />,
    ],
    [
      "Other",
      <SlSocialDribbble
        size={30.6}
        color={`${
          viewsetting?.paginationSetting?.socialIconColor ||
          viewsetting?.headersSettings?.fontColor ||
          ""
        }`}
      />,
    ],
  ];

  return (
    <div className="m-2">
      {SocialLink.map((item) => {
        if (item[0] === linktype) {
          return (
            <div key={item[0]}>
              <a href={linkurl} target="_blank" rel="noreferrer">
                <p>{item[1]}</p>
                {/* <img
                  src={item[1]}
                  style={{ width: "40px", height: "40px" }}
                  alt={item[0]}
                /> */}
              </a>
            </div>
          );
        }
      })}
    </div>
  );
};

export default SocialLink;
