import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: "15px 20px",
};

const CreateNewCbxtreeModal = ({
  openModal,
  handleCloseModal,
  setUserName,
  handleSubmitUserName,
  userName,
}) => {
  return (
    <Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="muiModalBox" sx={style}>
          <Typography
            sx={{ fontWeight: 550, fontSize: "22px" }}
            id="simple-modal-title"
            variant="h6"
            component="h2"
          >
            <span style={{ color: "#4D8733" }}> Choose a</span>
            <span style={{ color: "#B4D33B" }}> username</span>
          </Typography>
          <Typography
            id="simple-modal-description"
            sx={{ mt: 1, fontSize: "14px", color: "#777E90" }}
          >
            Choose a Cbxtree URL for your new Cbxtree. You can always change it
            later.
          </Typography>

          <input
            className="usernameInput"
            style={{ marginTop: "20px", width: "100%" }}
            fullWidth
            id="outlined-basic"
            placeholder="Username"
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          ></input>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "end",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                m: "16px 0px",
                textTransform: "none",
                borderColor: "#4D8733",
                color: "#4D8733",
                fontWeight: 500,
                "&:hover": {
                  color: "#4f8b33",
                  border: "1px solid #4f8b33",
                },
              }}
              onClick={handleCloseModal}
              style={{ marginTop: "16px" }}
            >
              Close
            </Button>
            <Button
              onClick={handleSubmitUserName}
              variant="contained"
              sx={{
                m: "16px 0px",
                borderColor: "#4D8733",
                background: "#4D8733",
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#376927",
                  background: "#376927",
                },
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CreateNewCbxtreeModal;
