import BasicTheme from "./BasicTheme";
import ColoursTheme from "./ColoursTheme";
import ArtistTheme from "./ArtistTheme";
import AnimatedTheme from "./AnimatedTheme";
import MinimalistTheme from "./MinimalistTheme";
import { Box } from "@mui/material";
import {
  basicTheme,
  coloursTheme,
  artistTheme,
  animatedTheme,
  minimalistTheme,
} from "../Constant";
import { useContext, useEffect, useState } from "react";
import linkContext from "../../context/links/linkContext";

const Themes = () => {
  const context = useContext(linkContext);
  let { changeTheme, previewUrl } = context;
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [showColorTheme, setShowColorTheme] = useState(false);
  const [showBasicTheme, setShowBasicTheme] = useState(false);
  const [showMinimalistTheme, setShowMinimalisTheme] = useState(false);
  const [showArtistTheme, setShowArtistTheme] = useState(false);
  const [showAnimatedTheme, setShowAnimatedTheme] = useState(false);

  const extractUserId = (url) => {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  };

  const userName = extractUserId(previewUrl);

  const updateTheme = async (idx, themeName, themeType) => {
    setSelectedTheme(themeName);
    const themeArray = await getThemeArray(themeType);
    const selectedTheme = themeArray?.find((theme) => theme.name === themeName);
    if (selectedTheme) {
      changeTheme(selectedTheme, userName);
      localStorage.setItem("selectedTheme", JSON.stringify(themeName));
    }
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem("selectedTheme");
    if (storedTheme) {
      setSelectedTheme(JSON.parse(storedTheme));
    } else {
      setSelectedTheme(getDefaultTheme().name);
    }
  }, []);

  const getDefaultTheme = () => {
    return BasicTheme;
  };

  const getThemeArray = async (themeType) => {
    switch (themeType) {
      case "basic":
        return basicTheme;
      case "colours":
        return coloursTheme;
      case "artist":
        return artistTheme;
      case "animated":
        return animatedTheme;
      case "minimalist":
        return minimalistTheme;
      default:
        console.error(`Theme array not found for ${themeType}`);
        return [];
    }
  };

  const handleToggleBasicTheme = () => {
    setShowBasicTheme(!showBasicTheme);
    setShowColorTheme(false);
    setShowMinimalisTheme(false);
    setShowArtistTheme(false);
    setShowAnimatedTheme(false);
  };

  const handleToggleColorTheme = () => {
    setShowColorTheme(!showColorTheme);
    setShowBasicTheme(false);
    setShowMinimalisTheme(false);
    setShowArtistTheme(false);
    setShowAnimatedTheme(false);
  };

  const handleToggleMinimalisTheme = () => {
    setShowMinimalisTheme(!showMinimalistTheme);
    setShowBasicTheme(false);
    setShowColorTheme(false);
    setShowArtistTheme(false);
    setShowAnimatedTheme(false);
  };

  const handleToggleArtistTheme = () => {
    setShowArtistTheme(!showArtistTheme);
    setShowBasicTheme(false);
    setShowColorTheme(false);
    setShowAnimatedTheme(false);
    setShowMinimalisTheme(false);
  };

  const handleToggleAnimatedTheme = () => {
    setShowAnimatedTheme(!showAnimatedTheme);
    setShowBasicTheme(false);
    setShowMinimalisTheme(false);
    setShowColorTheme(false);
    setShowArtistTheme(false);
  };

  return (
    <>
      <Box sx={{ mt: 2, mb: 2, position: "relative", zIndex: 100 }}>
        <h4
          style={{
            color: "#B4D33B",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Themes
        </h4>

        <Box>
          <BasicTheme
            showBasicTheme={showBasicTheme}
            handleToggleBasicTheme={handleToggleBasicTheme}
            updateTheme={updateTheme}
            selectedTheme={selectedTheme}
          />
        </Box>

        <Box>
          <ColoursTheme
            showColorTheme={showColorTheme}
            handleToggleColorTheme={handleToggleColorTheme}
            updateTheme={updateTheme}
            selectedTheme={selectedTheme}
          />
        </Box>

        <Box>
          <MinimalistTheme
            showMinimalistTheme={showMinimalistTheme}
            handleToggleMinimalisTheme={handleToggleMinimalisTheme}
            updateTheme={updateTheme}
            selectedTheme={selectedTheme}
          />
        </Box>

        <Box>
          <ArtistTheme
            showArtistTheme={showArtistTheme}
            handleToggleArtistTheme={handleToggleArtistTheme}
            updateTheme={updateTheme}
            selectedTheme={selectedTheme}
          />
        </Box>

        <Box>
          <AnimatedTheme
            showAnimatedTheme={showAnimatedTheme}
            handleToggleAnimatedTheme={handleToggleAnimatedTheme}
            updateTheme={updateTheme}
            selectedTheme={selectedTheme}
          />
        </Box>
      </Box>
    </>
  );
};

export default Themes;
