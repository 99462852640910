import React, { useEffect } from "react";
import FaqSection from "./FaqSection/FaqSection";
import Footer from "./Footer/Footer";
import CustomerReviewSection from "./CustomerReviewSection/CustomerReviewSection";
import AddDomainSection from "./AddDomainSection/AddDomainSection";
import VideoSection from "./VideoSection/VideoSection";
import AdvanceFeatures from "./AdvanceFeatures/AdvanceFeatures";
import ThemeSection from "./ThemeSection/ThemeSection";
import HeroSection from "./HeroSection/HeroSection";
// import PreviewSection from "./PreviewSection/PreviewSection";
import "./Home.css";
import OurUser from "./OurUser/OurUser";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <HeroSection />
      <AdvanceFeatures />
      <ThemeSection />
      {/* <PreviewSection /> */}
      <VideoSection />
      <OurUser />
      <CustomerReviewSection />
      <FaqSection />
      <AddDomainSection />
      <Footer />
    </div>
  );
};

export default HomePage;
