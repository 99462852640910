import {
  Box,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { useState } from "react";
import { faqs } from "../../Constant";
import "./FaqSection.css";

const FaqSection = () => {
  const [expanded, setExpanded] = useState(faqs[0]?.id);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box
        id="faqs"
        sx={{
          background: "#FFF",
          width: "100%",
          height: "auto",
          p: "5% 0px",
          position: "relative",
        }}
      >
        <Box
          className="logoIconBox"
          sx={{ position: "absolute", bottom: 0 }}
        ></Box>
        <Box
          className="faqMainContainer"
          sx={{
            width: "90%",
            m: "auto",
            height: "100%",
            display: "flex",
          }}
        >
          <Box
            className="faqHeadinBox"
            sx={{
              width: "50%",
            }}
          >
            <Typography
              className="faqSecHeading"
              sx={{
                fontSize: "48px",
                fontWeight: 500,
                lineHeight: "60px",
                color: "#161616",
                width: "70%",
                letterSpacing: "0.48px",
              }}
            >
              <span className="anyText">Any</span> Questions?
            </Typography>
            <Typography
              sx={{
                mt: 3,
                color: "#6E7183",
                letterSpacing: "0.18px",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "36px",
              }}
            >
              Find Answers to Common Questions About Using CBXTREE
            </Typography>
          </Box>
          <Box
            className="faqContainer"
            sx={{
              width: "50%",
            }}
          >
            <Box>
              {faqs &&
                faqs?.map((item) => (
                  <Box key={item.id}>
                    <Accordion
                      expanded={expanded === item.id}
                      onChange={handleChange(item.id)}
                      sx={{
                        background: "#fff",
                        p: 1,
                        boxShadow: "none",
                        borderBottom: "1px solid #C6C6C6",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === item.id ? (
                            <img
                              style={{ width: "22px", height: "22px" }}
                              src="/ThemeImages/expandMore.png"
                              alt="ExpandLess"
                            />
                          ) : (
                            <img
                              style={{ width: "14px", height: "14px" }}
                              src="/ThemeImages/expandLess.png"
                              alt="ExpandMore"
                            />
                          )
                        }
                        aria-controls={`panel${item.id}-content`}
                        id={`panel${item.id}-header`}
                        sx={{
                          fontSize: "18px",
                          color: "#161616",
                          transition: "color 0.3s ease",
                          "&:hover": { color: "#00bce0" },
                        }}
                        className={
                          expanded === item.id ? "faqTitle" : "faq-title"
                        }
                      >
                        {item.title}
                      </AccordionSummary>
                      <AccordionDetails
                        className="faqDescription"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#6E7183",
                          letterSpacing: "0.16px",
                        }}
                      >
                        {item.description}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FaqSection;
