import React from "react";

const Table = (props) => {
  const { data } = props;

  return (
    <div>
      <div className="row d-flex justify-content-end"></div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">City</th>
            <th scope="col">Country</th>
            <th scope="col">Latitude</th>
            <th scope="col">Longitude</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row) => (
            <tr>
              <td>{row.city}</td>
              <td>{row.country}</td>
              <td>{row.latitude}</td>
              <td>{row.longitude}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
