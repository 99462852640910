import React from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { customerReviews } from "../../Constant";
import { motion } from "framer-motion";

const CustomerReviewSection = () => {
  const settings = {
    dots: false,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    // infinite: true,
    // speed: 600,
    slidesToShow: 4,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const cardVariants = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  return (
    <>
      <Box
        id="reviews"
        sx={{
          width: "100%",
          height: "862px",
          background: "#fff",
          backgroundImage: "url('/HomePage/CustomerRBg.svg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          border: "1px solid transparent",
        }}
      >
        <Box
          sx={{
            width: "90%",
            margin: "auto",
            mt: "7%",
          }}
        >
          {/* Heading box */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize={"55px"} className="customerReviewHeading">
              What Our Users Are <span className="savingText">Saying!</span>
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 10,
            }}
          >
            <Slider {...settings}>
              {customerReviews?.map((item) => {
                return (
                  <motion.div
                    key={item.id}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.5 }}
                    variants={cardVariants}
                    animate={{
                      x: ["0%", "-100%"],
                      transition: {
                        ease: "linear",
                        duration: 5,
                        repeat: Infinity,
                      },
                    }}
                  >
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <div className="rectangle-parent9">
                        <div className="frame-child59" />
                        <div className="frame-parent37">
                          <div className="frame-wrapper31">
                            <div className="image-17-parent">
                              <img
                                className="image-17-icon"
                                loading="lazy"
                                alt=""
                                src={item.image}
                              />
                              <div className="frame-parent38">
                                <div className="sarah-j-wrapper">
                                  <div className="sarah-j">{item.name}</div>
                                </div>
                                <div className="frame-parent39">
                                  <img
                                    className="frame-icon4"
                                    alt=""
                                    src="/svgIcons/frame-4.svg"
                                  />
                                  <div className="mumbaimaharashtra">
                                    {item.address}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <img
                            className="frame-child60"
                            loading="lazy"
                            alt=""
                            src="/svgIcons/group-2351.svg"
                          />
                        </div>
                        <div className="frame-wrapper32">
                          <div className="frame-parent40">
                            <div className="cbxtree-has-streamlined-how-i-parent">
                              <p className="cbxtree-has-streamlined">
                                {item.review}
                              </p>
                              <div className="frame-wrapper33">
                                <img
                                  className="frame-child61"
                                  loading="lazy"
                                  alt=""
                                  src="/svgIcons/group-2352.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-wrapper34">
                          {item.star === 5 && (
                            <div className="star-parent">
                              <img
                                className="star-icon"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child62"
                                alt=""
                                src="/svgIcons/star-6.svg"
                              />
                              <img
                                className="frame-child63"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child64"
                                alt=""
                                src="/svgIcons/star-6.svg"
                              />
                              <img
                                className="frame-child64"
                                alt=""
                                src="/svgIcons/star-6.svg"
                              />
                            </div>
                          )}
                          {item.star === 4 && (
                            <div className="star-parent">
                              <img
                                className="star-icon"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child62"
                                alt=""
                                src="/svgIcons/star-6.svg"
                              />
                              <img
                                className="frame-child63"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child64"
                                alt=""
                                src="/svgIcons/star-6.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                            </div>
                          )}
                          {item.star === 3 && (
                            <div className="star-parent">
                              <img
                                className="star-icon"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child62"
                                alt=""
                                src="/svgIcons/star-6.svg"
                              />
                              <img
                                className="frame-child63"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                            </div>
                          )}
                          {item.star === 2 && (
                            <div className="star-parent">
                              <img
                                className="star-icon"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child62"
                                alt=""
                                src="/svgIcons/star-6.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                            </div>
                          )}
                          {item.star === 1 && (
                            <div className="star-parent">
                              <img
                                className="star-icon"
                                alt=""
                                src="/svgIcons/star-1.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                              <img
                                className="frame-child65"
                                alt=""
                                src="/svgIcons/star-5.svg"
                              />
                            </div>
                          )}
                        </div>
                        <div className="rectangle-wrapper">
                          <div className="frame-child66" />
                        </div>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </Slider>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomerReviewSection;
