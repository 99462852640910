import { useContext, useEffect, useState } from "react";
import linkContext from "../../context/links/linkContext";

const useDynamicFaviconAndTitle = () => {
  const context = useContext(linkContext);
  let { previewUrl, settings } = context;
  const [mainSettings, setMainSettings] = useState([]);

  // const [mainSettings, setMainSettings] = useState({
  //   pageTitleAndFeviconIconSetting: {
  //     feviconIcon: "https://i.ibb.co/K00ch0m/CEOITBOX-Logo-Small.png",
  //     title: "CEOITBOX | Instant Link",
  //   },
  // });

  function extractUserId(url) {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  }

  const userName = extractUserId(previewUrl);

  useEffect(() => {
    if (settings && Array.isArray(settings)) {
      const data = settings.filter((item) => item.userid === userName);
      if (data.length > 0) {
        setMainSettings(data[0]);
      }
    }
  }, [settings, userName]);

  useEffect(() => {
    if (mainSettings?.pageTitleAndFeviconIconSetting?.feviconIcon) {
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = mainSettings?.pageTitleAndFeviconIconSetting?.feviconIcon;
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    // Change title
    if (mainSettings?.pageTitleAndFeviconIconSetting?.title) {
      document.title = mainSettings?.pageTitleAndFeviconIconSetting?.title;
    }
  }, [mainSettings]);
};

export default useDynamicFaviconAndTitle;
