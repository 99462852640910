import { Grid, Typography, Box } from "@mui/material";
import { artistTheme } from "../Constant";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const ArtistTheme = (props) => {
  const {
    showArtistTheme,
    handleToggleArtistTheme,
    updateTheme,
    selectedTheme,
  } = props;

  const preloadImages = () => {
    artistTheme?.forEach((theme) => {
      const img = new Image();
      img.src = theme.backgroundImage;
    });
  };

  preloadImages();

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <div
          onClick={handleToggleArtistTheme}
          style={{
            display: "flex",
            gap: "10px",
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: showArtistTheme ? "" : "20px",
            borderBottomRightRadius: showArtistTheme ? "" : "20px",
            height: "auto",
            background: "#fff",
            padding: "16px 16px 14px 20px",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
        >
          <h4
            style={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#B4D33B",
              cursor: "pointer",
            }}
          >
            Artist Theme
          </h4>
          <Box>
            {" "}
            {showArtistTheme ? (
              <IoIosArrowUp
                size={18}
                style={{
                  cursor: "pointer",
                  marginBottom: "5px",
                  color: "#B4D33B",
                  fontWeight: "bold",
                }}
              />
            ) : (
              <IoIosArrowDown
                size={18}
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginBottom: "5px",
                  color: "#B4D33B",
                }}
              />
            )}
          </Box>
        </div>

        {showArtistTheme && (
          <div
            style={{
              borderBottomRightRadius: "20px",
              borderBottomLeftRadius: "20px",

              height: "auto",
              background: "#fff",
              padding: "16px 16px 20px 20px",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px",
            }}
          >
            <Grid container justifyContent="center" spacing={4}>
              {artistTheme?.map((theme) => (
                <Grid item key={theme.ids}>
                  <Box
                    onClick={() => updateTheme(theme.ids, theme.name, "artist")}
                    sx={{
                      padding: selectedTheme === theme.name ? "10px" : "0px",
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      width: "140px",
                      height: "200px",
                      textAlign: "center",
                      cursor: "pointer",
                      mb: 2,
                      backgroundImage: theme.backgroundImage
                        ? `url('${theme.backgroundImage}')`
                        : "none",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: !theme.backgroundImage
                          ? theme.background
                          : "",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        border:
                          selectedTheme === theme.name ? "1px solid #ccc" : "",
                      }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          border: `1px solid ${theme.linksBorderColor}`,
                          background: theme.linksBackgroundColor,
                          width: "90%",
                          borderRadius: theme.borderRadius || "10px",
                          boxShadow: theme.boxShadow
                            ? `0 0 #0000, 0 0 #0000, 4px 4px 0 0 ${theme.linksBorderColor}`
                            : "none",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          p: 1,
                          border: `1px solid ${
                            theme.linksBorderColor || theme.color
                          }`,
                          background: theme.linksBackgroundColor,
                          width: "90%",
                          borderRadius: theme.borderRadius || "10px",
                          boxShadow: theme.boxShadow
                            ? `0 0 #0000, 0 0 #0000, 4px 4px 0 0 ${theme.linksBorderColor}`
                            : "none",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          p: 1,
                          border: `1px solid ${theme.linksBorderColor}`,
                          background: theme.linksBackgroundColor,
                          width: "90%",
                          borderRadius: theme.borderRadius || "10px",
                          boxShadow: theme.boxShadow
                            ? `0 0 #0000, 0 0 #0000, 4px 4px 0 0 ${theme.linksBorderColor}`
                            : "none",
                        }}
                      ></Box>
                    </Box>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 2,
                        fontSize: "12px",
                        fontWeight: "550",
                        pt: selectedTheme === theme.name ? 1 : "",
                      }}
                    >
                      {theme.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Box>
    </>
  );
};

export default ArtistTheme;
