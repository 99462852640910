import React, { useContext, useEffect, useState } from "react";
import Links from "./Links";
import MobilePreview from "./Themes/MobilePreview";
import linkContext from "../context/links/linkContext";
// import { Backdrop, CircularProgress } from "@mui/material";

function Home(props) {
  const context = useContext(linkContext);
  let { settings, links, previewUrl } = context;
  const [linkData, updateLinkData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [mainSettings, setMainSettings] = useState([]);
  // const [loading, setLoading] = useState(true);

  function extractUserId(url) {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  }

  const userName = extractUserId(previewUrl);

  useEffect(() => {
    if (settings && Array.isArray(settings)) {
      const data = settings?.filter((item) => item.userid === userName);
      setMainSettings(data[0]);
    }
  }, [settings, userName]);

  useEffect(() => {
    if (links && Array.isArray(links)) {
      const newData = links?.filter((item) => item.userid === userName);
      setFilteredData(newData);
    }
  }, [links, userName]);

  useEffect(() => {
    const currentDate = new Date();
    // Filter links based on endDate
    if (Array.isArray(filteredData)) {
      const filterviewlink = filteredData?.filter((link) => {
        if (link.endDate) {
          const [day, month, year, hour, minute] =
            link.endDate.split(/[\/\s:]/);
          const endDate = new Date(year, month - 1, day, hour, minute);
          return endDate >= currentDate;
        } else {
          return true;
        }
      });
      // Sort links based on the 'order' property
      const sortedLinks = filterviewlink
        .slice()
        .sort((a, b) => a.order - b.order);
      // Update linkData when links change
      updateLinkData(sortedLinks);
    }
  }, [filteredData]);

  return (
    <div>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress sx={{ color: "#0bd7f3" }} />
      </Backdrop> */}

      <div
        style={{
          marginRight: "0px",
          background: "#F8F8F8",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 20px 0px 20px",
        }}
      >
        <div
          className="my-3 linksComponentDiv"
          style={{
            width: "60%",
            position: "relative",
            borderRight: "1px solid #ccc",
            paddingRight: "20px",
            // zIndex: 999,
          }}
        >
          <Links showAlert={props.showAlert} />
        </div>

        <div
          className="previewComponentDiv"
          style={{
            width: "50%",
            height: "100vh",
            position: "fixed",
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        >
          <MobilePreview viewsetting={mainSettings} viewlinks={linkData} />
        </div>
      </div>
    </div>
  );
}

export default Home;
