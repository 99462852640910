import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { MdChevronRight } from "react-icons/md";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ReactTyped } from "react-typed";
import { useNavigate } from "react-router-dom";
import "./HeroSection.css";

const HeroSection = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [typedPlaceholder, setTypedPlaceholder] = useState("");
  const headingControls = useAnimation();
  const subHeadingControls = useAnimation();
  const subheadingControls = useAnimation();
  const inputControls = useAnimation();
  const themeImgControls = useAnimation();

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      headingControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.1, duration: 0.5 },
      });
      subHeadingControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 0.5 },
      });
      subheadingControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.5, duration: 0.7 },
      });
      inputControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.7, duration: 0.9 },
      });
      themeImgControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.9, duration: 1 },
      });
    } else {
      headingControls.start({ opacity: 0, y: 20 });
      subHeadingControls.start({ opacity: 0, y: 20 });
      subheadingControls.start({ opacity: 0, y: 20 });
      inputControls.start({ opacity: 0, y: 20 });
      themeImgControls.start({ opacity: 0, y: 20 });
    }
  }, [
    inView,
    headingControls,
    subheadingControls,
    inputControls,
    subHeadingControls,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTypedPlaceholder("Unlimited Links");
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const handleClick = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          p: "50px 0px 0px 0px",
        }}
        ref={ref}
      >
        <Box className="heroSectionHeadingBox" width={"85%"}>
          <motion.div initial={{ opacity: 0, y: 20 }} animate={headingControls}>
            <Typography className="heroSectionHeading" fontSize={"50px"}>
              Share More, <span className="cbxtreeText">Effortlessly</span>
            </Typography>
          </motion.div>

          <motion.div
            style={{
              marginBottom: "50px",
              position: "relative",
              zIndex: 100,
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={subHeadingControls}
          >
            <Typography
              className="heroSubHeading"
              sx={{
                color: "#161616",
                fontWeight: 600,
                // mr: "100px",
                mr: "25px",
              }}
              fontSize={"50px"}
            >
              Your <span className="cbxtreeText">Personalized</span> Link Page
              Awaits
              {/* Presence with <span className="cbxtreeText">CBXTREE</span> */}
            </Typography>
          </motion.div>

          {/* <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={subheadingControls}
        >
          <Box mt={"25px"} mb={"50px"}>
            <Typography className="subHeading" fontSize={"20px"}>
              Create a Personalized, Link-Rich Page with Advanced Analytics,
              Seamless
            </Typography>
            <Typography className="subHeading" fontSize={"20px"}>
              Integrations, and Customizable Design – All in One Place
            </Typography>
          </Box>
        </motion.div> */}

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={inputControls}
            style={{
              position: "relative",
              top: "10px",
              zIndex: 100,
            }}
            className="inputAndBtnBox"
          >
            {typedPlaceholder && (
              <ReactTyped
                strings={[typedPlaceholder]}
                typeSpeed={100}
                backSpeed={50}
                loop
                attr="placeholder"
                showCursor={false}
              >
                <input type="text" className="heroSInput" />
              </ReactTyped>
            )}
            <Button
              onClick={handleClick}
              sx={{
                position: "absolute",
                right: "27%",
                minWidth: "221px",
                height: "64px",
                padding: "10px 30px",
              }}
              className="createYourPBtn"
              endIcon={<MdChevronRight />}
            >
              Create Your Tree
            </Button>
          </motion.div>
        </Box>
        <Box
          className="themeImgBox"
          sx={{
            width: "100%",
            height: "349px",
            background: "#fff",
            backgroundImage: "url('/HomePage/heroSBg.svg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
            bottom: "50px",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={themeImgControls}
            className="themeImg"
          >
            <img
              className="themeimgForHeroSection"
              // src="/HomePage/themeImg.png"
              src="/HomePage/heroSectionSide.png"
              alt="Theme Image"
              loading="lazy"
            />
            <img
              className="smallScreenTheme"
              style={{ display: "none" }}
              src="/HomePage/heroSectionTheme.png"
              alt="Theme Image"
              loading="lazy"
            />
          </motion.div>
        </Box>
      </Box>
    </>
  );
};

export default HeroSection;
