import React from "react";
import { Box, Modal } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  maxHeight: "85%",
  boxShadow: 24,
  outline: "none",
  background: "#FFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const FeatureImgPreviewModal = ({
  openModal,
  closedPreviewModal,
  previewImg,
}) => {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closedPreviewModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="muiModalBox" sx={modalStyle}>
          <img
            src={previewImg}
            alt="Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default FeatureImgPreviewModal;
