import React from "react";
import "./Footer.css";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  const scrollToSection = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <Box>
      <Box
        className="footerMainContainer"
        sx={{
          width: "100%",
          height: "433px",
          backgroundImage: "url('/HomePage/FooterBg.png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          p: "80px 0px ",
          position: "relative",
          // zIndex: 999,
        }}
      >
        <Box
          sx={{
            width: "90%",
            m: "auto",
          }}
        >
          <Box
            className="footerContainer"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box className="logo-box" sx={{ display: "flex", gap: "5px" }}>
                <Typography
                  sx={{
                    fontSize: "45px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    lineHeight: "normal",
                  }}
                >
                  <span style={{ color: "#fff" }}>CBX</span>
                  {""}
                  <span style={{ color: "#161616" }}>TREE</span>
                </Typography>
              </Box>
              {/*Social  Logo */}
              <Box
                className="logo-box"
                sx={{
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <a
                  href="https://www.instagram.com/sanjeevjain_coach/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.9634 2.6814C15.1607 2.60466 6.97814 2.49996 4.33014 2.67923C3.27355 2.75076 2.77827 3.17131 2.52424 3.5444C2.23615 3.9675 2.16358 4.47386 2.1846 4.77166L2.18758 4.81388V20.5621C2.46963 22.362 3.49618 22.685 3.75085 22.7108H19.6388C20.2596 22.7108 20.6127 22.4263 20.8606 22.0279C21.137 21.5836 21.2587 21.0103 21.2749 20.628V4.85621C21.2749 3.8744 20.9009 3.38923 20.494 3.10551C20.0234 2.77738 19.4171 2.66755 19.0196 2.68101L18.9915 2.68196L18.9634 2.6814ZM18.9795 0.392244C15.208 0.316403 6.94525 0.208376 4.18969 0.394927C2.54134 0.506521 1.41875 1.2305 0.749325 2.21367C0.127296 3.12723 -0.0357415 4.16393 0.00618286 4.89417V20.735L0.0179575 20.8184C0.445292 23.8454 2.42811 24.9338 3.66011 24.9985L3.68733 24.9999H19.6388C21.1122 24.9999 22.1042 24.2162 22.6872 23.2793C23.237 22.3955 23.4322 21.3777 23.4556 20.6915L23.4563 20.6711V4.85621C23.4563 3.09114 22.7032 1.89767 21.7013 1.19901C20.7759 0.553753 19.7085 0.373392 18.9795 0.392244Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.6201 8.47291C9.37139 8.54199 7.61556 10.4936 7.6803 12.8142C7.74504 15.1347 9.60666 16.9752 11.8554 16.9061C14.1042 16.837 15.86 14.8854 15.7953 12.5649C15.7305 10.2443 13.8689 8.40383 11.6201 8.47291ZM5.49983 12.8811C5.39938 9.28048 8.11905 6.29043 11.5563 6.18484C14.9936 6.07924 17.8753 8.89722 17.9757 12.4979C18.0762 16.0985 15.3565 19.0886 11.9192 19.1942C8.48199 19.2998 5.60028 16.4818 5.49983 12.8811Z"
                        fill="white"
                      />
                      <path
                        d="M19.7666 5.97715C19.7909 6.84826 19.1325 7.57526 18.296 7.60096C17.4595 7.62666 16.7617 6.94132 16.7374 6.07021C16.7131 5.19909 17.3715 4.47209 18.208 4.44639C19.0445 4.42069 19.7423 5.10604 19.7666 5.97715Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                </a>

                <a
                  href="https://www.youtube.com/@SanjeevJainCBX"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="21"
                      viewBox="0 0 30 21"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.34383 0.475415C2.3618 0.475415 0.75504 2.16147 0.75504 4.24132C0.362613 9.47083 0.350221 12.2351 0.75504 16.7943C0.75504 18.8742 2.3618 20.5602 4.34383 20.5602C12.5644 21.1334 17.1783 21.1599 25.3981 20.5602C27.3801 20.5602 28.9869 18.8742 28.9869 16.7943C29.2238 11.8107 29.2287 9.05731 28.9869 4.24132C28.9869 2.16147 27.671 0.977517 25.3981 0.475415C23.1252 -0.0266868 11.4917 0.0417193 4.34383 0.475415ZM12.5386 14.999L19.3573 10.5418L12.5386 6.08462V14.999Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                </a>

                <a
                  href="https://www.facebook.com/ceoitbox/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="26"
                      viewBox="0 0 12 26"
                      fill="none"
                    >
                      <path
                        d="M2.53768 13.0506V26H7.56354V13.0506H10.6929L11.167 7.58755H7.56354C7.53193 7.25032 7.48768 6.37354 7.56354 5.5642C7.63941 4.75486 8.22734 4.55253 8.51182 4.55253H11.167V0H6.61527C3.65665 0 2.66412 2.76524 2.53768 4.14786V7.58755H0.166992V13.0506H2.53768Z"
                        fill="white"
                      />
                    </svg>
                  </Box>
                </a>

                <a
                  href="https://www.linkedin.com/in/sjtns"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box>
                    <img src="/linkdine.png" alt="linkdine" />
                  </Box>
                </a>
              </Box>
            </Box>
            <Box
              className="footerItemBox"
              sx={{ display: "flex", gap: "100px" }}
            >
              <Box className="needHelpComInfoBox">
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "30px",
                    textTransform: "uppercase",
                    mb: 1,
                    letterSpacing: "0.05em",
                  }}
                >
                  Company
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "27px",
                    mt: 1,
                  }}
                >
                  CEOITBOX TECH SERVICES LLP
                </Typography>
                <Typography
                  className="addresss"
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "27px",
                    mt: 1,
                    width: "200px",
                  }}
                >
                  293, Dhanmill Road, Chattarpur Hills, New Delhi-110074
                </Typography>
              </Box>
              <Box className="needHelpBox">
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "30px",
                    textTransform: "uppercase",
                    mb: 1,
                    letterSpacing: "0.05em",
                  }}
                >
                  Need Help?
                </Typography>
                <Typography
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "27px",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://ceoitbox.com/"
                    style={{ color: "inherit", textDecoration: "none" }}
                    target="_blank"
                  >
                    {" "}
                    About Us
                  </a>
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "27px",
                    mt: 1,
                    cursor: "pointer",
                  }}
                >
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=access@ceoitbox.in&su=Enquiry on CBXTREE&body="
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </Typography>

                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="#features"
                  onClick={scrollToSection}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "27px",
                      mt: 1,
                      cursor: "pointer",
                    }}
                  >
                    Features
                  </Typography>
                </a>
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="#faqs"
                  onClick={scrollToSection}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "27px",
                      mt: 1,
                      cursor: "pointer",
                    }}
                  >
                    FAQ`s
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>

          <Box
            className="logo-box"
            sx={{
              mt: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px",
                color: "#F8F8F8",
              }}
            >
              <span style={{ fontSize: "16px", fontWeight: "600" }}>©</span>{" "}
              2024 CEOITBOX All Rights Reserved
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
