import { React, useEffect, useMemo, useState } from "react";
import Link from "./Link.jsx";
import SocialLink from "../SocialLink.js";
import { useMediaQuery } from "../hook.js";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import "react-device-frameset/styles/device-selector.min.css";
import { Button, IconButton, CircularProgress } from "@mui/material";
import { CiMobile3 } from "react-icons/ci";
import { MdOutlineTabletAndroid } from "react-icons/md";
import { IoIosDesktop } from "react-icons/io";
import { ImTablet } from "react-icons/im";
import { Tooltip } from "antd";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const MobilePreview = (props) => {
  let { viewlinks, viewsetting } = props;
  const [page, setPage] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState("iPhone X");
  const devices = ["iPhone X", "Galaxy Note 8", "iPad Mini", "MacBook Pro"];

  const handleToggleSearch = () => {
    setIsSearch(!isSearch);
  };

  const handleColse = () => {
    setSearch("");
    setIsSearch(false);
  };

  const DriveUrl = (logoUrl) => {
    let link = logoUrl;
    if (logoUrl?.indexOf("https://drive.google.com") > -1) {
      var id = logoUrl.match(/[-\w]{25,}/)[0];
      link = "https://lh3.google.com/u/0/d/" + id;
    }
    return link;
  };

  useEffect(() => {
    setPage(viewsetting?.showLinksPerPage?.linksLimit);
  }, [viewsetting]);

  // useEffect(() => {
  //   if (viewlinks && viewlinks.length > 0 && viewsetting !== undefined) {
  //     setLoading(false);
  //   }
  // }, [viewsetting, viewlinks]);

  useEffect(() => {
    if (viewsetting !== undefined) {
      if (viewlinks && viewlinks.length > 0) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [viewsetting, viewlinks]);

  const lgBackground = (bg) => {
    if (bg && bg.includes("→")) {
      const colors = bg.split(" → ");
      return `linear-gradient(to right, ${colors[0]}, ${colors[1]})`;
    }
    return bg;
  };

  var bgImage = viewsetting?.backgroundImage;
  var bgLinear = viewsetting?.background;

  const backgroundStyle = {
    background:
      bgImage === "" || bgImage === undefined
        ? bgLinear !== ""
          ? lgBackground(bgLinear) || bgLinear
          : "#fff"
        : `url(${DriveUrl(bgImage)})`,
    padding: "20px 20px 60px 20px",
    minHeight: "100vh",
    height: "100%",
    backgroundPosition: "center ",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    top: "0",
    bottom: "0",
    boxSizing: "border-box",
  };

  // Search filter
  const isRowBased = useMediaQuery("(min-width: 500px)");
  const customStyles = {
    search: (isRowBased) => ({
      padding: "8px",
      marginInline: "20px",
      borderRadius: "20px",
      width: isRowBased ? "20%" : "60%",
      border: "none",
      cursor: "pointer",
      outline: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`,
    }),
  };

  const styleSearch = {
    search: (isRowBased) => ({
      // textAlign: "right",
      textAlign: isRowBased ? "right" : "center",
    }),
  };

  const searchData = useMemo(
    () =>
      viewlinks?.filter((item) => {
        const searchLower = search.toLowerCase();
        return (
          searchLower === "" ||
          item.description.toLowerCase().includes(searchLower) ||
          item.link.toLowerCase().includes(searchLower) ||
          item.linkType.toLowerCase().includes(searchLower) ||
          item.instantLink.toLowerCase().includes(searchLower)
        );
      }),
    [viewlinks, search]
  );

  //Pagination constant
  const recordsPerPage = page || 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  var records = searchData?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchData?.length / recordsPerPage);

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div
      className="device-frame-container"
      style={{
        position: "relative",
        marginRight: "-20%",
        bottom:
          device === "iPhone X"
            ? "10px"
            : "" || device === "Galaxy Note 8"
            ? "30px"
            : "",
        transform:
          device === "iPhone X" || device === "Galaxy Note 8"
            ? "scale(0.6)"
            : "scale(0.5)",
      }}
    >
      <div
        className="deviceBtnDiv"
        style={{
          position: "absolute",
          top:
            device === "iPhone X"
              ? "-75px"
              : device === "Galaxy Note 8"
              ? "-55px"
              : "-80px",
        }}
      >
        {devices?.map((deviceName) => (
          <Button
            className="deviceBtn"
            variant="contained"
            sx={{
              ml: "8px",
              // background: "#0d6efd",
              fontWeight: "bold",
              borderRadius: "0.25rem",
              fontSize:
                device === "iPhone X" || device === "Galaxy Note 8"
                  ? "16px"
                  : "20px",
            }}
            key={deviceName}
            onClick={() => setDevice(deviceName)}
          >
            {deviceName === "iPhone X"
              ? "I phone"
              : "" || deviceName === "Galaxy Note 8"
              ? "Android"
              : "" || deviceName === "iPad Mini"
              ? "Tab"
              : "" || deviceName === "MacBook Pro"
              ? "Desktop"
              : ""}
          </Button>
        ))}
      </div>

      {/* Responsive screen */}
      <div
        className="deviceDiv"
        style={{
          position: "absolute",
          top: device === "Galaxy Note 8" ? "-55px" : "-80px",
          display: "none",
        }}
      >
        {devices?.map((deviceName, i) => (
          <IconButton
            sx={{ ml: i == 0 ? "" : "15px" }}
            key={deviceName}
            onClick={() => setDevice(deviceName)}
          >
            {deviceName === "iPhone X" ? (
              <Tooltip title={"I Phone"}>
                <CiMobile3 size={38} color="#0d6efd" />
              </Tooltip>
            ) : "" || deviceName === "Galaxy Note 8" ? (
              <Tooltip title={"Android"}>
                <MdOutlineTabletAndroid size={30} color="#0d6efd" />
              </Tooltip>
            ) : "" || deviceName === "iPad Mini" ? (
              <Tooltip title={"Tablet"}>
                <ImTablet size={30} color="#0d6efd" />
              </Tooltip>
            ) : "" || deviceName === "MacBook Pro" ? (
              <Tooltip title={"Dasktop"}>
                <IoIosDesktop size={35} color="#0d6efd" />
              </Tooltip>
            ) : (
              ""
            )}
          </IconButton>
        ))}
      </div>
      <DeviceFrameset device={device}>
        <div
          className="previewMainContainer"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <div style={backgroundStyle} className="previewSubContainer">
            {loading && (
              <div>
                {" "}
                <CircularProgress size={18} style={{ color: "#00bce0" }} />
              </div>
            )}
            <div className="d-flex justify-content-center">
              {viewsetting && (
                <div className="m-2" style={{ textAlign: "center" }}>
                  <div>
                    {viewsetting.logo && (
                      // eslint-disable-next-line jsx-a11y/alt-text
                      <img
                        src={DriveUrl(viewsetting.logo)}
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                          margin: "10px",
                          padding: "5px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <h2
                      style={{
                        color: `${viewsetting?.headersSettings?.fontColor}`,
                        fontFamily: `${viewsetting?.headersSettings?.fontFamily}`,
                        fontSize: `${viewsetting?.headersSettings?.fontSize}`,
                        fontWeight: "550",
                      }}
                    >
                      {viewsetting.header}
                    </h2>
                  </div>
                </div>
              )}
            </div>
            {/* <div style={styleSearch.search(isRowBased)} className="searchDiv">
              {viewsetting?.search && (
                <input
                  type="text"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentPage(1);
                  }}
                  style={customStyles.search(isRowBased)}
                  id="gsearch"
                  name="gsearch"
                  placeholder="Search"
                />
              )}
            </div> */}
            {viewsetting?.search && (
              <div style={styleSearch.search(isRowBased)} className="searchDiv">
                {isSearch && viewsetting?.search ? (
                  <input
                    className="searchInput"
                    type="text"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setCurrentPage(1);
                    }}
                    style={customStyles.search(isRowBased)}
                    id="gsearch"
                    name="gsearch"
                    placeholder="Search"
                  />
                ) : (
                  <div> </div>
                )}

                {isSearch ? (
                  <IconButton
                    sx={{
                      padding: "4px",
                      border: `1px solid ${
                        viewsetting?.paginationSetting?.paginationBtnBorder ||
                        "#fff"
                      }} `,
                    }}
                    onClick={handleColse}
                  >
                    <IoClose
                      style={{
                        color: `${
                          viewsetting?.paginationSetting?.paginationBtnBorder ||
                          "#fff"
                        }`,
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      padding: "5px",
                      border: `1px solid ${
                        viewsetting?.paginationSetting?.paginationBtnBorder ||
                        "#fff"
                      }}`,
                    }}
                    onClick={handleToggleSearch}
                  >
                    <IoMdSearch
                      style={{
                        color: `${
                          viewsetting?.paginationSetting?.paginationBtnBorder ||
                          "#fff"
                        }`,
                      }}
                    />
                  </IconButton>
                )}
              </div>
            )}
            <div>
              {records?.map(
                (link, i) =>
                  link.isTrue && (
                    <Link link={link} viewsetting={viewsetting} key={i} />
                  )
              )}
            </div>
            {/* {viewsetting?.footer &&
              viewsetting?.leftFooter[0]?.leftFooterName !== "" ? ( */}
            <div className="d-flex justify-content-evenly my-3">
              {viewsetting?.footer &&
              viewsetting?.leftFooter.length > 0 &&
              viewsetting?.leftFooter[0]?.leftFooterName !== "" ? (
                <div>
                  <a
                    style={{
                      border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`,
                      color: `${viewsetting?.leftFooterSetting?.color}`,
                      fontFamily: `${viewsetting?.leftFooterSetting?.family}`,
                      fontSize: `${viewsetting?.leftFooterSetting?.size}`,
                      background: `${viewsetting?.leftFooterSetting?.background}`,
                    }}
                    type="button"
                    className="btn btn-primary"
                    href={viewsetting?.leftFooter[0]?.leftFooterURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {viewsetting?.leftFooter[0]?.leftFooterName}
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    type="button"
                    style={{ visibility: "hidden" }}
                    className="btn btn-primary"
                  >
                    ..
                  </a>
                </div>
              )}
              {/* {viewsetting?.footer && ( */}
              {searchData?.length > page ? (
                <div className="d-flex justify-content-evenly">
                  <div className="mx-2">
                    <button
                      style={{
                        border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`,
                        color: `${viewsetting?.paginationSetting?.color}`,
                        fontFamily: `${viewsetting?.paginationSetting?.family}`,
                        fontSize: `${viewsetting?.paginationSetting?.size}`,
                        background: `${viewsetting?.paginationSetting?.background}`,
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="mx-2">
                    <button
                      style={{
                        border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`,
                        color: `${viewsetting?.paginationSetting?.color}`,
                        fontFamily: `${viewsetting?.paginationSetting?.family}`,
                        fontSize: `${viewsetting?.paginationSetting?.size}`,
                        background: `${viewsetting?.paginationSetting?.background}`,
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={nextPage}
                      disabled={currentPage === npage}
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : null}

              {viewsetting?.footer &&
              viewsetting?.rightFooter.length > 0 &&
              viewsetting?.rightFooter[0]?.rightFooterName !== "" ? (
                <div>
                  <a
                    style={{
                      border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`,
                      color: `${viewsetting?.rightFooterSetting?.color}`,
                      fontFamily: `${viewsetting?.rightFooterSetting?.family}`,
                      fontSize: `${viewsetting?.rightFooterSetting?.size}`,
                      background: `${viewsetting?.rightFooterSetting?.background}`,
                    }}
                    type="button"
                    className="btn btn-primary"
                    href={viewsetting?.rightFooter[0]?.rightFooterURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {viewsetting?.rightFooter[0]?.rightFooterName}
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    type="button"
                    style={{ visibility: "hidden" }}
                    className="btn btn-primary"
                  >
                    ..
                  </a>
                </div>
              )}
            </div>

            {viewsetting?.companyName && (
              // header
              <div className="d-flex justify-content-center">
                <div className="companyTag">
                  {/* <a
                    href="https://ceoitbox.com/"
                    target="_blank"
                    style={{ textDecoration: "none", color: "inherit" }}
                    rel="noreferrer"
                  > */}
                  <h3
                    style={{
                      padding: "4px",
                      margin: "0px",
                      color: `${viewsetting?.headersSettings?.fontColor}`,
                      fontFamily: `${viewsetting?.headersSettings?.fontFamily}`,
                      fontSize: "25px",
                      fontWeight: "500",
                    }}
                  >
                    {viewsetting?.companyName}
                  </h3>
                  {/* </a> */}
                </div>
              </div>
            )}

            {viewsetting?.socialLinks?.length > 0 && (
              <div className="d-flex justify-content-center">
                {viewsetting?.socialLinks?.map((socialLink, i) => {
                  return (
                    <SocialLink
                      viewsetting={viewsetting}
                      linktype={socialLink?.linkType}
                      linkurl={socialLink?.linkUrl}
                      key={i}
                    />
                  );
                })}
                {/* <SocialLink /> */}
              </div>
            )}
            <div className="d-flex justify-content-center ">
              <h6
                style={{
                  color: `${
                    viewsetting?.descriptionSettings?.descriptionColor ||
                    viewsetting?.descriptionSettings?.color
                  }`,
                  fontFamily: `${viewsetting?.descriptionSettings?.family}`,
                  fontSize: `${viewsetting?.descriptionSettings?.size}`,
                  textAlign: "center",
                }}
              >
                {viewsetting?.description}
              </h6>
            </div>
          </div>
        </div>
      </DeviceFrameset>
    </div>
  );
};

export default MobilePreview;
