import React, { useEffect } from "react";

const CustomModal = ({ show, handleClose, heading, children }) => {
  const modalRef = React.useRef(null); // Create a ref for the modal

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    // Add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    // Return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`modal ${show ? "show" : ""}`}
      tabIndex="-1"
      style={{
        display: show ? "block" : "none",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="modal-dialog modal-dialog-centered" ref={modalRef}>
        <div className="modal-content" style={{ borderRadius: "10px" }}>
          <div className="modal-header">
            <h5 className="modal-title">{heading}</h5>
            <button
              style={{ fontSize: "14px" }}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
