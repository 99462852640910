import { Button, Box, Typography, Container, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import "./Auth.css";

const SendOtp = (props) => {
  let host = "http://localhost:3000/api";
  if (document.location.href.includes("localhost")) {
    host = "http://localhost:3000/api";
  } else {
    host = "api";
  }

  const [credentials, setCredentials] = useState({
    email: "",
    OTP: "",
    password: "",
    cpassword: "",
  });
  const [isEmail, setIsemail] = useState(false);
  const [countdown, setCountdown] = useState(360);
  const [countdownFinished, setCountdownFinished] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConformPassword = () => {
    setShowConformPassword(!showConformPassword);
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!credentials || typeof credentials !== "object") {
      // Make sure credentials is defined and is an object
      console.error("Credentials are missing or invalid.");
      return;
    }

    if (isEmail) {
      if (!credentials.OTP || credentials.OTP.trim() === "") {
        props.showAlert("Please enter your OTP", "danger");
        return;
      }
      if (
        !credentials.password ||
        !credentials.cpassword ||
        credentials.password.trim() === "" ||
        credentials.cpassword.trim() === ""
      ) {
        props.showAlert("Please enter your password", "danger");
        return;
      }
      if (credentials.password !== credentials.cpassword) {
        props.showAlert("Password and Confirm password do not match", "danger");
        setCredentials({ password: "", cpassword: "" });
        return;
      }
      if (credentials.password === credentials.cpassword) {
        const response = await fetch(`${host}/auth/validateOtp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: credentials.email,
            password: credentials.password,
            otp: credentials.OTP,
          }),
        });
        const json = await response.json();
        if (json.success) {
          navigate("/login");
          props.showAlert(json.msg, "success");
          setCountdown(360);
          setCountdownFinished(false);
        } else {
          props.showAlert(json.msg, "danger");
        }
      }
    } else {
      const response = await fetch(`${host}/auth/getOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: credentials.email }),
      });
      const json = await response.json();
      if (json.success) {
        // navigate('/forgetpassword');
        props.showAlert(json.msg, "success");
        setIsemail(true);
        setCountdown(360);
        setCountdownFinished(false);
      } else {
        props.showAlert(json.msg, "danger");
      }
    }
  };

  const handleResendOTP = async () => {
    // Implement logic to resend OTP
    const response = await fetch(`${host}/auth/getOtp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: credentials.email }),
    });
    const json = await response.json();
    if (json.success) {
      props.showAlert(json.msg, "success");
      setCountdown(360);
      setCountdownFinished(false);
    } else {
      props.showAlert(json.msg, "danger");
    }
  };

  const goBack = () => {
    setIsemail(false);
    clearInterval(intervalRef.current);
    setCountdownFinished(false); // Reset countdownFinished state
  };

  useEffect(() => {
    if (isEmail && countdown > 0) {
      // Set up the countdown interval
      intervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(intervalRef.current);
            setCountdownFinished(true); // Set countdownFinished state when countdown completes
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isEmail, countdown]);

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          borderRadius: "16px",
          background:
            "linear-gradient(110deg, rgba(245, 253, 255, 0.2) 3.48%, rgba(255, 245, 255, 0.2) 81.52%)",
          alignItems: "center",
          // paddingTop: "65px",
        }}
      >
        <Box
          className="formContainer"
          sx={{
            height: "auto",
            display: "flex",
            justifyContent: "center ",
            gap: "100px",
            pt: "50px",
            pb: "50px",
          }}
        >
          <Container
            sx={{ width: "450px" }}
            component="main"
            maxWidth="xs"
            className="formControl"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {isEmail && (
                <Box
                  onClick={goBack}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                    mb: 5,
                    width: "80px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                  >
                    <path
                      d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989592 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM22 5.25H1V6.75H22V5.25Z"
                      fill="#0A0932"
                    />
                  </svg>

                  <Typography
                    sx={{
                      color: "#6E7183",
                      fontSize: "16px",
                      fontWeight: 500,
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Back
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "13px",
                  mb: 5,
                }}
              >
                <Box>
                  <img src="lock.png" alt="lock" />
                </Box>
                <Typography
                  component="h1"
                  variant="h5"
                  className="checkFormHeading"
                  sx={{ fontWeight: "500", color: "#0D0D0D", fontSize: "30px" }}
                >
                  {isEmail ? "Reset Password" : "Confirm Email"}
                </Typography>
              </Box>

              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ width: "100%" }}
              >
                <Box sx={{ mb: "25px" }}>
                  <input
                    className="signInInputs"
                    placeholder="Email Address*"
                    type="email"
                    name="email"
                    value={credentials.email}
                    onChange={onChange}
                    required
                  />
                  <Typography
                    className="secInfoText"
                    sx={{
                      color: "#6E7183",
                      fontSize: "14px",
                      ml: "38px",
                      mt: "4px",
                      fontWeight: 500,
                    }}
                  >
                    (We'll never share your email with anyone else.)
                  </Typography>
                </Box>

                {isEmail && (
                  <>
                    <Box sx={{ mb: "25px" }}>
                      <input
                        className="signInInputs"
                        placeholder="Enter OTP*"
                        type="text"
                        name="OTP"
                        value={credentials.OTP}
                        onChange={onChange}
                        required
                      />

                      {countdownFinished ? (
                        // <Button
                        //   type="link"
                        //   onClick={handleResendOTP}
                        //   style={{
                        //     padding: "0px",
                        //     color: "blue",
                        //     fontWeight: "bold",
                        //   }}
                        // >
                        //   Resend OTP
                        // </Button>

                        <Typography
                          onClick={handleResendOTP}
                          className="resendOtp"
                        >
                          Resend OTP{" "}
                        </Typography>
                      ) : (
                        <Typography
                          className="secInfoText"
                          sx={{
                            color: "#6E7183",
                            fontSize: "14px",
                            ml: "38px",
                            mt: "4px",
                            fontWeight: 600,
                          }}
                        >
                          (OTP will be valid for the next{" "}
                          <span className="otpExpTime">
                            {Math.floor(countdown / 60)}:{countdown % 60}{" "}
                            minutes)
                          </span>{" "}
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ position: "relative", mb: "25px" }}>
                      <input
                        className="signInInputs"
                        placeholder="Password*"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={credentials.password}
                        onChange={onChange}
                        required
                      />
                      <Box
                        onClick={handleClickShowPassword}
                        sx={{
                          position: "absolute",
                          right: "3%",
                          bottom: "8px",
                        }}
                      >
                        <IconButton>
                          {showPassword ? (
                            <IoIosEye
                              size={20}
                              color="gray"
                              className="eyeIcon"
                              cursor="pointer"
                            />
                          ) : (
                            <IoIosEyeOff
                              size={20}
                              className="eyeIcon"
                              color="gray"
                              cursor="pointer"
                            />
                          )}
                        </IconButton>
                      </Box>
                    </Box>

                    <Box sx={{ position: "relative", mb: "5px" }}>
                      <input
                        className="signInInputs"
                        placeholder="Confirm Password*"
                        type={showConformPassword ? "text" : "password"}
                        name="cpassword"
                        value={credentials.cpassword}
                        onChange={onChange}
                        required
                      />
                      <Box
                        onClick={handleClickShowConformPassword}
                        sx={{
                          position: "absolute",
                          right: "3%",
                          bottom: "8px",
                        }}
                      >
                        <IconButton>
                          {showConformPassword ? (
                            <IoIosEye
                              size={20}
                              color="gray"
                              className="eyeIcon"
                              cursor="pointer"
                            />
                          ) : (
                            <IoIosEyeOff
                              size={20}
                              className="eyeIcon"
                              color="gray"
                              cursor="pointer"
                            />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                )}

                {/* Enter button */}
                <Box mt={5}>
                  <Button
                    className="enterButton"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      background:
                        credentials.email || credentials.OTP
                          ? "#0FB3E2"
                          : "rgba(204, 200, 216, 0.25)",
                      color:
                        credentials.email || credentials.OTP
                          ? "#fff"
                          : "#969FA1",
                      "&:hover": {
                        background:
                          credentials.email || credentials.OTP
                            ? "#2FCCFF"
                            : "rgba(204, 200, 216, 0.25)",
                      },
                    }}
                  >
                    Enter
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
          <Box className="sideImgBox">
            {isEmail ? (
              <img src="/HomePage/resetTheme.png" alt="Image" loading="lazy" />
            ) : (
              <img src="/themee.png" alt="Image" loading="lazy" />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SendOtp;
