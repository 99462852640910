import React, { useEffect, useState } from "react";
import { message } from "antd";

const Alert = ({ alert }) => {
  const [lastId, setLastId] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (alert && alert.id !== lastId) {
      // Check if the ID is different
      if (alert.type === "success") {
        messageApi.success(alert.msg, 5); // Set shorter duration
      } else if (alert.type === "danger") {
        messageApi.error(alert.msg, 5);
      }
      setLastId(alert.id); // Update the last ID
    }
  }, [alert, messageApi, lastId]);

  return <div>{contextHolder}</div>;
};

export default Alert;
