import React, { useContext, useState, useEffect } from "react";
import linkContext from "../context/links/linkContext";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "antd";

const Addlink = (props) => {
  const context = useContext(linkContext);
  const { settings } = props;
  const { addLink, addSheetLink, previewUrl } = context;
  const [add, setAdd] = useState(false);
  const [sheet, setSheet] = useState(false);
  const [displayLink, setDisplayLink] = useState(false);
  const [sheetLinkValue, setSheetLinkValue] = useState("");
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);

  const [links, setLink] = useState({
    description: "",
    link: "",
    linkType: "",
    instantLink: "",
  });

  function extractUserId(url) {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  }

  const userName = extractUserId(previewUrl);

  useEffect(() => {
    if (Array.isArray(settings)) {
      const data = settings?.filter((item) => item.userid === userName);
      setSheetLinkValue(data[0]?.sheetLink);
    }
  }, [settings, userName]);

  const handleClick = (e) => {
    e.preventDefault();
    const linkData = {
      ...links,
      userid: userName,
    };
    addLink(linkData);
    setLink({ description: "", link: "", linkType: "", instantLink: "" });
    setAdd(false);
    props.showAlert("Your Link Added Successfully", "success");
  };

  const onChange = (e) => {
    setLink({ ...links, [e.target.name]: e.target.value });

    if (e.target.name === "instantLink") {
      if (e.target.value.length > 0) {
        setIsLimitExceeded(true);
      } else {
        setIsLimitExceeded(false);
      }
    }
  };

  // useEffect(() => {
  //   if (links.linkType !== "Normal Link" && links.linkType.length !== 0) {
  //     setDisplayLink(true);
  //   } else {
  //     setDisplayLink(false);
  //   }
  // }, [links, setDisplayLink]);

  useEffect(() => {
    const typesRequiringDisplayLink = new Set([
      "Normal Image Link",
      "Drive Image Link",
      "Drive Video",
      "Youtube Video",
    ]);
    setDisplayLink(typesRequiringDisplayLink.has(links.linkType));
  }, [links.linkType]);

  const handleAdd = () => {
    add ? setAdd(false) : setAdd(true);
    if (sheet) {
      setSheet(false);
    }
  };

  const handleAddSheet = () => {
    sheet ? setSheet(false) : setSheet(true);
    if (add) {
      setAdd(false);
    }
  };

  const handleAddSheetLink = () => {
    addSheetLink(sheetLinkValue);
    if (sheet) {
      setSheet(false);
    }
  };

  return (
    <div style={{ position: "relative", zIndex: 100 }}>
      <div className="d-flex justify-content-center my-3">
        <div
          className="mx-2 addlinkButton"
          style={{
            flex: "1",
            backgroundColor: "#0D6EFD",
            color: "#fff",
            borderRadius: "20px",
            cursor: "pointer",
            textAlign: "center",
            paddingBlock: "8px",
          }}
          onClick={handleAdd}
        >
          <span className="my-2">
            {add ? (
              <i className="fa-solid fa-minus"></i>
            ) : (
              <i className="fa-regular fa-plus"></i>
            )}{" "}
            Add Link
          </span>
        </div>
        <div
          className="mx-2 addlinkButton"
          style={{
            flex: "1",
            backgroundColor: "#4F8B33",
            color: "#fff",
            borderRadius: "20px",
            cursor: "pointer",
            textAlign: "center",
            paddingBlock: "8px",
          }}
          onClick={handleAddSheet}
        >
          <span className="my-2">
            {sheet ? (
              <i className="fa-solid fa-minus"></i>
            ) : (
              <i className="fa-regular fa-plus"></i>
            )}{" "}
            Google Sheet
          </span>
        </div>
      </div>

      {sheet ? (
        <div
          className="shadow-sm bg-white"
          style={{
            padding: "20px",
            borderRadius: "20px",
            marginBottom: "5px",
          }}
        >
          {sheetLinkValue ? (
            <>
              <button
                style={{ fontSize: "14px" }}
                type="submit"
                onClick={() => window.open(sheetLinkValue, "_blank")}
                className="btn btn-primary mx-1 shadow-none"
              >
                Open Your Google Sheet
              </button>
              <span
                style={{
                  margin: "5px 0px 0px 5px",
                }}
              >
                <Tooltip title="Click the button to open the specified Google Sheet in a new tab.">
                  <FaInfoCircle size={14} cursor={"pointer"} color="#0d6efd" />
                </Tooltip>
              </span>
            </>
          ) : (
            <>
              <button
                style={{
                  background: "#4F8B33",
                  border: "1px solid #4F8B33",
                  fontSize: "14px",
                }}
                target="_blank"
                type="submit"
                className="btn btn-primary mx-1 shadow-none"
                onClick={() =>
                  window.open(
                    "https://docs.google.com/spreadsheets/d/18ONY6rzZzGtudRJF7AClEAv0cmc40jSiRb8Q1jxKvdY/copy",
                    "_blank"
                  )
                }
              >
                Copy Google Sheet
              </button>
              <span
                style={{
                  margin: "5px 0px 0px 5px",
                }}
              >
                <Tooltip title="Click to copy the Google Sheet to your account.">
                  <FaInfoCircle size={14} cursor={"pointer"} color="#4F8B33" />
                </Tooltip>
              </span>
            </>
          )}

          <div className="input-group " style={{ margin: "10px 5px" }}>
            <input
              type="text"
              className="form-control shadow-none input-hover-effect"
              placeholder="Add Your Sheet Link"
              aria-label="Add Header"
              aria-describedby="button-addon2"
              value={sheetLinkValue}
              onChange={(e) => setSheetLinkValue(e.target.value)}
            />
            <button
              style={{ background: "#4F8B33", border: "1px solid #4F8B33" }}
              className="btn btn-primary shadow-none"
              type="button"
              id="button-addon2"
              onClick={handleAddSheetLink}
              disabled={!sheetLinkValue}
            >
              Add
            </button>
            <span
              style={{
                margin: "5px 0px 0px 5px",
              }}
            >
              <Tooltip title="Enter Your Sheet Link">
                <FaInfoCircle size={14} cursor={"pointer"} color="#4F8B33" />
              </Tooltip>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}

      {add ? (
        <form
          style={{
            padding: "10px",
            borderRadius: "8px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="text"
              className="form-control shadow-none input-hover-effect"
              id="description"
              name="description"
              aria-describedby="emailHelp"
              onChange={onChange}
              placeholder="Enter Link Title"
            />
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              <Tooltip title="To create a hyperlink that will take someone to a specific website or online resource, you need to provide the text that you want to display as the clickable link.">
                <FaInfoCircle size={14} cursor={"pointer"} color="#4F8B33" />
              </Tooltip>
            </span>
          </div>
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="text"
              className="form-control shadow-none input-hover-effect"
              id="link"
              name="link"
              onChange={onChange}
              placeholder="Enter Redirect Link"
            />
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              <Tooltip title="You need to provide the URL that you want their browser to be redirected to.">
                <FaInfoCircle size={14} cursor={"pointer"} color="#4F8B33" />
              </Tooltip>
            </span>
          </div>
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <select
              className="form-select shadow-none input-hover-effect"
              id="linkType"
              name="linkType"
              aria-label="Default select example"
              onChange={onChange}
              placeholder="Select"
            >
              <option value="DEFAULT" disabled selected>
                Select Link Type
              </option>
              <option value="Normal Link">Normal Link</option>
              <option value="Add Description">Add Description</option>
              <option value="Normal Image Link">Normal Image Link</option>
              <option value="Drive Image Link">Drive Image Link</option>
              <option value="Drive Video">Drive Video</option>
              <option value="Youtube Video">Youtube Video</option>
            </select>
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              <Tooltip title="You need to choose the specific kind of link you want, such as Normal link, Drive Image link, Youtube link etc.">
                <FaInfoCircle size={14} cursor={"pointer"} color="#4F8B33" />
              </Tooltip>
            </span>
          </div>
          {links?.linkType === "Add Description" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: isLimitExceeded ? "5px" : "18px",
                }}
                // className="mb-3"
              >
                <textarea
                  className="form-control shadow-none input-hover-effect"
                  type="text"
                  id="instantLink"
                  name="instantLink"
                  onChange={onChange}
                  placeholder="Enter description"
                  maxLength="600"
                />
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  <Tooltip title="Enter description">
                    <FaInfoCircle
                      size={14}
                      cursor={"pointer"}
                      color="#4F8B33"
                    />
                  </Tooltip>
                </span>
              </div>
              {isLimitExceeded && (
                <div
                  className="mb-2"
                  style={{ color: "red", fontSize: "14px" }}
                >
                  Maximum length of {links.instantLink.length}/600 characters
                  exceeded!
                </div>
              )}
            </>
          )}

          {displayLink && (
            <div
              className="mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="text"
                className="form-control shadow-none input-hover-effect"
                id="instantLink"
                name="instantLink"
                onChange={onChange}
                placeholder="Enter Display Link"
              />
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                <Tooltip title="Enter display link">
                  <FaInfoCircle size={14} cursor={"pointer"} color="#4F8B33" />
                </Tooltip>
              </span>
            </div>
          )}

          <button
            disabled={
              links.description.length === 0 ||
              links.link.length === 0 ||
              links.linkType.length === 0
            }
            type="submit"
            className={
              links.description.length === 0 ||
              links.link.length === 0 ||
              links.linkType.length === 0
                ? "btn btn-secondary shadow-none"
                : "btn btn-primary shadow-none"
            }
            onClick={handleClick}
          >
            Submit
          </button>
        </form>
      ) : (
        ""
      )}
    </div>
  );
};

export default Addlink;
