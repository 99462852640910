import { Button, Box, Typography, Container, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import linkContext from "../../context/links/linkContext";
import { message } from "antd";

const CheckPassword = ({ setIsShow, userName }) => {
  const context = useContext(linkContext);
  let { getSettings, viewsetting } = context;
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [yourPassword, setYourPassword] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (viewsetting) {
      const data = viewsetting?.filter((item) => item.userid === userName);
      setYourPassword(data[0]?.protectPassword || {});
    }
  }, [viewsetting, userName]);

  useEffect(() => {
    getSettings();
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === yourPassword) {
      setIsShow(true);
      success("Checked");
    } else {
      error("Invalid password.");
      setIsShow(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          borderRadius: "16px",
          // background:
          //   "linear-gradient(110deg, rgba(245, 253, 255, 0.2) 3.48%, rgba(255, 245, 255, 0.2) 81.52%)",
          backgroundImage: `url('bgggg.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          className="checkPasswordNav"
          sx={{
            p: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography
            className="checkPasswordHeading"
            sx={{ fontWeight: 700, fontSize: "40px" }}
          >
            CBXTREE
            {/* <span
              style={{ color: "#0D0D0D", fontWeight: 700, fontSize: "40px" }}
            >
              CBX
            </span>
            <span
              style={{ color: "#369978", fontWeight: 700, fontSize: "40px" }}
            >
              TREE
            </span> */}
          </Typography>
          <div id="google_translate_element"></div>
        </Box>

        <Container
          component="main"
          maxWidth="xs"
          className="formControl"
          sx={{
            mt: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "13px",
                mb: 6,
              }}
            >
              <Box>
                <img src="lock.png" alt="" />
              </Box>
              <Typography
                component="h1"
                variant="h5"
                className="checkFormHeading"
                sx={{
                  fontWeight: "500",
                  color: "#0D0D0D",
                  fontSize: "30px",
                }}
              >
                Please Enter the Password{" "}
                <span
                  className="toLoginText"
                  //  style={{ color: "#369978" }}
                >
                  {" "}
                  to Log In
                </span>
              </Typography>
            </Box>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                mt: 0,
                width: "100%",
              }}
            >
              <input
                className="checkPasswordInput"
                style={{
                  background: "#fff",
                  borderRadius: "344px",
                  width: "100%",
                  border: "1px solid #EAF5F0",
                  padding: "10px 27px",
                  outline: "none",
                  boxShadow: "26px 12px 26.9px 0px #D5E5DEB0",
                }}
                placeholder="Password*"
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                value={password}
                required
              />
              <Box
                onClick={handleClickShowPassword}
                sx={{
                  position: "absolute",
                  right: "36%",
                  bottom: "82px",
                }}
              >
                {showPassword ? (
                  <IconButton>
                    <IoIosEye
                      size={20}
                      color="gray"
                      className="eyeIcon"
                      cursor="pointer"
                    />
                  </IconButton>
                ) : (
                  <IconButton>
                    <IoIosEyeOff
                      size={20}
                      className="eyeIcon"
                      color="gray"
                      cursor="pointer"
                    />
                  </IconButton>
                )}
              </Box>

              <Button
                className="checkPasswordBtn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  position: "relative",
                  width: "140px",
                  left: "65%",
                  bottom: "69px",
                  padding: "8px",
                  mt: 3,
                  mb: 1,
                  fontWeight: 500,
                  fontSize: "16px",
                  transition: "all 0.3s ease",
                  borderRadius: "79px",
                  textTransform: "none",
                  // background: "#369978",
                  background: "#00bce0",
                  "&:hover": {
                    // background: "#369978",
                    background: "#2FCCFF",
                  },
                }}
              >
                Enter
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CheckPassword;
