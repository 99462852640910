import React, { useRef } from "react";
import { Box, Modal, IconButton, Typography, Tooltip } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import QRCode from "qrcode.react";
import { toPng } from "html-to-image";
import { FiDownload } from "react-icons/fi";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: "15px 20px",
};

const QRCodeModal = ({ open, handleClose, qrUrl, userName }) => {
  const qrRef = useRef();

  const handleDownload = () => {
    if (qrRef.current) {
      toPng(qrRef.current, {
        filter: (node) => {
          return !(
            node.nodeName === "STYLE" &&
            node.sheet &&
            node.sheet.href &&
            node.sheet.href.includes("fonts.googleapis.com")
          );
        },
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${userName}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error("Failed to download QR code as png:", err);
        });
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="muiModalBox" sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "22px" }}>
              QR Code
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Tooltip title="Download QR Code as PNG" arrow>
                <IconButton onClick={handleDownload}>
                  <FiDownload />
                </IconButton>
              </Tooltip>

              <IconButton sx={{ p: "4px" }} onClick={handleClose}>
                <IoIosCloseCircleOutline size={30} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <div ref={qrRef}>
              <QRCode value={qrUrl} size={256} />
            </div>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default QRCodeModal;
