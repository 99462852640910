import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { MdChevronRight } from "react-icons/md";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./AdvanceFeature.css";
import { useNavigate } from "react-router-dom";

const cardVariants = {
  hidden: { x: 100, opacity: 0 },
  visible: (i) => ({
    x: 0,
    opacity: 1,
    transition: { delay: i * 0.2, duration: 0.5 },
  }),
};

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const AdvanceFeatures = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const handleClick = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <Box
      id="features"
      className="mainFeatureBox"
      sx={{
        width: "100%",
        height: "922px",
        background: "#fff",
        backgroundImage: "url('/HomePage/advanceFeatureBg.svg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        p: "10% 0px",
      }}
      ref={ref}
    >
      <Box
        className="featureMainContainer"
        sx={{
          width: "85%",
          m: "auto",
          display: "flex",
          alignItems: "center",
          gap: "75px",
          position: "relative",
        }}
      >
        <motion.div
          className="leftHeadingBox"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { x: -100, opacity: 0 },
            visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
          }}
        >
          <Typography className="featuresHeading" sx={{ fontSize: "50px" }}>
            Advanced Features for{" "}
            <span className="powerText"> Power Users</span>
          </Typography>
          <Button
            onClick={handleClick}
            className="createPageButton"
            endIcon={<MdChevronRight />}
          >
            Create Your Tree
          </Button>
        </motion.div>
        <motion.div
          className="rightFeaturesCardBox"
          initial="hidden"
          animate={controls}
          variants={containerVariants}
        >
          {[
            "/HomePage/sheet.svg",
            "/HomePage/Analytics.png",
            "/HomePage/password.svg",
            "/HomePage/multipal.svg",
          ].map((src, index) => (
            <motion.div
              className="featureCard"
              whileHover={{ scale: 1.1 }}
              variants={cardVariants}
              custom={index}
              key={index}
              style={
                index === 0 || index === 2
                  ? { position: "relative", left: "25px", top: "80px" }
                  : {}
              }
            >
              <Box mb={1}>
                <img
                  className="featureImg"
                  style={{
                    width: src === "/HomePage/Analytics.png" ? "38px" : "23px",
                    height: src === "/HomePage/Analytics.png" ? "39px" : "23px",
                  }}
                  src={src}
                  alt="feature"
                />
              </Box>
              <Typography className="featuresText" sx={{ fontSize: "20px" }}>
                {src === "/HomePage/sheet.svg"
                  ? "Google Sheets Integration"
                  : src === "/HomePage/Analytics.png"
                  ? "Detailed Analytics"
                  : src === "/HomePage/password.svg"
                  ? "Password Protection"
                  : "Design Customization"}
              </Typography>
            </motion.div>
          ))}
        </motion.div>
      </Box>
    </Box>
  );
};

export default AdvanceFeatures;
