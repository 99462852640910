import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import linkContext from "../../context/links/linkContext";
import { message } from "antd";
import axios from "axios";
import "./Navbar.css";

const modalStyle = {
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: 24,
  p: 4,
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "15px",
};

let host = "http://localhost:3000/api";
if (document.location.href.includes("localhost")) {
  host = "http://localhost:3000/api";
} else {
  host = "api";
}

const ReportAnIssueModal = ({ open, setOpen }) => {
  const { userDetails, user } = useContext(linkContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [issueType, setIssueType] = useState("");
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  useEffect(() => {
    userDetails();
  }, []);

  const resetForm = () => {
    setIssueType("");
    setTitle("");
    setDetail("");
    setIsUrgent(false);
    setIsSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const temp = {
      issueType,
      title,
      details: detail,
      isUrgent,
      isResolved: false,
      userID: user?._id,
      userEmail: user?.email,
    };
    setIsSubmitting(true);

    try {
      const res = await axios.post(`${host}/issue/reportIssue`, temp, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      console.log("res", res);
    } catch (error) {
      message.error(error.response?.data?.message || "Internal Server Error");
      setIsSubmitting(false);
    }
    setTimeout(() => {
      resetForm();
      setIsSuccess(true);
      setIsSubmitting(false);
    }, 1500);
  };

  const handleClose = () => {
    resetForm();
    setIsSuccess(false);
    setOpen(false);
  };

  const isFormInvalid = !issueType || !title || !detail;

  return (
    <>
      {contextHolder}

      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "100px",
          overflowY: "scroll",
        }}
        open={open}
        onClose={handleClose}
      >
        <Box component={"form"} sx={modalStyle} onSubmit={handleSubmit}>
          {!isSuccess && (
            <>
              <Box
                width={"100%"}
                borderBottom={"2px solid rgba(239, 239, 239, 1)"}
              >
                <Typography variant="h5" component="h4" sx={{ mb: 2 }}>
                  Report a Problem
                </Typography>
              </Box>

              <TextField
                size="small"
                required
                className="report-issue-select"
                select
                fullWidth
                label="Issue Type"
                value={issueType}
                variant="filled"
                InputLabelProps={{
                  style: {
                    fontSize: "14px",
                    textTransform: "none",
                    fontWeight: 500,
                  },
                }}
                sx={{
                  background: "#f1ffeb",
                  borderRadius: "10px",
                  overflow: "hidden",
                  "&:hover": { background: "#f1ffeb !important" },
                }}
                onChange={(e) => setIssueType(e.target.value)}
              >
                <MenuItem
                  value="analyticalIssue"
                  sx={{ background: "#f1ffeb" }}
                >
                  Analytical Issue
                </MenuItem>
                <MenuItem value="executiveIssue" sx={{ background: "#f1ffeb" }}>
                  Executive Issue
                </MenuItem>
                <MenuItem
                  value="operationalIssue"
                  sx={{ background: "#f1ffeb" }}
                >
                  Operational Issue
                </MenuItem>
                <MenuItem value="tacticalIssue" sx={{ background: "#f1ffeb" }}>
                  Tactical Issue
                </MenuItem>
              </TextField>

              <TextField
                size="small"
                required
                className="report-issue-textField"
                variant="filled"
                InputLabelProps={{
                  style: {
                    color: "rgba(124, 124, 124, 1)",
                    fontSize: "14px",
                    textTransform: "none",
                    fontWeight: 500,
                  },
                }}
                sx={{ borderRadius: "10px", overflow: "hidden" }}
                fullWidth
                label="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <TextField
                size="small"
                required
                spellCheck="false"
                className="report-issue-textField"
                variant="filled"
                InputLabelProps={{
                  style: {
                    color: "rgba(124, 124, 124, 1)",
                    fontSize: "14px",
                    textTransform: "none",
                    fontWeight: 500,
                  },
                }}
                sx={{
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
                fullWidth
                multiline
                rows={4}
                label="Enter in detail"
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />

              <Box width={"100%"}>
                <FormControlLabel
                  size="small"
                  sx={{ textTransform: "none", fontWeight: 500 }}
                  control={
                    <Checkbox
                      size="small"
                      checked={isUrgent}
                      onChange={(e) => setIsUrgent(e.target.checked)}
                    />
                  }
                  label="Is this issue urgent?"
                />
              </Box>

              <Button
                type="submit"
                variant="contained"
                disabled={isFormInvalid}
                sx={{
                  borderRadius: "30px",
                  width: "150px",
                  textTransform: "none",
                  fontSize: "16px",
                  padding: "10px 30px",
                  color: "#fff",
                  background: "#0FB3E2",
                  "&:hover": {
                    color: "#fff",
                    background: "#2FCCFF",
                  },
                }}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </>
          )}

          {isSuccess && (
            <>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <Box
                    display={"grid"}
                    sx={{
                      width: "fit-content",
                      placeItems: "center",
                      bgcolor: "rgba(209, 255, 183, 1)",
                      padding: "15px",
                      borderRadius: "50%",
                    }}
                  >
                    <svg
                      style={{
                        position: "relative",
                        left: "2px",
                        top: "-2px",
                      }}
                      width="42"
                      height="41"
                      viewBox="0 0 42 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32.0278 13.5459L13.4272 32.1466L4.97229 23.6917"
                        stroke="#4D8733"
                        stroke-width="4.50926"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Box>
                  <Typography fontSize={"20px"} fontWeight={"500"}>
                    Success!
                  </Typography>
                </Box>
                <Typography mt={"10px"} textAlign={"center"}>
                  Your Report request has been submitted successfully!
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ReportAnIssueModal;
