import {
  Button,
  Box,
  Typography,
  Container,
  IconButton,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import linkContext from "../../context/links/linkContext";
import "./Auth.css";

const Login = (props) => {
  const context = useContext(linkContext);
  const { getSettings, userDetails } = context;
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [loginAdmin, setLoginAdmin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  let host = "http://localhost:3000/api";
  if (document.location.href.includes("localhost")) {
    host = "http://localhost:3000/api";
  } else {
    host = "api";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = loginAdmin
      ? `${host}/auth/adminlogin`
      : `${host}/auth/login`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: credentials.email,
        password: credentials.password,
      }),
    });
    const json = await response.json();
    if (json.success) {
      const tokenKey = loginAdmin ? "adminToken" : "token";
      localStorage.setItem(tokenKey, json.authtoken);
      navigate(loginAdmin ? "/users" : "/link");
      props.showAlert("Logged in Successfully", "success");
      if (!loginAdmin) {
        getSettings();
        userDetails();
      }
    } else {
      props.showAlert(json.error || "Invalid Details", "danger");
    }
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (localStorage.getItem("token")) {
    return <Navigate to="/link" />;
  }

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          borderRadius: "16px",
          background:
            "linear-gradient(110deg, rgba(245, 253, 255, 0.2) 3.48%, rgba(255, 245, 255, 0.2) 81.52%)",

          alignItems: "center",
          // paddingTop: "65px",
        }}
      >
        <Box
          className="formContainer"
          sx={{
            height: "auto",
            display: "flex",
            justifyContent: "center ",
            gap: "100px",
            pt: "50px",
            pb: "50px",
          }}
        >
          <Container
            sx={{ width: "450px" }}
            component="main"
            maxWidth="xs"
            className="formControl"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "13px",
                  mb: 5,
                }}
              >
                <Box>
                  <img src="lock.png" alt="lock" />
                </Box>
                <Typography
                  component="h1"
                  variant="h5"
                  className="checkFormHeading"
                  sx={{ fontWeight: "500", color: "#0D0D0D", fontSize: "30px" }}
                >
                  Please Enter the Details{" "}
                  <span className="toSignUpText"> to Log In</span>
                </Typography>
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  mb: "25px",
                  ml: "38px",
                }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={!loginAdmin}
                      onClick={() => setLoginAdmin(false)}
                      sx={{
                        color: "#161616",
                        "&.Mui-checked": {
                          color: "#00BCE0",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#161616",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      User
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={loginAdmin}
                      onClick={() => setLoginAdmin(true)}
                      sx={{
                        color: "#161616",
                        "&.Mui-checked": {
                          color: "#00BCE0",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#161616",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Admin
                    </Typography>
                  }
                />
              </Box> */}

              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ width: "100%" }}
              >
                <Box sx={{ mb: "25px" }}>
                  <input
                    className="signInInputs"
                    placeholder="Email Address*"
                    type="email"
                    name="email"
                    value={credentials.email}
                    onChange={onChange}
                    required
                  />
                  <Typography
                    className="secInfoText"
                    sx={{
                      color: "#6E7183",
                      fontSize: "14px",
                      ml: "38px",
                      mt: "4px",
                      fontWeight: 500,
                    }}
                  >
                    (We'll never share your email with anyone else.)
                  </Typography>
                </Box>

                <Box sx={{ position: "relative", mb: "25px" }}>
                  <input
                    className="signInInputs"
                    placeholder="Password*"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={credentials.password}
                    onChange={onChange}
                    required
                  />
                  <Box
                    onClick={handleClickShowPassword}
                    sx={{
                      position: "absolute",
                      right: "3%",
                      bottom: "42px",
                    }}
                  >
                    <IconButton>
                      {showPassword ? (
                        <IoIosEye
                          size={20}
                          color="gray"
                          className="eyeIcon"
                          cursor="pointer"
                        />
                      ) : (
                        <IoIosEyeOff
                          size={20}
                          className="eyeIcon"
                          color="gray"
                          cursor="pointer"
                        />
                      )}
                    </IconButton>
                  </Box>
                  <Link to="/sendotp">
                    <Typography
                      className="forgotPasswordText"
                      sx={{
                        color: "#6E7183",
                        fontSize: "16px",
                        ml: "38px",
                        mt: "10px",
                      }}
                    >
                      Forgot Password?
                    </Typography>
                  </Link>
                </Box>

                {/* Enter button */}
                <Box mt={5}>
                  <Button
                    className="enterButton"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      background:
                        credentials.email && credentials.password
                          ? "#0FB3E2"
                          : "rgba(204, 200, 216, 0.25)",
                      color:
                        credentials.email && credentials.password
                          ? "#fff"
                          : "#969FA1",
                      "&:hover": {
                        background:
                          credentials.email && credentials.password
                            ? "#2FCCFF"
                            : "rgba(204, 200, 216, 0.25)",
                      },
                    }}
                  >
                    Enter
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
          <Box className="sideImgBox">
            <img src="/HomePage/themeImg.png" alt="Image" loading="lazy" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
