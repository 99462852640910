import React, { useContext, useEffect, useState } from "react";
import { MenuItem, Box } from "@mui/material";
import linkContext from "../../context/links/linkContext";
import { CiEdit } from "react-icons/ci";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { Tooltip } from "antd";
import { MdDashboard } from "react-icons/md";

const LinksPopover = ({ userName, isHovered }) => {
  let host = "http://localhost:3000/api";
  if (document.location.href.includes("localhost")) {
    host = "http://localhost:3000/api";
  } else {
    host = "api";
  }

  const navigate = useNavigate();
  const context = useContext(linkContext);
  let {
    userDetails,
    user,
    settings,
    getSettings,
    getLinks,
    allUsers,
    UserData,
    shareSettingData,
    fetchShareCbxtreeData,
    loadLinks,
    loadSettings,
  } = context;

  const [filteredData, setFilteredData] = useState([]);

  // Get share treee
  useEffect(() => {
    fetchShareCbxtreeData();
  }, []);

  useEffect(() => {
    if (user && user.userid && settings && shareSettingData) {
      const shareTreeDataMap = new Map();
      shareSettingData.forEach((shareItem) => {
        shareTreeDataMap.set(shareItem.userid, shareItem);
      });

      // Step 2: Combine user settings with shareTreeData
      let data = user.userid.map((name, index) => {
        const userSetting =
          (settings && settings.find((setting) => setting.userid === name)) ||
          {};
        const shareTreeUser = shareTreeDataMap.get(name);

        const shareUser = allUsers.find(
          (u) => u._id === (shareTreeUser ? shareTreeUser.user : "")
        );

        return {
          _id: index,
          name: name,
          isActived: userSetting.isActived ?? true,
          isPassword: userSetting.protectPassword ?? {},
          isSecure: userSetting.isSecureLink ?? "",
          highlight: !!shareTreeUser, // Check if the user is in shareTreeData
          user: userSetting.user || "",
          shareUserName: shareUser ? shareUser.name : "",
        };
      });

      // Step 3: Add any users in shareTreeData that are not in user.userid
      shareSettingData.forEach((shareItem, index) => {
        if (!user.userid.includes(shareItem.userid)) {
          const shareUser = allUsers.find((u) => u._id === shareItem.user);
          data.push({
            _id: data.length + index, // Ensure unique ID
            name: shareItem.userid,
            isActived: shareItem.isActived ?? true,
            isPassword: shareItem.protectPassword ?? {},
            isSecure: shareItem.isSecureLink ?? "",
            highlight: true,
            user: shareItem.user || "",
            shareUserName: shareUser ? shareUser.name : "",
          });
        }
      });

      data?.sort((a, b) => {
        if (a.name === userName) return -1;
        if (b.name === userName) return 1;
        return b.isActived === a.isActived ? 0 : b.isActived ? 1 : -1;
      });

      const top10Data = data?.slice(0, 10);
      setFilteredData(top10Data);
    }
  }, [user && user.userid, settings, shareSettingData, allUsers, userName]);

  useEffect(() => {
    userDetails();
    UserData();
    getSettings();
    getLinks();
  }, []);

  //   Handle edit cbxtree
  const handleEditShareLink = (name, user) => {
    const data = allUsers?.filter(
      (item) => item.userid.includes(name) && item._id === user
    );

    if (data && data.length > 0) {
      Cookies.set("userInfo", JSON.stringify(data[0]), { expires: 365 });
    }
    Cookies.set("cbxtree", name, { expires: 365 });
    navigate("/link");
  };

  // Handle edit links
  const handleEditLinks = async (useridToMove) => {
    Cookies.remove("cbxtree");
    Cookies.remove("userInfo");

    try {
      const response = await fetch(
        `${host}/auth/updateUseridOrder/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ useridToMove }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update userid order");
      }
      const responseText = await response.text();
      navigate("/link");
      userDetails();
      getLinks();
      getSettings();
      loadSettings();
      loadLinks();
    } catch (error) {
      console.error("Error updating userid order:", error);
    }
  };

  const handleClickViewMore = (e) => {
    e.stopPropagation();
    navigate("/dashboard");
  };

  return (
    <>
      <div>
        {isHovered && (
          <Box sx={{ boxShadow: 1 }} id="accountMenu">
            <div id="menuItemDiv">
              {filteredData &&
                filteredData.map((item, i) => (
                  <MenuItem
                    key={i}
                    onClick={() =>
                      item.highlight
                        ? handleEditShareLink(item.name, item.user)
                        : handleEditLinks(item.name)
                    }
                    sx={{
                      m: "5px 10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <CiEdit color="#00bce0" />
                      <span className="linksDropdown">{item.name}</span>
                    </Box>
                    {item.highlight && (
                      <Tooltip title={` Shared by ${item.shareUserName}`}>
                        <FaRegShareFromSquare color="#00bce0" />
                      </Tooltip>
                    )}
                  </MenuItem>
                ))}

              <>
                <div
                  style={{
                    width: "90%",
                    height: "1px",
                    margin: "auto",
                    backgroundColor: "#ccc",
                  }}
                />

                <MenuItem
                  onClick={(e) => handleClickViewMore(e)}
                  sx={{
                    m: "5px 10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <MdDashboard color="#00bce0" />
                  <span className="linksDropdown"> View More</span>
                </MenuItem>
              </>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default LinksPopover;

{
  /* <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "left",
        // }}
        disableRestoreFocus
        sx={{
          mt: "5px",
          borderRadius: "16px",
          border: "1px solid red",
          width: "auto",
          "& .MuiPaper-root": {
            mt: "5px",
            borderRadius: "16px",
            width: "auto",
          },
        }}
      >
        {filteredData &&
          filteredData.map((item, i) => {
            return (
              <MenuItem
                onClick={() =>
                  item.highlight
                    ? handleEditShareLink(item.name, item.user)
                    : handleEditLinks(item.name)
                }
                key={i}
                sx={{
                  m: "10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <CiEdit color="#00bce0" />
                <span className="linksDropdown">{item.name}</span>
              </MenuItem>
            );
          })}
      </Popover> */
}
