export const basicTheme = [
  {
    ids: 0,
    color: "#000", //description and other fonts
    fontColor: "#000", //Main heading font color
    linksBorderColor: "#fff",
    background: "#fff",
    socialIconColor: "#3458eb",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "#fff",
    borderRadius: "5px",
    descriptionColor: "",
    paginationBtnBorder: "#3458eb",
    transition: "",
    transform: "",
    linksBackgroundColor: "#3458eb",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Air Moon",
  },

  {
    ids: 1,
    color: "#000", //description and other fonts
    fontColor: "#000", //Main heading font color
    linksBorderColor: "#fff",
    background: "#fff",
    socialIconColor: "#34eb77",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "15px",
    descriptionColor: "",
    paginationBtnBorder: "#34eb77",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#34eb77",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Air Leaf",
  },
  {
    ids: 2,
    color: "#000", //description and other fonts
    fontColor: "#000", //Main heading font color
    linksBorderColor: "#fff",
    background: "#fff",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "5px",
    descriptionColor: "",
    paginationBtnBorder: "#F2F2F2",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#F2F2F2",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Air White",
  },
  {
    ids: 3,
    color: "#000", //description and other fonts
    fontColor: "#000", //Main heading font color
    linksBorderColor: "#fff",
    background: "#fff",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "5px",
    descriptionColor: "",
    paginationBtnBorder: "#FFD700",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#FFD700",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Air Yellow",
  },
];

export const coloursTheme = [
  {
    ids: 0,
    color: "#000", //description and other fonts
    fontColor: "#000", //Main heading font color
    linksBorderColor: "#fff",
    background: "#CCCCCC ",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "5px",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Air Gray",
  },
  {
    ids: 1,
    color: "#fff", //description and other fonts
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#4E4B51",
    background: "#000",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "5px",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#4E4B51",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Air Black",
  },

  {
    ids: 2,
    color: "#fff", //description and other fonts
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#ccc",
    background: "#017374 ",
    socialIconColor: "",
    backgroundImage: "",
    family: "monospace",
    fontFamily: "monospace", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#ccc",
    transition: "",
    transform: "",
    linksBackgroundColor: "#017374",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Mineral Blue",
  },

  {
    ids: 3,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background: "#B0C4DE",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Bluish-Gray Smoke",
  },

  {
    ids: 4,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background: "#FF0000",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Miami",
  },

  {
    ids: 5,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background: "#34eb6b",
    socialIconColor: "",
    backgroundImage: "",
    family: "cursive",
    fontFamily: "cursive", //fontFamily for main heading
    linksTitleHoverColor: "",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Mint",
  },

  {
    ids: 6,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background: "#FF7F50",
    socialIconColor: "",
    backgroundImage: "",
    family: "cursive",
    fontFamily: "cursive", //fontFamily for main heading
    linksTitleHoverColor: "",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Coral",
  },

  {
    ids: 7,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background: "#FF007F ",
    socialIconColor: "",
    backgroundImage: "",
    family: "cursive",
    fontFamily: "cursive", //fontFamily for main heading
    linksTitleHoverColor: "",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#FF007F",
    boxShadow: "",
    name: "Bloom",
  },

  {
    ids: 8,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background:
      "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "#fd1d1d",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#fd1d1d",
    boxShadow: "",
    name: "Pastel Sunrise",
  },

  {
    ids: 9,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background:
      "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "rgb(63,94,251)",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "rgb(63,94,251)",
    boxShadow: "",
    name: "Color Burst",
  },
  {
    ids: 10,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background:
      "linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "rgb(238,174,202)",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "rgb(238,174,202)",
    boxShadow: "",
    name: "Pastel Dreams",
  },
  {
    ids: 11,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background:
      "radial-gradient(circle, rgba(255,105,180,1) 0%, rgba(255,182,193,1) 100%)",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "rgb(238,174,202)",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "rgb(238,174,202)",
    boxShadow: "",
    name: "Blush Pink",
  },
  {
    ids: 12,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background:
      "linear-gradient(135deg, rgba(255,102,102,1) 25%, rgba(255,204,102,1) 75%)",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "rgb(255,102,102)",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "rgb(255,102,102)",
    boxShadow: "",
    name: "Sunset Wave",
  },
  {
    ids: 13,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#fff",
    background:
      "linear-gradient(135deg, rgba(0,255,127,1) 25%, rgba(34,193,195,1) 75%)",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "rgb(0,255,127)",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#fff",
    transition: "",
    transform: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "rgb(0,255,127)",
    boxShadow: "",
    name: "Green Wave",
  },
];

export const artistTheme = [
  {
    ids: 0,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "#dbeb34",
    socialIconColor: "",
    backgroundImage:
      "https://images.unsplash.com/photo-1707343843344-011332037abb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwyMXx8fGVufDB8fHx8fA%3D%3D",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#FF7E00",
    borderRadius: "50px", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBorderColor: "#dbeb34",
    paginationBtnBorder: "",
    linksBackgroundColor: "#dbeb34",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Poppy",
  },
  {
    ids: 1,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage:
      "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&w=600",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "blue",
    borderRadius: "50px", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBorderColor: "",
    paginationBtnBorder: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Lris",
  },
  {
    ids: 2,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage:
      "https://res.cloudinary.com/dfrhy6m3m/image/upload/v1711692285/mahrcwpwexrz6nxxbfvs.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#f542ec",
    borderRadius: "5px", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBorderColor: "",
    paginationBtnBorder: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Sky",
  },
  {
    ids: 3,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/natural.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "blue",
    borderRadius: "", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(0.9)",
    linksBorderColor: "",
    paginationBtnBorder: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Natural",
  },
  {
    ids: 4,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage:
      "https://res.cloudinary.com/dfrhy6m3m/image/upload/v1711692325/ub8ooohhkqplyttibaun.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#b166e3",
    borderRadius: "", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(0.9)",
    linksBorderColor: "",
    paginationBtnBorder: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Cloud",
  },
  {
    ids: 5,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage:
      "https://res.cloudinary.com/dfrhy6m3m/image/upload/v1711692410/drv56jbtjxdotd66tyrh.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "blue",
    borderRadius: "", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(0.9)",
    linksBorderColor: "",
    paginationBtnBorder: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Green Tree",
  },
  {
    ids: 6,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage:
      "https://res.cloudinary.com/dfrhy6m3m/image/upload/v1711692458/kgmiugm2lwokwquhxv6d.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "Red",
    borderRadius: "", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(0.9)",
    linksBorderColor: "",
    paginationBtnBorder: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Leaves",
  },
  {
    ids: 7,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage:
      "https://res.cloudinary.com/dfrhy6m3m/image/upload/v1711692494/zrdcpzfsm5hbs72cxu4u.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#bf8478",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "",
    paginationBtnBorder: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Sunset",
  },
  {
    ids: 8,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "#B4D33B",
    socialIconColor: "",
    backgroundImage:
      "https://res.cloudinary.com/dfrhy6m3m/image/upload/v1711692375/qp2notb4vrvn8skskhhg.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#fff",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#B4D33B",
    paginationBtnBorder: "",
    linksBackgroundColor: "#B4D33B",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Flower",
  },
  {
    ids: 9,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    // backgroundImage: "./ThemeImages/buterfly.jpg",
    backgroundImage:
      "https://res.cloudinary.com/dfrhy6m3m/image/upload/v1711692146/fx3hv56jlo7qjxmwemgg.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "transform 0.3s",
    transform: "scale(0.9)",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Butterfly",
  },
];

export const animatedTheme = [
  {
    ids: 0,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage:
      "https://mfe-appearance.production.linktr.ee/images/selector-zigzag.bf3ead0f16e863f314ab.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Zig Zag",
  },
  {
    ids: 1,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage:
      "https://mfe-appearance.production.linktr.ee/images/selector-polka.ac5c9578cfbeace1faca.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "0 0 #0000, 0 0 #0000, 4px 4px 0 0 #fff",
    name: "Polka",
  },
  {
    ids: 2,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage:
      "https://mfe-appearance.production.linktr.ee/images/selector-stripe.f89087851c8191542ca9.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Stripe",
  },
  {
    ids: 3,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage:
      "https://mfe-appearance.production.linktr.ee/images/selector-waves.a2acfc10d083ad393d84.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Waves",
  },
  {
    ids: 4,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/gifThemeOne.gif",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Gif Image",
  },

  {
    ids: 5,
    color: "#000",
    fontColor: "#fff", //Main heading font color
    background: "#fff",
    socialIconColor: "",
    backgroundImage:
      "https://i.pinimg.com/originals/b6/ed/65/b6ed656900bfa2ab87a1053727f6a92a.gif",
    family: "cursive",
    fontFamily: "cursive", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "#fff",
    linksBackgroundColor: "#fff",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "AquaWater",
  },
];

export const minimalistTheme = [
  {
    ids: 0,
    color: "#fff", //description and other fonts
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#B4D33B",
    background: "#105910",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#B4D33B",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#000",
    arrowsColor: "#000",
    arrowsHoverColor: "#105910",
    boxShadow: "0 0 #0000, 0 0 #0000, 5px 5px 0 2px #B4D33B",
    name: "Fern Green",
  },

  {
    ids: 1,
    color: "#fff", //description and other fonts
    fontColor: "#fff", //Main heading font color
    linksBorderColor: "#FFB300",
    background: "#FF6F00",
    socialIconColor: "",
    backgroundImage: "",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "5px",
    descriptionColor: "",
    paginationBtnBorder: "#FFB300",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#000",
    arrowsColor: "#000",
    arrowsHoverColor: "#FF6F00",
    boxShadow: "0 0 #0000, 0 0 #0000, 6px 6px 0 3px #FFB300",
    name: "Yellow",
  },

  {
    ids: 2,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/blackSand.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Black Sand",
  },

  {
    ids: 3,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/grayBackground.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "0px", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Gray",
  },

  {
    ids: 4,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/wood.jpg",
    family: "cursive",
    fontFamily: "cursive", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "0px", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Wood Shape",
  },

  {
    ids: 5,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/socialive.jpg",
    family: "cursive",
    fontFamily: "cursive", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Socialive",
  },

  {
    ids: 6,
    color: "#fff",
    fontColor: "#fff", //Main heading font color
    background: "transparent",
    socialIconColor: "",
    backgroundImage:
      "https://i.pinimg.com/564x/ad/7d/fe/ad7dfe5cb4b6bc4eaaeb6bc3ae00d457.jpg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    descriptionColor: "#fff",
    linksTitleHoverColor: "#000",
    borderRadius: "", //links borderRedius
    transition: "",
    transform: "",
    linksBorderColor: "#fff",
    paginationBtnBorder: "",
    linksBackgroundColor: "",
    linksTitleColor: "",
    arrowsColor: "",
    arrowsHoverColor: "#000",
    boxShadow: "",
    name: "Pebble Pink",
  },

  {
    ids: 7,
    color: "#000", //description and other fonts
    fontColor: "#000", //Main heading font color
    linksBorderColor: "",
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/CloudNine.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#000",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#000",
    arrowsColor: "#000",
    arrowsHoverColor: "#FFB6C1",
    boxShadow: "",
    name: "Cloud Nine",
  },

  {
    ids: 8,
    color: "#000080", //description and other fonts
    fontColor: "#000080", //Main heading font color
    linksBorderColor: "#000080",
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/PastelStripes.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "#0000A0",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#000080",
    transition: "",
    transform: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#000080",
    arrowsColor: "#000080",
    arrowsHoverColor: "#0000A0",
    boxShadow: "",
    name: "Pastel Stripes",
  },
  {
    ids: 9,
    color: "#f294a2", //description and other fonts
    fontColor: "#f294a2", //Main heading font color
    linksBorderColor: "#f294a2",
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/Pastel.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#f294a2",
    transition: "",
    transform: "",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#f294a2",
    arrowsColor: "#f294a2",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Pastel Floral Dreams",
  },
  {
    ids: 10,
    color: "#8B4513", //description and other fonts
    fontColor: "#8B4513", //Main heading font color
    linksBorderColor: "#8B4513",
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/EarthyMinimalism.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#8B4513",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#8B4513",
    arrowsColor: "#8B4513",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Earthy Minimalism",
  },
  {
    ids: 11,
    color: "#87CEEB", //description and other fonts
    fontColor: "#87CEEB", //Main heading font color
    linksBorderColor: "#87CEEB",
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/SereneWatercolor.svg",
    family: "",
    fontFamily: "", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#87CEEB",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#87CEEB",
    arrowsColor: "#87CEEB",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Serene Watercolor",
  },
  {
    ids: 12,
    color: "#FFB6C1", //description and other fonts
    fontColor: "#FFB6C1", //Main heading font color
    linksBorderColor: "#FFB6C1",
    background: "transparent",
    socialIconColor: "",
    backgroundImage: "./ThemeImages/MinimalistBlush.svg",
    family: "cursive",
    fontFamily: "cursive", //fontFamily for main heading
    linksTitleHoverColor: "none",
    borderRadius: "",
    descriptionColor: "",
    paginationBtnBorder: "#FFB6C1",
    transition: "transform 0.3s",
    transform: "scale(1.1)",
    linksBackgroundColor: "#fff",
    linksTitleColor: "#FFB6C1",
    arrowsColor: "#FFB6C1",
    arrowsHoverColor: "",
    boxShadow: "",
    name: "Minimalist Blush",
  },
];

// Home Page
export const faqs = [
  {
    id: 1,
    title: "How do I create my page?",
    description:
      "It's easy! Just sign up for an account, choose a template or customize your own, add your links, and you're ready to share!",
  },

  {
    id: 2,
    title: "Is CBXTREE free to use?",
    description:
      "We offer a free plan with basic features and a premium plan with additional customization options and analytics.",
  },
  {
    id: 3,
    title: "How do I add/edit/delete links?",
    description:
      "You can manage your links easily through your account dashboard.",
  },
  {
    id: 4,
    title: "Can I customize the look of my page?",
    description:
      "Yes! Choose from our templates or customize your page with your own colors, images, and fonts.",
  },
  {
    id: 5,
    title: "Can I track how many people click on my links? ",
    description:
      "Yes, our premium plan provides analytics so you can track clicks and see which links are most popular.",
  },
  {
    id: 6,
    title: "How do I share my page?",
    description:
      "You'll get a unique link for your page that you can share anywhere: social media, email signatures, websites, etc.",
  },

  {
    id: 7,
    title: "Can I use my own domain name?",
    description:
      "Yes, our premium plan allows you to connect your own custom domain name for a more professional look.",
  },

  {
    id: 8,
    title: "How can I make my page more visible?",
    description:
      "Promote your page link on social media, add it to your email signature, and embed it on your website or blog.",
  },
  {
    id: 9,
    title: "Is my data secure?",
    description:
      "Yes, we take data security seriously and have measures in place to protect your information.",
  },
];

export const customerReviews = [
  {
    id: 1,
    image:
      "https://img.freepik.com/premium-photo/indian-businessman-formal-suit_1375-41032.jpg?size=626&ext=jpg&ga=GA1.1.1049173403.1697283924&semt=ais_user",
    name: "Dhruv J.",
    address: "Noida,UP",
    review:
      "Cbxtree has streamlined how I share my links. The customization options are fantastic, and the analytics have given me valuable insights.",
    star: 5,
  },
  {
    id: 2,
    image:
      "https://t3.ftcdn.net/jpg/06/39/87/52/240_F_639875265_J9qzTzZbTtpX3fDaZcIKD8pBwidQqv4W.jpg",
    name: "Aditi Pawar",
    address: "Mumbai,Maharashtra",
    review:
      "Cbxtree makes sharing my links easy. The customization is great, and the analytics give me useful insights.",
    star: 5,
  },

  {
    id: 3,
    image:
      "https://t3.ftcdn.net/jpg/07/02/09/04/240_F_702090485_la8XR7DJPNopgPVAWVeBP5C9dxAX5L1p.jpg",
    name: "Suresh J.",
    address: "Delhi,India",
    review:
      "The ability to create multiple link trees and share them anywhere has been a game-changer for my business. Excellent tool!",
    star: 4,
  },
  {
    id: 4,
    image:
      "https://t4.ftcdn.net/jpg/05/70/57/47/240_F_570574724_HWfki1q3XZt9WzVlCcQujOV5Jxe8UBG1.jpg",
    name: "Simran Gupta",
    address: "Jodhpur,Rajasthan",
    review:
      "Cbxtree makes link management effortless. Customizing is easy, and the analytics are incredibly useful.",
    star: 3,
  },
  {
    id: 5,
    image:
      "https://t4.ftcdn.net/jpg/06/79/50/91/240_F_679509187_D54puEPWVv1jkUfkPgOCGpn7DdD99v2O.jpg",
    name: "Sunidha R.",
    address: "Pune,Maharashtra",
    review:
      "Cbxtree has transformed how I manage my links. Easy customization and powerful analytics all in one place.",
    star: 5,
  },

  {
    id: 6,
    image:
      "https://media.istockphoto.com/id/1369199737/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=612x612&w=0&k=20&c=ctxvuvJJZS2euXBGQazRd1W81dXZCJXgW11i2jZnNMI=",
    name: "Aarav Mathur",
    address: "Kolkata,India",
    review:
      "I love the simplicity of setting up and managing my Cbxtree page. The real-time previews on different devices are a huge plus!",
    star: 4,
  },
];
