import { React, useState, useEffect, useContext, useRef } from "react";
import AddSocialLink from "./AddSocialLink";
import linkContext from "../context/links/linkContext";
import Preview from "./Preview";
import CustomModal from "./Custom/CustomModal";
import ColorPicker from "./ColorPicker/ColorPicker";
import { Tooltip } from "antd";
import MobilePreview from "./Themes/MobilePreview";
import { Button, IconButton } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { FaSave } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import PasswordModal from "./UserLinksDashboard/Modals/PasswordModal";
import { message } from "antd";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { FaPlus } from "react-icons/fa6";
import QRCodeModal from "./QRCodeModal/QRCodeModal";
import { IoQrCodeOutline } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa6";

const Settings = (props) => {
  let host = "http://localhost:3000/api";
  if (document.location.href.includes("localhost")) {
    host = "http://localhost:3000/api";
  } else {
    host = "api";
  }
  let userInfo;
  const userInfoString = Cookies.get("userInfo");
  userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const shareUserName = Cookies.get("cbxtree");

  const [messageApi, contextHolder] = message.useMessage();
  const context = useContext(linkContext);
  let {
    settings,
    getLinks,
    links,
    getSettings,
    updateLogo,
    updateBgImage,
    updateLgBackground,
    addHeader,
    addDescription,
    updaterightfooter,
    updateleftfooter,
    handleFooter,
    handleSearch,
    user,
    previewUrl,
    fetchclicks,
    updateHeaderSetting,
    updateDescriptionSetting,
    updatedleftfooterStyle,
    updatedrightfooterStyle,
    updatedpaginationStyle,
    updatedLinksStyle,
    handleAddDomain,
    handleAddUseId,
    userDetails,
    addCompanyName,
    handleDeleteLogoUrl,
    handleDeleteBackgroundImageUrl,
    handleDeleteBackground,
    handleDeleteFaviconIconAndTitle,
    handleDeleteHeaderValue,
    handleDeleteDomainUrl,
    handleUpdateQRCodeStatus,
  } = context;

  const [feviconIcon, setFeviconIcon] = useState("");
  const [title, setTitle] = useState("");
  const [linkData, updateLinkData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [mainSettings, setMainSettings] = useState([]);
  const [page, setPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const refCloseFavModal = useRef(null);

  const extractUserId = (url) => {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  };

  const userName = extractUserId(previewUrl);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const handleOpen = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  useEffect(() => {
    getSettings();
    getLinks();
    fetchclicks();
    userDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (settings) {
      const data = settings?.filter((item) => item.userid === userName);
      const setting = data[0];
      setMainSettings(setting);
      setPage(setting?.showLinksPerPage?.linksLimit);
      setFeviconIcon(setting?.pageTitleAndFeviconIconSetting?.feviconIcon);
      setTitle(setting?.pageTitleAndFeviconIconSetting?.title);
    }
  }, [settings, userName]);

  useEffect(() => {
    if (links) {
      const newData = links.filter((item) => item.userid === userName);
      setFilteredData(newData);
    }
  }, [links, userName]);

  const handleAddPageLimit = async (e) => {
    const id = userInfo && userInfo._id;
    e.preventDefault();
    const apiUrl = `${host}/settings/updateLinksShowLimit`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ userName, page, id }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) throw new Error("Network response was not ok.");

      const data = await response.json();
      success("Update successfully");
      getSettings();
      getLinks();
      setTimeout(() => {
        handleCloseLinks();
      }, 1000);
    } catch (error) {
      console.log(error, "Something went wrong");
      // error("Something went wrong");
    }
  };

  useEffect(() => {
    if (isCheck && mainSettings && !mainSettings?.isQRCodeGenrate) {
      handleUpdateQRCodeStatus(isCheck, userName).then((updatedSettings) => {
        setMainSettings(updatedSettings);
      });
    }
  }, [isCheck, mainSettings, userName]);

  const handleCloseQRModal = () => setOpenQRModal(false);
  const handleOpenQRCode = () => {
    setOpenQRModal(true);
    setIsCheck(true);
  };

  // Delete apis
  const handleDeleteLogo = (id) => {
    handleDeleteLogoUrl(id);
    setlogo("");
  };

  const handleDeleteBackgroundImage = (id) => {
    handleDeleteBackgroundImageUrl(id);
    setImage(null);
  };

  const handleDeleteBackgroundColor = (id) => {
    handleDeleteBackground(id);
    setSelectedBackground("");
  };

  const handleDeleteFaviconIcon = (id) => {
    handleDeleteFaviconIconAndTitle(id);
    setTitle("");
    setFeviconIcon("");
  };

  const handleDeleteHeader = (id, settingName) => {
    handleDeleteHeaderValue(id, settingName);

    switch (settingName) {
      case "header":
        setHeader("");
        success("Header deleted successfully.");
        break;
      case "description":
        setDescription("");
        success("Description deleted successfully.");
        break;
      case "companyName":
        setCompanyName("");
        success("Company name deleted successfully.");
        break;
      case "leftFooter":
        setLeftFooter({ leftFooterName: "", leftFooterURL: "" });
        success("Left footer deleted successfully.");
        break;
      case "rightFooter":
        setRightFooter({ rightFooterName: "", rightFooterURL: "" });
        success("Right footer deleted successfully.");
        break;
      default:
        error("Unknown setting name.");
        break;
    }
  };

  const [selectedBackground, setSelectedBackground] = useState(
    (mainSettings && mainSettings?.background) || ""
  );

  useEffect(() => {
    setSelectedBackground(
      lgvBackground(mainSettings && mainSettings?.background) || ""
    );
  }, [mainSettings]);

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  let updatedFeviconIcon = feviconIcon;
  const handleUpdateFeviconIconAndTitleName = async (e) => {
    const id = userInfo && userInfo._id;
    e.preventDefault();

    if (feviconIcon && !isValidUrl(feviconIcon)) {
      error("Please enter a valid icon URL.");
      return;
    }

    if (feviconIcon?.indexOf("https://drive.google.com") > -1) {
      updatedFeviconIcon = DriveUrl(feviconIcon);
    }
    try {
      const response = await fetch(
        `${host}/settings/updateFaviconIconAndTitle`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({
            // feviconIcon,
            feviconIcon: updatedFeviconIcon,
            title,
            userName: userName,
            id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update the settings");
      }
      const updatedSettings = await response.json();
      getSettings();
      refCloseFavModal.current.click();
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const filterviewlink = filteredData?.filter((link) => {
      if (link.endDate) {
        const [day, month, year, hour, minute] = link.endDate.split(/[\/\s:]/);
        const endDate = new Date(year, month - 1, day, hour, minute);
        return endDate >= currentDate;
      } else {
        return true;
      }
    });
    const sortedLinks = filterviewlink
      .slice()
      .sort((a, b) => a.order - b.order);
    updateLinkData(sortedLinks);
  }, [filteredData]);

  useEffect(() => {
    const fetchData = async () => {
      getSettings();
      getLinks();
      fetchclicks();
      userDetails();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // Header satting
  const handleEditClick = () => {
    setEditing(true);
  };

  const handleClose = () => {
    setEditing(false);
  };

  const [isEditing, setEditing] = useState(false);
  const [editedFontSize, setEditedFontSize] = useState(
    mainSettings?.headersSettings?.fontSize || ""
  );
  const [editedFontColor, setEditedFontColor] = useState(
    mainSettings?.headersSettings?.fontColor || ""
  );
  const [editedFontFamily, setEditedFontFamily] = useState(
    mainSettings?.headersSettings?.fontFamily || ""
  );

  const handleSaveClick = () => {
    updateHeaderSetting(editedFontSize, editedFontColor, editedFontFamily);
    setEditedFontColor("");
    setEditedFontSize("");
    setEditedFontFamily("");
    setEditing(false);
  };

  // Description setting
  const [isEditingDescription, setEditingDescription] = useState(false);
  const handleEditdescription = () => {
    setEditingDescription(true);
  };

  const handleClosedescription = () => {
    setEditingDescription(false);
  };

  const [editDescColor, setEditDescColor] = useState(
    mainSettings?.descriptionSettings?.color || ""
  );
  const [editDescSize, setEditDescSize] = useState(
    mainSettings?.descriptionSettings?.size || ""
  );
  const [editDescFontFamily, setEditDescFontFamily] = useState(
    mainSettings?.descriptionSettings?.family || ""
  );

  const handleSaveDescription = () => {
    updateDescriptionSetting(editDescColor, editDescSize, editDescFontFamily);
    setEditDescColor("");
    setEditDescSize("");
    setEditDescFontFamily("");
    setEditingDescription(false);
  };

  // Left Footer Setting
  const [isEditingLeftFooter, setEditingLeftFooter] = useState(false);

  const handleEditLeftFooter = () => {
    setEditingLeftFooter(true);
  };

  const handleCloseLeftFooter = () => {
    setEditingLeftFooter(false);
  };

  const [editLeftFooterColor, setEditLeftFooterColor] = useState(
    mainSettings?.leftFooterSetting?.color || ""
  );
  const [editLeftFooterSize, setEditLeftFooterSize] = useState(
    mainSettings?.leftFooterSetting?.size || ""
  );
  const [editLeftFooterFamily, setEditLeftFooterFamily] = useState(
    mainSettings?.leftFooterSetting?.family || ""
  );
  const [editLeftFooterBackground, setEditLeftFooterBackground] = useState(
    mainSettings?.leftFooterSetting?.background || ""
  );

  const handleSaveLeftFooter = () => {
    updatedleftfooterStyle(
      editLeftFooterColor,
      editLeftFooterSize,
      editLeftFooterFamily,
      editLeftFooterBackground
    );
    setEditLeftFooterColor("");
    setEditLeftFooterSize("");
    setEditLeftFooterFamily("");
    setEditLeftFooterBackground("");
    setEditingLeftFooter(false);
  };

  // Right Footer Satting
  const [isEditingRightFooter, setEditingRightFooter] = useState(false);

  const handleEditRightFooter = () => {
    setEditingRightFooter(true);
  };

  const handleCloseRightFooter = () => {
    setEditingRightFooter(false);
  };

  const [editRightFooterColor, setEditRightFooterColor] = useState(
    mainSettings?.rightFooterSetting?.color || ""
  );
  const [editRightFooterSize, setEditRightFooterSize] = useState(
    mainSettings?.rightFooterSetting?.size || ""
  );
  const [editRightFooterFamily, setEditRightFooterFamily] = useState(
    mainSettings?.rightFooterSetting?.family || ""
  );
  const [editRightFooterBackground, setEditRightFooterBackground] = useState(
    mainSettings?.rightFooterSetting?.background || ""
  );

  const handleSaveRightFooter = () => {
    updatedrightfooterStyle(
      editRightFooterColor,
      editRightFooterSize,
      editRightFooterFamily,
      editRightFooterBackground
    );
    setEditRightFooterColor("");
    setEditRightFooterSize("");
    setEditRightFooterFamily("");
    setEditRightFooterBackground("");
    setEditingRightFooter(false);
  };

  // Pagination Satting
  const [isEditingPagination, setEditingPagination] = useState(false);

  const handleEditPagination = () => {
    setEditingPagination(true);
  };

  const handleClosePagination = () => {
    setEditingPagination(false);
  };

  const [editPaginationButtonColor, setEditPaginationButtonColor] = useState(
    mainSettings?.paginationSetting?.color || ""
  );
  const [editPaginationButtonSize, setEditPaginationButtonSize] = useState(
    mainSettings?.paginationSetting?.size || ""
  );
  const [editPaginationButtonFamily, setEditPaginationButtonFamily] = useState(
    mainSettings?.paginationSetting?.family || ""
  );
  const [editPaginationButtonBackground, setEditPaginationButtonBackground] =
    useState(mainSettings?.paginationSetting?.background || "");

  const handleSavePagination = () => {
    updatedpaginationStyle(
      editPaginationButtonColor,
      editPaginationButtonSize,
      editPaginationButtonFamily,
      editPaginationButtonBackground
    );
    setEditPaginationButtonColor("");
    setEditPaginationButtonSize("");
    setEditPaginationButtonFamily("");
    setEditPaginationButtonBackground("");
    setEditingPagination(false);
  };

  // Links Settings
  const [isEditingLinks, setEditingLinks] = useState(false);

  const handleEditLinks = () => {
    setEditingLinks(true);
  };

  const handleCloseLinks = () => {
    setEditingLinks(false);
  };

  const [editLinksColor, setEditLinksColor] = useState(
    mainSettings?.linksSettings?.color || ""
  );
  const [editLinksSize, setEditLinksSize] = useState(
    mainSettings?.linksSettings?.size || ""
  );
  const [editLinksFamily, setEditLinksFamily] = useState(
    mainSettings?.linksSettings?.family || ""
  );
  const [editLinksBackground, setEditLinksBackground] = useState(
    mainSettings?.linksSettings?.background || ""
  );
  const [editLinksBorderRadius, setEditLinksBorderRadius] = useState(
    mainSettings?.linksSettings?.borderRadius || ""
  );

  const handleSaveLinks = () => {
    updatedLinksStyle(
      editLinksColor,
      editLinksSize,
      editLinksFamily,
      editLinksBackground,
      editLinksBorderRadius
    );
    setEditLinksColor("");
    setEditLinksSize("");
    setEditLinksFamily("");
    setEditLinksBackground("");
    setEditLinksBorderRadius("");
    setEditingLinks(false);
  };

  const bgList = [
    ["Roseanna", "#ffafbd → #ffc3a0"],
    ["Purple Love", "#cc2b5e → #753a88"],
    ["Piglet", "#ee9ca7 → #ffdde1"],
    ["Mauve", "#42275a → #734b6d"],
    ["50 Shades of Grey", "#bdc3c7 → #2c3e50"],
    ["A Lost Memory", "#de6262 → #ffb88c"],
    ["Socialive", "#06beb6 → #48b1bf"],
    ["Cherry", "#eb3349 → #f45c43"],
    ["Pinky", "#dd5e89 → #f7bb97"],
    ["Lush", "#56ab2f → #a8e063"],
    ["Kashmir", "#614385 → #516395"],
    ["Tranquil", "#eecda3 → #ef629f"],
    ["Pale Wood", "#eacda3 → #d6ae7b"],
    ["Green Beach", "#02aab0 → #00cdac"],
    ["Sha La La", "#d66d75 → #e29587"],
    ["Virgin America", "#7b4397 → #dc2430"],
    ["Bloody Mary", "#ff512f → #dd2476"],
  ];

  const handleBackground = (value) => {
    // let bg = e.target.value;
    let bg = value;
    updateLgBackground(bg);
  };

  const lgBackground = (bg) => {
    bg = bg.split(" → ");
    return `linear-gradient(to right, ${bg[0]} , ${bg[1]})`;
  };

  const lgvBackground = (value) => {
    const temp = bgList?.filter((row) => {
      return row[1] === value;
    });
    if (temp && temp.length > 0) {
      return temp[0][0];
    }
    return null;
  };

  // Logo mainSettings
  const DriveUrl = (logoUrl) => {
    let link = logoUrl;
    if (logoUrl?.indexOf("https://drive.google.com") > -1) {
      var id = logoUrl.match(/[-\w]{25,}/)[0];
      link = "https://lh3.google.com/u/0/d/" + id;
    }
    return link;
    // console.log("link", link);
  };

  const refCloseLogo = useRef(null);
  const [logo, setlogo] = useState(mainSettings?.logo);
  const onChangeLogo = (e) => {
    setlogo(e.target.value);
  };

  const handleUpdateLogo = () => {
    updateLogo(logo);
    refCloseLogo.current.click();
  };

  // Background Image Settings
  const imageUrl = DriveUrl(mainSettings?.backgroundImage);
  const [image, setImage] = useState(mainSettings?.backgroundImage);

  const refCloseImage = useRef(null);
  const onChangeImage = (e) => {
    const value = e.target.value;
    setImage(value);
  };

  const handleUpdateImage = () => {
    updateBgImage(image);
    refCloseImage.current.click();
  };

  //  1. Header setting : -
  const [header, setHeader] = useState(mainSettings?.header || "");
  const [isEdit, setIsEdit] = useState(false);
  const originalHeader = mainSettings?.header || "";

  useEffect(() => {
    if (mainSettings?.header) {
      setIsEdit(true);
    }
  }, [mainSettings]);

  const onchangeHeader = (e) => {
    const newValue = e.target.value;
    setHeader(newValue);
    if (newValue !== originalHeader) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  const handleheader = () => {
    addHeader(header);
    setIsEdit(true);
  };

  // 2. Description setting : -
  const [description, setDescription] = useState(
    mainSettings?.description || ""
  );
  const [isEditDesc, setIsEditDesc] = useState(false);
  const originalDesc = mainSettings?.description || "";

  // const onchangeDescription = (e) => {
  //   setDescription(e.target.value);
  // };
  // const handledescription = () => {
  //   addDescription(description);
  // };
  useEffect(() => {
    if (mainSettings?.description) {
      setIsEditDesc(true);
    }
  }, [mainSettings]);

  const onchangeDescription = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
    if (newValue !== originalDesc) {
      setIsEditDesc(false);
    } else {
      setIsEditDesc(true);
    }
  };

  const handledescription = () => {
    addDescription(description);
    setIsEditDesc(true);
  };

  // 3. Company Name setting : -
  const [companyName, setCompanyName] = useState(
    mainSettings?.companyName || ""
  );
  const [isEditCName, setIsEditCName] = useState(false);
  const originalCName = mainSettings?.companyName || "";

  useEffect(() => {
    if (mainSettings?.companyName) {
      setIsEditCName(true);
    }
  }, [mainSettings]);

  // const onchangeCompanyName = (e) => {
  //   setCompanyName(e.target.value);
  // };
  // const handleCompanyName = () => {
  //   addCompanyName(companyName);
  // };

  const onchangeCompanyName = (e) => {
    const newValue = e.target.value;
    setCompanyName(newValue);
    if (newValue !== originalCName) {
      setIsEditCName(false);
    } else {
      setIsEditCName(true);
    }
  };

  const handleCompanyName = () => {
    addCompanyName(companyName);
    setIsEditCName(true);
  };

  // Left footer
  const [leftFooter, setLeftFooter] = useState({
    leftFooterName: Array.isArray(mainSettings?.leftFooter)
      ? mainSettings.leftFooter[0]?.leftFooterName || ""
      : "",
    leftFooterURL: Array.isArray(mainSettings?.leftFooter)
      ? mainSettings.leftFooter[0]?.leftFooterURL || ""
      : "",
  });

  const [isEditLeftFooter, setIsEditLeftFooter] = useState(false);

  const originalLeftFooter = {
    leftFooterName: Array.isArray(mainSettings?.leftFooter)
      ? mainSettings.leftFooter[0]?.leftFooterName || ""
      : "",
    leftFooterURL: Array.isArray(mainSettings?.leftFooter)
      ? mainSettings.leftFooter[0]?.leftFooterURL || ""
      : "",
  };

  useEffect(() => {
    if (
      mainSettings?.leftFooter?.length > 0 &&
      mainSettings.leftFooter[0]?.leftFooterName
    ) {
      setIsEditLeftFooter(true);
    }
  }, [mainSettings]);

  const onChangeleft = (e) => {
    const { name, value } = e.target;
    setLeftFooter((prevState) => ({ ...prevState, [name]: value }));
    if (
      name === "leftFooterName" &&
      value !== originalLeftFooter.leftFooterName
    ) {
      setIsEditLeftFooter(false);
    } else if (
      name === "leftFooterURL" &&
      value !== originalLeftFooter.leftFooterURL
    ) {
      setIsEditLeftFooter(false);
    } else {
      setIsEditLeftFooter(true);
    }
  };

  const handleAddleft = () => {
    updateleftfooter(leftFooter);
    setIsEditLeftFooter(true);
  };

  // right footer
  const [rightFooter, setRightFooter] = useState({
    rightFooterName: Array.isArray(mainSettings?.rightFooter)
      ? mainSettings.rightFooter[0]?.rightFooterName || ""
      : "",
    rightFooterURL: Array.isArray(mainSettings?.rightFooter)
      ? mainSettings.rightFooter[0]?.rightFooterURL || ""
      : "",
  });

  const [isEditRightFooter, setIsEditRightFooter] = useState(false);

  const originalRightFooter = {
    rightFooterName: Array.isArray(mainSettings?.rightFooter)
      ? mainSettings.rightFooter[0]?.rightFooterName || ""
      : "",
    rightFooterURL: Array.isArray(mainSettings?.rightFooter)
      ? mainSettings.rightFooter[0]?.rightFooterURL || ""
      : "",
  };

  useEffect(() => {
    if (
      mainSettings?.rightFooter?.length > 0 &&
      mainSettings.rightFooter[0]?.rightFooterName
    ) {
      setIsEditRightFooter(true);
    }
  }, [mainSettings]);

  const onChangeright = (e) => {
    const { name, value } = e.target;
    setRightFooter((prevState) => ({ ...prevState, [name]: value }));
    if (
      name === "rightFooterName" &&
      value !== originalRightFooter.rightFooterName
    ) {
      setIsEditRightFooter(false);
    } else if (
      name === "rightFooterURL" &&
      value !== originalRightFooter.rightFooterURL
    ) {
      setIsEditRightFooter(false);
    } else {
      setIsEditRightFooter(true);
    }
  };

  const handleAddright = () => {
    updaterightfooter(rightFooter);
    setIsEditRightFooter(true);
  };

  const [domain, setDomain] = useState("");
  useEffect(() => {
    setDomain(user?.domain);
  }, [user]);

  const onChangedomain = (e) => {
    setDomain(e.target.value);
  };

  const [userId, setUserid] = useState("");
  const [userDomain, setUserDomian] = useState("");

  useEffect(() => {
    if (previewUrl) {
      let location = previewUrl.split("/");
      const userid = location[3];
      setUserid(userid);
      location.pop();
      let userdomain = location.join("/").concat("/");
      setUserDomian(userdomain);
    }
  }, [previewUrl]);

  const onchangeUserId = (e) => {
    setUserid(e.target.value);
  };

  const handleDeleteDomain = (id) => {
    handleDeleteDomainUrl(id);
    setDomain("");
  };

  useEffect(() => {
    setlogo(mainSettings?.logo);
    setImage(mainSettings?.backgroundImage);
    setHeader(mainSettings?.header);
    setDescription(mainSettings?.description);
    setCompanyName(mainSettings?.companyName);

    // Header
    setEditedFontColor(
      mainSettings?.headersSettings?.fontColor
        ? mainSettings?.headersSettings?.fontColor
        : ""
    );
    setEditedFontSize(
      mainSettings?.headersSettings?.fontSize
        ? mainSettings?.headersSettings?.fontSize
        : ""
    );
    setEditedFontFamily(
      mainSettings?.headersSettings?.fontFamily
        ? mainSettings?.headersSettings?.fontFamily
        : ""
    );

    // Description
    setEditDescColor(
      mainSettings?.descriptionSettings?.color
        ? mainSettings?.descriptionSettings?.color
        : ""
    );

    setEditDescFontFamily(
      mainSettings?.descriptionSettings?.family
        ? mainSettings?.descriptionSettings?.family
        : ""
    );

    setEditDescSize(
      mainSettings?.descriptionSettings?.size
        ? mainSettings?.descriptionSettings?.size
        : ""
    );

    // Pagination
    setEditPaginationButtonColor(
      mainSettings?.paginationSetting?.color
        ? mainSettings?.paginationSetting?.color
        : ""
    );
    setEditPaginationButtonSize(
      mainSettings?.paginationSetting?.size
        ? mainSettings?.paginationSetting?.size
        : ""
    );
    setEditPaginationButtonBackground(
      mainSettings?.paginationSetting?.background
        ? mainSettings?.paginationSetting?.background
        : ""
    );

    setEditPaginationButtonFamily(
      mainSettings?.paginationSetting?.family
        ? mainSettings?.paginationSetting?.family
        : ""
    );

    // Link
    setEditLinksColor(
      mainSettings?.linksSettings?.color
        ? mainSettings?.linksSettings?.color
        : ""
    );
    setEditLinksSize(
      mainSettings?.linksSettings?.size ? mainSettings?.linksSettings?.size : ""
    );
    setEditLinksFamily(
      mainSettings?.linksSettings?.family
        ? mainSettings?.linksSettings?.family
        : ""
    );
    setEditLinksBackground(
      mainSettings?.linksSettings?.background
        ? mainSettings?.linksSettings?.background
        : ""
    );
    setEditLinksBorderRadius(
      mainSettings?.linksSettings?.borderRadius
        ? mainSettings?.linksSettings?.borderRadius
        : ""
    );

    // Left Footer
    setEditLeftFooterSize(
      mainSettings?.leftFooterSetting?.size
        ? mainSettings?.leftFooterSetting?.size
        : ""
    );
    setEditLeftFooterColor(
      mainSettings?.leftFooterSetting?.color
        ? mainSettings?.leftFooterSetting?.color
        : ""
    );
    setEditLeftFooterFamily(
      mainSettings?.leftFooterSetting?.family
        ? mainSettings?.leftFooterSetting?.family
        : ""
    );
    setEditLeftFooterBackground(
      mainSettings?.leftFooterSetting?.background
        ? mainSettings?.leftFooterSetting?.background
        : ""
    );
    // Right Footer
    setEditRightFooterSize(
      mainSettings?.rightFooterSetting?.size
        ? mainSettings?.rightFooterSetting?.size
        : ""
    );
    setEditRightFooterColor(
      mainSettings?.rightFooterSetting?.color
        ? mainSettings?.rightFooterSetting?.color
        : ""
    );
    setEditRightFooterFamily(
      mainSettings?.rightFooterSetting?.family
        ? mainSettings?.rightFooterSetting?.family
        : ""
    );
    setEditRightFooterBackground(
      mainSettings?.rightFooterSetting?.background
        ? mainSettings?.rightFooterSetting?.background
        : ""
    );

    setLeftFooter(
      mainSettings?.leftFooter?.length > 0 ? mainSettings?.leftFooter[0] : ""
    );

    setRightFooter(
      mainSettings?.rightFooter?.length > 0 ? mainSettings?.rightFooter[0] : ""
    );
  }, [mainSettings]);

  const [viewUrl, setViewUrl] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    // Filter links based on endDate
    const filterviewlink = filteredData?.filter((link) => {
      if (link.endDate) {
        const [day, month, year, hour, minute] = link.endDate.split(/[\/\s:]/);
        const endDate = new Date(year, month - 1, day, hour, minute);
        return endDate >= currentDate;
      } else {
        return true;
      }
    });
    const sortedLinks = filterviewlink
      .slice()
      .sort((a, b) => a.order - b.order);
    updateLinkData(sortedLinks);
  }, [filteredData]);

  const isLeftFooterNameEmpty = !leftFooter?.leftFooterName;
  const isLeftFooterURLEmpty = !leftFooter?.leftFooterURL;

  const isRightFooterNameEmpty = !rightFooter?.rightFooterName;
  const isRightFooterURLEmpty = !rightFooter?.rightFooterURL;

  const handleClick = () => {
    if (!leftFooter?.leftFooterURL || !leftFooter?.leftFooterName) {
      error("Please fill out all fields");
      return;
    }

    if (isEditLeftFooter) {
      handleDeleteHeader(mainSettings?.userid, "leftFooter");
      setIsEditLeftFooter(false);
    } else {
      handleAddleft();
    }
  };

  const handleClickRightFooter = () => {
    if (!rightFooter?.rightFooterURL || !rightFooter?.rightFooterName) {
      error("Please fill out all fields");
      return;
    }

    if (isEditRightFooter) {
      handleDeleteHeader(mainSettings?.userid, "rightFooter");
      setIsEditRightFooter(false);
    } else {
      handleAddright();
    }
  };

  const handleClickDomain = () => {
    if (user && user?.domain) {
      handleDeleteDomain(user?._id);
      success("Domain deleted successfully.");
    } else {
      handleAddDomain(domain);
      success("Domain added successfully.");
    }
  };

  return (
    <>
      {contextHolder}
      <div
        style={{
          background: "#F8F8F8",
          paddingBottom: "20px",
          border: "1px solid transparent",
        }}
      >
        {/* Navbar  */}
        {/* <div style={{ marginBottom: "80px" }}>
          <Navbar />
        </div> */}

        <div
          className="mainContainer"
          style={{
            marginRight: "0px",
            background: "#F8F8F8",
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <div
            className=" linksSettingComponentDiv"
            style={{
              width: "60%",
              position: "relative",
              borderRight: "1px solid #ccc",
              paddingRight: "20px",
              marginTop: "20px",
            }}
          >
            <div
              className="settingMainContainer shadow-sm bg-white"
              style={{
                width: "100%",
                margin: "auto",
                paddingBottom: "20px",
                background: "#fff",
                padding: "10px 20px 5px 20px",
                borderRadius: "20px",
              }}
            >
              <div
                className="d-flex justify-content-center my-3"
                style={{
                  backgroundColor: "#4F8B33",
                  color: "#fff",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
              >
                <span className="my-2" style={{ fontSize: "17px" }}>
                  {" "}
                  Settings
                </span>
              </div>

              <div className="d-flex justify-content-center my-3 ">
                <div className="input-group addressDiv">
                  {/* <!-- Button trigger modal --> */}
                  {/* <div className="previewIconDiv"> */}
                  <Tooltip title={"Click to Get Preview"}>
                    <button
                      className="btn btn-outline-primary shadow-none eyeBtn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#previewModel"
                    >
                      <i className="fa-solid fa-eye"></i>
                    </button>
                  </Tooltip>
                  {/* </div> */}

                  <div className="form-control d-flex align-items-center linkAddressInput">
                    <span>{userDomain}</span>
                    <input
                      type="text"
                      aria-label="Add Header"
                      aria-describedby="button-addon2"
                      value={userId}
                      onChange={onchangeUserId}
                      style={{
                        borderRadius: "4px",
                        background: viewUrl ? "#F8F8F8" : "#fff",
                        border: viewUrl ? "1px solid #198754" : "none",
                        outline: "none",
                        width: "100%",
                        paddingInline: viewUrl ? "5px" : "0px",
                      }}
                      disabled={!viewUrl ? "disabled" : ""}
                    />
                  </div>

                  {/* Add password button*/}
                  <Tooltip title="Secure your Cbxtree : Click to set or update your password for added protection.">
                    <IconButton
                      onClick={handleOpen}
                      sx={{
                        fontWeight: 500,
                        border: "1px solid #4F8B33",
                        color: "#4F8B33",
                        borderColor: "#4F8B33",
                        "&:hover": { background: "#4F8B33", color: "#fff" },
                      }}
                      className="editAddressBtn"
                    >
                      <RiLockPasswordFill size={18} />
                    </IconButton>
                  </Tooltip>

                  {!viewUrl ? (
                    <Tooltip
                      title={
                        shareUserName
                          ? "You do not have permission to edit alias"
                          : "Edit your alias."
                      }
                    >
                      <IconButton
                        className="editAddressBtn"
                        sx={{
                          position: "relative",
                          zIndex: 100,
                          fontWeight: 500,
                          cursor: shareUserName ? "default" : "pointer",
                          color: shareUserName ? "#a9a9a9" : "#4F8B33",
                          background: shareUserName ? "#e8e6e6" : "",
                          borderColor: "#4F8B33",
                          border: "1px solid #4F8B33",
                          "&:hover": {
                            background: shareUserName ? "#e8e6e6" : "#4F8B33",
                            color: shareUserName ? "#a9a9a9" : "#fff",
                          },
                        }}
                        variant="outlined"
                        onClick={() => {
                          !shareUserName && setViewUrl(!viewUrl);
                        }}
                      >
                        <CiEdit size={18} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      className="editAddressBtn"
                      sx={{
                        position: "relative",
                        zIndex: 100,
                        fontWeight: 500,
                        color: "#4F8B33",
                        borderColor: "#4F8B33",
                        border: "1px solid #4F8B33",
                        "&:hover": { background: "#4F8B33", color: "#fff" },
                      }}
                      // startIcon={<FaSave />}
                      variant="outlined"
                      onClick={() => {
                        setViewUrl(!viewUrl);
                        handleAddUseId(userId, props);
                      }}
                    >
                      <FaSave size={18} />
                    </IconButton>
                  )}
                  <Tooltip
                    title={
                      mainSettings && mainSettings.isQRCodeGenrate
                        ? "View Your QR Code"
                        : "Generate QR Code"
                    }
                  >
                    <IconButton
                      className="editAddressBtn"
                      onClick={handleOpenQRCode}
                      sx={{
                        border: "1px solid #4F8B33",
                        position: "relative",
                        zIndex: 100,
                        fontWeight: 500,
                        color: "#4F8B33",
                        borderColor: "#4F8B33",
                        "&:hover": { background: "#4F8B33", color: "#fff" },
                      }}
                      variant="outlined"
                    >
                      <IoQrCodeOutline size={18} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={"Copy Link Address"}>
                    {/* <button
                      className="btn btn-outline-success shadow-none copyBtn"
                      type="button"
                      id="button-addon2"
                      onClick={() => {
                        navigator.clipboard.writeText(previewUrl);
                        props.showAlert("Copied", "success");
                      }}
                    >
                      <i className="fa-regular fa-copy"></i>
                    </button> */}
                    <IconButton
                      className="editAddressBtn"
                      sx={{
                        fontWeight: 500,
                        color: "#4F8B33",
                        borderColor: "#4F8B33",
                        position: "relative",
                        zIndex: 100,
                        border: "1px solid #4F8B33",
                        "&:hover": { background: "#4F8B33", color: "#fff" },
                      }}
                      variant="outlined"
                      onClick={() => {
                        navigator.clipboard.writeText(previewUrl);
                        props.showAlert("Copied", "success");
                      }}
                    >
                      <FaRegCopy size={18} />
                    </IconButton>
                  </Tooltip>

                  {/* small screen */}
                  <div
                    style={{ display: "none" }}
                    className="editIconAndCopyIconDiv"
                  >
                    <Tooltip title="Secure your Cbxtree : Click to set or update your password for added protection.">
                      <IconButton
                        onClick={handleOpen}
                        sx={{
                          fontWeight: 500,
                          border: "1px solid #4F8B33",
                          color: "#4F8B33",
                          p: "9px",
                          borderColor: "#4F8B33",
                          "&:hover": { background: "#4F8B33", color: "#fff" },
                        }}
                        // className="editAddressBtn"
                      >
                        <RiLockPasswordFill size={18} />
                      </IconButton>
                    </Tooltip>
                    {!viewUrl ? (
                      <Tooltip
                        title={
                          shareUserName
                            ? "You do not have permission to edit alias"
                            : "Edit your alias."
                        }
                      >
                        <IconButton
                          sx={{
                            fontWeight: 500,
                            cursor: shareUserName ? "default" : "pointer",
                            color: shareUserName ? "#a9a9a9" : "#4F8B33",
                            background: shareUserName ? "#e8e6e6" : "",
                            borderColor: "#4F8B33",
                            border: "1px solid #4F8B33",
                            "&:hover": {
                              background: shareUserName ? "#e8e6e6" : "#4F8B33",
                              color: shareUserName ? "#a9a9a9" : "#fff",
                            },
                          }}
                          // startIcon={<CiEdit />}
                          variant="outlined"
                          onClick={() => {
                            !shareUserName && setViewUrl(!viewUrl);
                          }}
                        >
                          <CiEdit size={18} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton
                        sx={{
                          fontWeight: 500,
                          color: "#4F8B33",
                          borderColor: "#4F8B33",
                          border: "1px solid #4F8B33",
                          "&:hover": { background: "#4F8B33", color: "#fff" },
                        }}
                        // startIcon={<FaSave />}
                        variant="outlined"
                        onClick={() => {
                          setViewUrl(!viewUrl);
                          handleAddUseId(userId, props);
                        }}
                      >
                        <FaSave size={18} />
                      </IconButton>
                    )}
                    <Tooltip
                      title={
                        mainSettings && mainSettings.isQRCodeGenrate
                          ? "View Your QR Code"
                          : "Generate QR Code"
                      }
                    >
                      <IconButton
                        // className="editAddressBtn"
                        onClick={handleOpenQRCode}
                        sx={{
                          border: "1px solid #4F8B33",
                          position: "relative",
                          zIndex: 100,
                          fontWeight: 500,
                          color: "#4F8B33",
                          borderColor: "#4F8B33",
                          "&:hover": { background: "#4F8B33", color: "#fff" },
                        }}
                        variant="outlined"
                      >
                        <IoQrCodeOutline size={18} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Copy Link Address"}>
                      {/* <button
                        className="btn btn-outline-success shadow-none copyButton"
                        type="button"
                        id="button-addon2"
                        onClick={() => {
                          navigator.clipboard.writeText(previewUrl);
                          props.showAlert("Copied", "success");
                        }}
                      >
                        <i className="fa-regular fa-copy"></i>
                      </button> */}
                      <IconButton
                        sx={{
                          fontWeight: 500,
                          color: "#4F8B33",
                          borderColor: "#4F8B33",
                          position: "relative",
                          zIndex: 100,
                          border: "1px solid #4F8B33",
                          "&:hover": { background: "#4F8B33", color: "#fff" },
                        }}
                        variant="outlined"
                        onClick={() => {
                          navigator.clipboard.writeText(previewUrl);
                          props.showAlert("Copied", "success");
                        }}
                      >
                        <FaRegCopy size={18} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                {/* <!-- Modal --> */}
                <div
                  className="modal fade"
                  id="previewModel"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <div className="modal-dialog modal-lg">
                    <div
                      className="modal-content"
                      style={{
                        marginTop: "65px",
                        borderRadius: linkData?.length > 0 ? "" : "20px",
                      }}
                    >
                      {linkData?.length > 0 ? (
                        <Preview
                          viewsetting={mainSettings}
                          viewlinks={linkData}
                        />
                      ) : (
                        <div
                          style={{
                            margin: "10px 10px 5px 10px",
                            padding: "10px",
                            textAlign: "center",
                            color: "#777e90",
                            fontWeight: 500,
                          }}
                        >
                          <h4>Please add links to get preview</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ justifyContent: "space-between", flexWrap: "wrap" }}
                className="d-flex logoContainer"
              >
                <div className="logo-div">
                  <div>
                    {mainSettings?.logo ? (
                      <img
                        src={DriveUrl(mainSettings.logo)}
                        alt="Logo"
                        style={{
                          height: "80px",
                          width: "120px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          height: "80px",
                          width: "120px",
                          background: "#fff",
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div
                      className=""
                      style={{
                        height: "18px",
                        width: "18px",
                        border: "1px solid black",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {/* <!-- Button trigger modal --> */}
                      <button
                        style={{ color: "#4F8B33", fontSize: "10px" }}
                        type="button"
                        className="btn btn-none shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadImageModel"
                      >
                        <i className="fa-solid fa-upload"></i>
                      </button>
                    </div>
                    {mainSettings?.logo && (
                      <IconButton
                        onClick={() =>
                          handleDeleteLogo(mainSettings && mainSettings?.userid)
                        }
                        sx={{ p: "4px" }}
                      >
                        <IoIosCloseCircleOutline size={22} color="#4F8B33" />
                      </IconButton>
                    )}
                    <span style={{ fontWeight: "500", color: "#4F8B33" }}>
                      Logo{" "}
                      <span>
                        <Tooltip title="Add or edit your logo url">
                          <FaInfoCircle size={14} cursor={"pointer"} />
                        </Tooltip>
                      </span>
                    </span>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="uploadImageModel"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                      <div className="modal-dialog  modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {logo ? "Edit Logo URL" : "Add Logo URL"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close shadow-none"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <input
                              type="text"
                              className="form-control"
                              id="logoInput1"
                              value={logo}
                              onChange={onChangeLogo}
                              placeholder="Google Drive URL of logo"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              style={{
                                color: "#4F8B33",
                                border: "1px solid #4F8B33",
                                fontSize: "14px",
                                background: "#fff",
                                fontWeight: 500,
                              }}
                              type="button"
                              ref={refCloseLogo}
                              className="btn btn-secondary shadow-none modalCloseBtn"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              style={{
                                background: "#4F8B33",
                                border: "1px solid #4F8B33",
                                fontSize: "14px",
                              }}
                              type="button"
                              className="btn btn-primary shadow-none modalSaveBtn"
                              onClick={handleUpdateLogo}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="logo-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {mainSettings?.backgroundImage ? (
                      <img
                        src={imageUrl}
                        alt="Background"
                        style={{
                          height: "80px",
                          width: "120px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div style={{ width: "120px", height: "80px" }}> </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className=""
                      style={{
                        height: "18px",
                        width: "18px",
                        border: "1px solid black",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "5px",
                      }}
                    >
                      {/* <!-- Button trigger modal --> */}
                      <button
                        style={{ color: "#4F8B33", fontSize: "10px" }}
                        type="button"
                        className="btn btn-none shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#backgroundModel"
                      >
                        <i className="fa-solid fa-upload"></i>
                      </button>
                    </div>
                    {mainSettings?.backgroundImage && (
                      <IconButton
                        onClick={() =>
                          handleDeleteBackgroundImage(
                            mainSettings && mainSettings?.userid
                          )
                        }
                        sx={{ p: "4px" }}
                      >
                        <IoIosCloseCircleOutline size={22} color="#4F8B33" />
                      </IconButton>
                    )}
                    <span
                      style={{
                        fontWeight: "500",
                        color: "#4F8B33",
                      }}
                    >
                      Background{" "}
                      <span>
                        <Tooltip title="Add or edit background image url">
                          <FaInfoCircle size={14} cursor={"pointer"} />
                        </Tooltip>
                      </span>
                    </span>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="backgroundModel"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {image
                                ? "Edit Background Image"
                                : "Add Background Image"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close shadow-none"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <input
                              type="text"
                              className="form-control"
                              id="backgroundInput1"
                              value={image}
                              onChange={onChangeImage}
                              placeholder="Google Drive URL of Background Image"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              style={{
                                color: "#4F8B33",
                                border: "1px solid #4F8B33",
                                fontSize: "14px",
                                background: "#fff",
                                fontWeight: 500,
                              }}
                              type="button"
                              ref={refCloseImage}
                              className="btn btn-secondary shadow-none modalCloseBtn"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              style={{
                                background: "#4F8B33",
                                border: "1px solid #4F8B33",
                                fontSize: "14px",
                              }}
                              type="button"
                              className="btn btn-primary shadow-none modalSaveBtn"
                              onClick={handleUpdateImage}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="logo-div">
                  <div
                    className=""
                    style={{
                      // border: "1px solid red",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="horizontal-gradient"
                      style={{
                        width: "120px",
                        height: "80px",
                        background: `${
                          (mainSettings?.background &&
                            mainSettings.background.includes("→") &&
                            lgBackground(mainSettings.background)) ||
                          mainSettings?.background
                        }`,
                      }}
                    ></div>
                  </div>
                  <div
                    className="my-auto"
                    style={{
                      // maxWidth: "150px",
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    {mainSettings?.background && (
                      <IconButton
                        onClick={() =>
                          handleDeleteBackgroundColor(
                            mainSettings && mainSettings?.userid
                          )
                        }
                        sx={{ p: "4px" }}
                      >
                        <IoIosCloseCircleOutline size={22} color="#4F8B33" />
                      </IconButton>
                    )}

                    <select
                      style={{
                        color: "#4F8B33",
                        border: "1px solid #4F8B33",
                        fontWeight: 500,
                      }}
                      className="form-select  my-1 p-1"
                      aria-label=""
                      // value={
                      //   mainSettings?.background &&
                      //   lgvBackground(mainSettings?.background)
                      // }
                      // onChange={handleBackground}
                      value={selectedBackground}
                      onChange={(e) => {
                        setSelectedBackground(e.target.value);
                        handleBackground(e.target.value);
                      }}
                    >
                      <option selected>Background </option>
                      {bgList?.map((list, i) => (
                        <option value={list[1]} key={i}>
                          {list[0]}
                        </option>
                      ))}
                    </select>

                    <span>
                      <Tooltip title="Select background color">
                        <FaInfoCircle
                          size={14}
                          cursor={"pointer"}
                          color="#4F8B33"
                        />
                      </Tooltip>
                    </span>
                  </div>
                </div>

                <div className="logo-div">
                  <div>
                    {mainSettings?.pageTitleAndFeviconIconSetting
                      ?.feviconIcon ? (
                      <img
                        src={DriveUrl(
                          mainSettings?.pageTitleAndFeviconIconSetting
                            ?.feviconIcon
                        )}
                        alt="Favicon Icon"
                        style={{
                          height: "80px",
                          width: "120px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <img
                        src="./cbxFavIcon.svg"
                        alt="Logo"
                        style={{
                          height: "80px",
                          width: "120px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                    <div
                      className=""
                      style={{
                        height: "18px",
                        width: "18px",
                        border: "1px solid black",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "5px",
                      }}
                    >
                      {/* <!-- Button trigger modal --> */}
                      <button
                        style={{
                          color: "#4F8B33",
                          position: "relative",
                          zIndex: 100,
                          fontSize: "10px",
                        }}
                        type="button"
                        className="btn btn-none shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadFavIconModel"
                      >
                        <i className="fa-solid fa-upload"></i>
                      </button>
                    </div>
                    {mainSettings?.pageTitleAndFeviconIconSetting
                      ?.feviconIcon && (
                      <IconButton
                        sx={{ position: "relative", zIndex: 100, p: "4px" }}
                        onClick={() =>
                          handleDeleteFaviconIcon(
                            mainSettings && mainSettings?.userid
                          )
                        }
                      >
                        <IoIosCloseCircleOutline size={22} color="#4F8B33" />
                      </IconButton>
                    )}
                    <span
                      style={{
                        fontWeight: "500",
                        color: "#4F8B33",
                        position: "relative",
                        zIndex: 100,
                      }}
                    >
                      {mainSettings?.pageTitleAndFeviconIconSetting?.title ||
                        "CEOITBOX"}{" "}
                      <span>
                        <Tooltip title="You can change the favicon icon and the name that shows up in the browser tab.">
                          <FaInfoCircle size={14} cursor={"pointer"} />
                        </Tooltip>
                      </span>
                    </span>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="uploadFavIconModel"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                      <div className="modal-dialog  modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {title && feviconIcon
                                ? "Edit Favicon Icon And Title Name"
                                : "Add Favicon Icon And Title Name"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close shadow-none"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="form-group">
                              <input
                                required
                                type="text"
                                className="form-control"
                                placeholder="Enter title name"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>

                            <div className="input-group mt-3">
                              <input
                                required
                                type="url"
                                className="form-control"
                                placeholder="Enter icon URL"
                                value={feviconIcon}
                                onChange={(e) => setFeviconIcon(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              style={{
                                color: "#4F8B33",
                                border: "1px solid #4F8B33",
                                fontSize: "14px",
                                background: "#fff",
                                fontWeight: 500,
                              }}
                              type="button"
                              ref={refCloseFavModal}
                              className="btn btn-secondary shadow-none modalCloseBtn"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              style={{
                                background: "#4F8B33",
                                border: "1px solid #4F8B33",
                                fontSize: "14px",
                              }}
                              type="button"
                              className="btn btn-primary shadow-none modalSaveBtn"
                              onClick={handleUpdateFeviconIconAndTitleName}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add Header */}
              <div className="d-flex justify-content-center my-3">
                <div className="input-group descriptionSetting">
                  <input
                    style={{ background: "#f3f3f3" }}
                    type="text"
                    className="form-control headingInput"
                    placeholder="Add Header"
                    aria-label="Add Header"
                    aria-describedby="button-addon2"
                    value={header}
                    onChange={onchangeHeader}
                  />
                  <button
                    style={{
                      background: "#DDDDDD",
                      color: "#000",
                      border: "none",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      borderLeft: "1px solid #ccc",
                    }}
                    className="btn btn-primary shadow-none descAddBtn"
                    type="button"
                    id="button-addon2"
                    // onClick={() => {
                    //   mainSettings && mainSettings.header
                    //     ? handleDeleteHeader(
                    //         mainSettings && mainSettings?.userid,
                    //         "header"
                    //       )
                    //     : handleheader();
                    // }}
                    onClick={() => {
                      if (isEdit) {
                        handleDeleteHeader(mainSettings?.userid, "header");
                        setIsEdit(false);
                      } else {
                        handleheader();
                      }
                    }}
                    disabled={!header}
                  >
                    {/* {mainSettings && mainSettings.header ? (
                      <i
                        style={{ color: "red" }}
                        className="fa-solid fa-minus"
                      ></i>
                    ) : (
                      <FaPlus
                        style={{
                          fontWeight: "bold",
                          color: header ? "#000" : "#999",
                        }}
                      />
                    )} */}
                    {isEdit ? (
                      <i
                        style={{ color: "red" }}
                        className="fa-solid fa-minus"
                      ></i>
                    ) : (
                      <FaPlus
                        style={{
                          fontWeight: "bold",
                          color: header ? "#000" : "#999",
                        }}
                      />
                    )}
                  </button>

                  <button
                    style={{
                      marginLeft: "5px",
                      background: "#4F8B33",
                      border: "none",
                      borderRadius: "4px",
                    }}
                    className={
                      mainSettings?.header === ""
                        ? "btn btn-secondary"
                        : "btn btn-primary descAddBtn"
                    }
                    type="button"
                    onClick={handleEditClick}
                    // disabled={mainSettings?.header === "" ? "disabled" : ""}
                    disabled={!mainSettings?.header}
                  >
                    <i className="fa-solid fa-gear"></i>
                  </button>

                  <div
                    className="infoIcon"
                    style={{
                      margin: "5px 0px 5px 5px",
                      position: "relative",
                      zIndex: 100,
                    }}
                  >
                    <Tooltip title="Add or edit your header content">
                      <FaInfoCircle
                        size={14}
                        cursor={"pointer"}
                        color="#4F8B33"
                      />
                    </Tooltip>
                  </div>

                  {/* small screen */}
                  <div
                    className="descBtn"
                    style={{
                      display: "none",
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        background: "#DDDDDD",
                        border: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        color: "#000",
                        borderLeft: "1px solid #ccc",
                      }}
                      className="btn btn-primary shadow-none"
                      type="button"
                      id="button-addon2"
                      // onClick={handleheader}
                      // onClick={() => {
                      //   mainSettings && mainSettings.header
                      //     ? handleDeleteHeader(
                      //         mainSettings && mainSettings?.userid,
                      //         "header"
                      //       )
                      //     : handleheader();
                      // }}
                      onClick={() => {
                        if (isEdit) {
                          handleDeleteHeader(mainSettings?.userid, "header");
                          setIsEdit(false);
                        } else {
                          handleheader();
                        }
                      }}
                      disabled={!header}
                    >
                      {isEdit ? (
                        <i
                          style={{ color: "red" }}
                          className="fa-solid fa-minus"
                        ></i>
                      ) : (
                        <FaPlus
                          style={{
                            fontWeight: "bold",
                            color: header ? "#000" : "#999",
                          }}
                        />
                      )}
                    </button>
                    <button
                      style={{
                        marginLeft: "5px",
                        background: "#4F8B33",
                        border: "none",
                        borderRadius: "4px",
                      }}
                      className={
                        mainSettings?.header === ""
                          ? "btn btn-secondary"
                          : "btn btn-primary"
                      }
                      type="button"
                      onClick={handleEditClick}
                      disabled={!mainSettings?.header}
                    >
                      <i className="fa-solid fa-gear"></i>
                    </button>
                    <div
                      style={{
                        position: "relative",
                        zIndex: 100,
                      }}
                    >
                      <Tooltip title="Add or edit your header content">
                        <FaInfoCircle
                          size={14}
                          cursor={"pointer"}
                          color="#4F8B33"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add description */}
              <div className="d-flex justify-content-center my-3">
                <div className="input-group descriptionSetting">
                  <textarea
                    style={{ background: "#f3f3f3" }}
                    type="text"
                    rows="2"
                    className="form-control textArea"
                    placeholder="Add Footer"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={description}
                    onChange={onchangeDescription}
                  />

                  <button
                    style={{
                      background: "#DDDDDD",
                      border: "none",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      color: "#000",
                      borderLeft: "1px solid #ccc",
                      float: "right",
                    }}
                    className="btn btn-primary shadow-none descAddBtn"
                    type="button"
                    id="button-addon2"
                    // onClick={() => {
                    //   mainSettings && mainSettings.description
                    //     ? handleDeleteHeader(
                    //         mainSettings && mainSettings?.userid,
                    //         "description"
                    //       )
                    //     : handledescription();
                    // }}

                    // onClick={handledescription}
                    // disabled={description === "" ? "disabled" : ""}
                    onClick={() => {
                      if (isEditDesc) {
                        handleDeleteHeader(mainSettings?.userid, "description");
                        setIsEditDesc(false);
                      } else {
                        handledescription();
                      }
                    }}
                    disabled={!description}
                  >
                    {isEditDesc ? (
                      <i
                        style={{ color: "red" }}
                        className="fa-solid fa-minus"
                      ></i>
                    ) : (
                      <FaPlus
                        style={{
                          fontWeight: "bold",
                          color: description ? "#000" : "#999",
                        }}
                      />
                    )}
                  </button>
                  <button
                    style={{
                      marginLeft: "5px",
                      background: "#4F8B33",
                      border: "none",
                      borderRadius: "4px",
                      float: "right",
                    }}
                    className={
                      mainSettings?.description === ""
                        ? "btn btn-secondary shadow-none "
                        : "btn btn-primary shadow-none descAddBtn"
                    }
                    type="button"
                    onClick={handleEditdescription}
                    disabled={!mainSettings?.description}
                  >
                    <i className="fa-solid fa-gear"></i>
                  </button>
                  <div
                    className="infoIcon"
                    style={{
                      margin: "15px 0px 5px 5px",
                      position: "relative",
                      zIndex: 100,
                    }}
                  >
                    <Tooltip title="Add or edit your description content">
                      <FaInfoCircle
                        size={14}
                        cursor={"pointer"}
                        color="#4F8B33"
                      />
                    </Tooltip>
                  </div>

                  {/* small screen */}
                  <div
                    className="descBtn"
                    style={{
                      display: "none",
                    }}
                  >
                    <button
                      style={{
                        background: "#DDDDDD",
                        border: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        color: "#000",
                        borderLeft: "1px solid #ccc",
                        float: "right",
                      }}
                      className="btn btn-primary shadow-none"
                      type="button"
                      id="button-addon2"
                      // onClick={handledescription}
                      // disabled={description === "" ? "disabled" : ""}
                      onClick={() => {
                        if (isEditDesc) {
                          handleDeleteHeader(
                            mainSettings?.userid,
                            "description"
                          );
                          setIsEditDesc(false);
                        } else {
                          handledescription();
                        }
                      }}
                      disabled={!description}
                    >
                      {/* {mainSettings && mainSettings.description ? (
                        <i
                          style={{ color: "red" }}
                          className="fa-solid fa-minus"
                        ></i>
                      ) : (
                        <FaPlus
                          style={{
                            fontWeight: "bold",
                            color: description ? "#000" : "#999",
                          }}
                        />
                      )} */}
                      {isEditDesc ? (
                        <i
                          style={{ color: "red" }}
                          className="fa-solid fa-minus"
                        ></i>
                      ) : (
                        <FaPlus
                          style={{
                            fontWeight: "bold",
                            color: description ? "#000" : "#999",
                          }}
                        />
                      )}
                    </button>
                    <button
                      style={{
                        marginLeft: "5px",
                        background: "#4F8B33",
                        border: "none",
                        borderRadius: "4px",
                        float: "right",
                      }}
                      className={
                        mainSettings?.description === ""
                          ? "btn btn-secondary shadow-none"
                          : "btn btn-primary shadow-none"
                      }
                      type="button"
                      onClick={handleEditdescription}
                      // disabled={
                      //   mainSettings?.description === "" ? "disabled" : ""
                      // }
                      disabled={!mainSettings?.description}
                    >
                      <i className="fa-solid fa-gear"></i>
                    </button>
                    <div
                      style={{
                        position: "relative",
                        zIndex: 100,
                      }}
                    >
                      <Tooltip title="Add or edit your description content">
                        <FaInfoCircle
                          size={14}
                          cursor={"pointer"}
                          color="#4F8B33"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add Company name */}
              <div className="d-flex justify-content-center my-3">
                <div className="input-group descriptionSetting">
                  <input
                    style={{ background: "#f3f3f3" }}
                    type="text"
                    className="form-control headingInput"
                    placeholder="Add Company Name"
                    aria-label="Add Company Name"
                    aria-describedby="button-addon2"
                    value={companyName}
                    onChange={onchangeCompanyName}
                  />
                  <button
                    style={{
                      background: "#DDDDDD",
                      border: "none",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      color: "#000",
                      borderLeft: "1px solid #ccc",
                    }}
                    className="btn btn-primary shadow-none descAddBtn"
                    type="button"
                    id="button-addon2"
                    // onClick={() => {
                    //   mainSettings && mainSettings.companyName
                    //     ? handleDeleteHeader(
                    //         mainSettings && mainSettings?.userid,
                    //         "companyName"
                    //       )
                    //     : handleCompanyName();
                    // }}
                    onClick={() => {
                      if (isEditCName) {
                        handleDeleteHeader(mainSettings?.userid, "companyName");
                        setIsEditCName(false);
                      } else {
                        handleCompanyName();
                      }
                    }}
                    // onClick={handleCompanyName}
                    disabled={!companyName}
                  >
                    {/* Add */}
                    {isEditCName ? (
                      <i
                        style={{ color: "red" }}
                        className="fa-solid fa-minus"
                      ></i>
                    ) : (
                      <FaPlus
                        style={{
                          fontWeight: "bold",
                          color: companyName ? "#000" : "#999",
                        }}
                      />
                    )}
                  </button>

                  {/* <button
                    style={{
                      marginLeft: "5px",
                      background: "#4F8B33",
                      border: "none",
                      borderRadius: "4px",
                    }}
                    className={
                      mainSettings?.companyName === ""
                        ? "btn btn-secondary"
                        : "btn btn-primary descAddBtn"
                    }
                    type="button"
                    onClick={handleCompanyNameEditClick}
                    disabled={!mainSettings?.companyName}
                  >
                    <i className="fa-solid fa-gear"></i>
                  </button> */}
                  <div
                    className="infoIcon"
                    style={{
                      margin: "5px 0px 5px 5px",
                      position: "relative",
                      zIndex: 100,
                    }}
                  >
                    <Tooltip title="Add or edit your company name">
                      <FaInfoCircle
                        size={14}
                        cursor={"pointer"}
                        color="#4F8B33"
                      />
                    </Tooltip>
                  </div>

                  {/* small screen */}
                  <div
                    className="descBtn"
                    style={{
                      display: "none",
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        background: "#DDDDDD",
                        border: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        color: "#000",
                        borderLeft: "1px solid #ccc",
                      }}
                      className="btn btn-primary shadow-none"
                      type="button"
                      id="button-addon2"
                      // onClick={() => {
                      //   mainSettings && mainSettings.companyName
                      //     ? handleDeleteHeader(
                      //         mainSettings && mainSettings?.userid,
                      //         "companyName"
                      //       )
                      //     : handleCompanyName();
                      // }}
                      onClick={() => {
                        if (isEditCName) {
                          handleDeleteHeader(
                            mainSettings?.userid,
                            "companyName"
                          );
                          setIsEditCName(false);
                        } else {
                          handleCompanyName();
                        }
                      }}
                      // onClick={handleCompanyName}
                      disabled={!companyName}
                    >
                      {isEditCName ? (
                        <i
                          style={{ color: "red" }}
                          className="fa-solid fa-minus"
                        ></i>
                      ) : (
                        <FaPlus
                          style={{
                            fontWeight: "bold",
                            color: companyName ? "#000" : "#999",
                          }}
                        />
                      )}
                    </button>
                    {/* <button
                      style={{
                        marginLeft: "5px",
                        background: "#4F8B33",
                        border: "none",
                        borderRadius: "4px",
                      }}
                      className={
                        mainSettings?.companyName === ""
                          ? "btn btn-secondary"
                          : "btn btn-primary"
                      }
                      type="button"
                      onClick={handleCompanyNameEditClick}
                      disabled={!mainSettings?.companyName}
                    >
                      <i className="fa-solid fa-gear"></i>
                    </button> */}
                    <div
                      style={{
                        position: "relative",
                        zIndex: 100,
                      }}
                    >
                      <Tooltip title="Add or edit your header content">
                        <FaInfoCircle
                          size={14}
                          cursor={"pointer"}
                          color="#4F8B33"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between my-3 footerSettingDiv">
                <div
                  className="footerAndSearchDiv"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <div className="form-check form-switch">
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={mainSettings?.footer}
                      onChange={handleFooter}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Footer
                    </label>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={mainSettings?.search}
                      onChange={handleSearch}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Search
                    </label>
                  </div>
                </div>

                <div
                  className="linksAndPaginationDiv"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <div>
                    <button
                      style={{
                        padding: "0px 3px",
                        background: "#4F8B33",
                        border: "1px solid #4F8B33",
                      }}
                      className={
                        linkData?.length < 1
                          ? "btn btn-secondary shadow-none"
                          : "btn btn-primary shadow-none"
                      }
                      type="button"
                      onClick={handleEditLinks}
                      disabled={linkData?.length > 0 ? "" : "disabled"}
                    >
                      Links
                      <i className="fa-solid fa-gear m-1"></i>
                    </button>
                    <span style={{ margin: "5px 0px 5px 5px" }}>
                      <Tooltip title="Edit links settings">
                        <FaInfoCircle
                          size={14}
                          cursor={"pointer"}
                          color="#4F8B33"
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <div>
                    <button
                      style={{
                        padding: "0px 3px",
                        background: "#4F8B33",
                        border: "1px solid #4F8B33",
                        position: "relative",
                        zIndex: 100,
                      }}
                      className="btn btn-primary shadow-none"
                      type="button"
                      onClick={handleEditPagination}
                      disabled={!mainSettings?.footer}
                    >
                      Pagination
                      <i className="fa-solid fa-gear m-1"></i>
                    </button>

                    <span
                      style={{
                        margin: "5px 0px 5px 5px",
                        position: "relative",
                        zIndex: 100,
                      }}
                    >
                      <Tooltip title="Edit pagination settings">
                        <FaInfoCircle
                          size={14}
                          cursor={"pointer"}
                          color="#4F8B33"
                        />
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </div>

              {mainSettings?.footer && (
                <div className="footerSettingContainer">
                  <div className="d-flex justify-content-center my-3">
                    <div className="input-group">
                      <span>
                        <input
                          style={{
                            background: "#f3f3f3",
                            border:
                              isLeftFooterNameEmpty && !isLeftFooterURLEmpty
                                ? "1px solid red"
                                : "",
                          }}
                          type="text"
                          className="form-control"
                          name="leftFooterName"
                          aria-label="Text input with dropdown button"
                          value={leftFooter?.leftFooterName}
                          onChange={onChangeleft}
                          placeholder="Left Footer Name"
                        />
                      </span>
                      <input
                        style={{
                          background: "#f3f3f3",
                          border:
                            isLeftFooterURLEmpty && !isLeftFooterNameEmpty
                              ? "1px solid red"
                              : "",
                        }}
                        type="text"
                        className="form-control"
                        name="leftFooterURL"
                        aria-label="Text input with dropdown button"
                        value={leftFooter?.leftFooterURL}
                        onChange={onChangeleft}
                        placeholder="Left Footer URL"
                      />
                      <button
                        style={{
                          background: "#DDDDDD",
                          border: "none",
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                          color: "#000",
                          borderLeft: "1px solid #ccc",
                        }}
                        type="button"
                        className="btn btn-primary shadow-none"
                        // onClick={() => {
                        //   mainSettings &&
                        //   mainSettings?.leftFooter[0]?.leftFooterURL
                        //     ? handleDeleteHeader(
                        //         mainSettings && mainSettings?.userid,
                        //         "leftFooter"
                        //       )
                        //     : handleAddleft();
                        // }}

                        onClick={handleClick}
                        // disabled={
                        //   !(
                        //     leftFooter?.leftFooterURL &&
                        //     leftFooter?.leftFooterName
                        //   )
                        // }
                      >
                        {/* Add */}
                        {
                          // mainSettings &&
                          // mainSettings.leftFooter[0]?.leftFooterURL
                          isEditLeftFooter ? (
                            <i
                              style={{ color: "red" }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <FaPlus
                              style={{
                                fontWeight: "bold",
                                color:
                                  leftFooter?.leftFooterName &&
                                  leftFooter?.leftFooterURL
                                    ? "#000"
                                    : "#aba9a9",
                              }}
                            />
                          )
                        }
                      </button>

                      <button
                        style={{
                          marginLeft: "5px",
                          background: "#4F8B33",
                          border: "none",
                          borderRadius: "4px",
                        }}
                        className={
                          mainSettings?.leftFooter?.[0]?.leftFooterName === ""
                            ? "btn btn-secondary shadow-none"
                            : "btn btn-primary shadow-none"
                        }
                        type="button"
                        onClick={handleEditLeftFooter}
                        // disabled={
                        //   mainSettings?.leftFooter?.[0]?.leftFooterName === ""
                        //     ? "disabled"
                        //     : ""
                        // }
                        disabled={
                          !mainSettings?.leftFooter?.[0]?.leftFooterName
                        }
                      >
                        <i className="fa-solid fa-gear"></i>
                      </button>

                      <span
                        style={{
                          margin: "5px 0px 5px 5px",
                          position: "relative",
                          zIndex: 100,
                        }}
                      >
                        <Tooltip title="Edit left footer settings">
                          <FaInfoCircle
                            size={14}
                            cursor={"pointer"}
                            color="#4F8B33"
                          />
                        </Tooltip>
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center my-3">
                    <div className="input-group">
                      <span>
                        <input
                          style={{
                            background: "#f3f3f3",
                            border:
                              isRightFooterNameEmpty && !isRightFooterURLEmpty
                                ? "1px solid red"
                                : "",
                          }}
                          type="text"
                          className="form-control"
                          name="rightFooterName"
                          aria-label="Text input with dropdown button"
                          value={rightFooter.rightFooterName}
                          onChange={onChangeright}
                          placeholder="Right Footer Name"
                        />
                      </span>
                      <input
                        style={{
                          background: "#f3f3f3",
                          border:
                            isRightFooterURLEmpty && !isRightFooterNameEmpty
                              ? "1px solid red"
                              : "",
                        }}
                        type="text"
                        className="form-control"
                        name="rightFooterURL"
                        aria-label="Text input with dropdown button"
                        value={rightFooter.rightFooterURL}
                        onChange={onChangeright}
                        placeholder="Right Footer URL"
                      />
                      <button
                        style={{
                          background: "#DDDDDD",
                          border: "none",
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                          color: "#000",
                          borderLeft: "1px solid #ccc",
                        }}
                        type="button"
                        className="btn btn-primary shadow-none"
                        // onClick={handleAddright}
                        // disabled={!rightFooter.rightFooterURL}
                        onClick={handleClickRightFooter}
                        // onClick={() => {
                        //   mainSettings &&
                        //   mainSettings?.rightFooter[0]?.rightFooterURL
                        //     ? handleDeleteHeader(
                        //         mainSettings && mainSettings?.userid,
                        //         "rightFooter"
                        //       )
                        //     : handleAddright();
                        // }}
                        // disabled={
                        //   !(
                        //     rightFooter?.rightFooterURL &&
                        //     rightFooter?.rightFooterName
                        //   )
                        // }
                      >
                        {
                          // mainSettings &&
                          // mainSettings.rightFooter[0]?.rightFooterURL
                          isEditRightFooter ? (
                            <i
                              style={{ color: "red" }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <FaPlus
                              style={{
                                fontWeight: "bold",
                                color:
                                  rightFooter?.rightFooterURL &&
                                  rightFooter?.rightFooterName
                                    ? "#000"
                                    : "#aba9a9",
                              }}
                            />
                          )
                        }
                      </button>
                      <button
                        style={{
                          marginLeft: "5px",
                          background: "#4F8B33",
                          border: "none",
                          borderRadius: "4px",
                        }}
                        className={
                          mainSettings?.rightFooter?.[0]?.rightFooterName === ""
                            ? "btn btn-secondary shadow-none"
                            : "btn btn-primary shadow-none"
                        }
                        type="button"
                        onClick={handleEditRightFooter}
                        // disabled={
                        //   mainSettings?.rightFooter?.[0]?.rightFooterName === ""
                        //     ? "disabled"
                        //     : ""
                        // }
                        disabled={
                          !mainSettings?.rightFooter?.[0]?.rightFooterName
                        }
                      >
                        <i className="fa-solid fa-gear"></i>
                      </button>

                      <span
                        style={{
                          margin: "5px 0px 5px 5px",
                          position: "relative",
                          zIndex: 100,
                        }}
                      >
                        <Tooltip title="Edit right footer settings">
                          <FaInfoCircle
                            size={14}
                            cursor={"pointer"}
                            color="#4F8B33"
                          />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* small screen  */}
              {mainSettings?.footer && (
                <div style={{ display: "none" }} className="footer-setting">
                  <div style={{ marginBottom: "20px" }}>
                    <div>
                      <input
                        style={{
                          background: "#f3f3f3",
                          marginBottom: "10px",
                          outline:
                            isLeftFooterNameEmpty && !isLeftFooterURLEmpty
                              ? "1px solid red"
                              : "",
                        }}
                        type="text"
                        className="form-control"
                        name="leftFooterName"
                        aria-label="Text input with dropdown button"
                        value={leftFooter?.leftFooterName}
                        onChange={onChangeleft}
                        placeholder="Left Footer Name"
                      />

                      <input
                        style={{
                          background: "#f3f3f3",
                          outline:
                            isLeftFooterURLEmpty && !isLeftFooterNameEmpty
                              ? "1px solid red"
                              : "",
                        }}
                        type="text"
                        className="form-control"
                        name="leftFooterURL"
                        aria-label="Text input with dropdown button"
                        value={leftFooter?.leftFooterURL}
                        onChange={onChangeleft}
                        placeholder="Left Footer URL"
                      />
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <button
                          style={{
                            background: "#DDDDDD",
                            border: "none",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            color: "#000",
                            borderLeft: "1px solid #ccc",
                          }}
                          type="button"
                          className="btn btn-primary shadow-none"
                          onClick={handleClick}
                          // onClick={handleAddleft}
                          // disabled={!leftFooter?.leftFooterURL}
                          // onClick={() => {
                          //   mainSettings &&
                          //   mainSettings?.leftFooter[0]?.leftFooterURL
                          //     ? handleDeleteHeader(
                          //         mainSettings && mainSettings?.userid,
                          //         "leftFooter"
                          //       )
                          //     : handleAddleft();
                          // }}
                          // disabled={
                          //   !(
                          //     leftFooter?.leftFooterURL &&
                          //     leftFooter?.leftFooterName
                          //   )
                          // }
                        >
                          {isEditLeftFooter ? (
                            <i
                              style={{ color: "red" }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <FaPlus
                              style={{
                                fontWeight: "bold",
                                color:
                                  leftFooter?.leftFooterName &&
                                  leftFooter?.leftFooterURL
                                    ? "#000"
                                    : "#aba9a9",
                              }}
                            />
                          )}
                        </button>
                        <button
                          style={{
                            marginLeft: "5px",
                            background: "#4F8B33",
                            border: "none",
                            borderRadius: "4px",
                          }}
                          className={
                            mainSettings?.leftFooter?.[0]?.leftFooterName === ""
                              ? "btn btn-secondary shadow-none"
                              : "btn btn-primary shadow-none"
                          }
                          type="button"
                          onClick={handleEditLeftFooter}
                          // disabled={
                          //   mainSettings?.leftFooter?.[0]?.leftFooterName === ""
                          //     ? "disabled"
                          //     : ""
                          // }
                          disabled={
                            !mainSettings?.leftFooter?.[0]?.leftFooterName
                          }
                        >
                          <i className="fa-solid fa-gear"></i>
                        </button>
                        <span style={{ margin: "5px 0px 5px 5px" }}>
                          <Tooltip title="Edit left footer settings">
                            <FaInfoCircle
                              size={14}
                              cursor={"pointer"}
                              color="#4F8B33"
                            />
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <div>
                      <input
                        style={{
                          background: "#f3f3f3",
                          marginBottom: "10px",
                          border:
                            isRightFooterNameEmpty && !isRightFooterURLEmpty
                              ? "1px solid red"
                              : "",
                        }}
                        type="text"
                        className="form-control"
                        name="rightFooterName"
                        aria-label="Text input with dropdown button"
                        value={rightFooter.rightFooterName}
                        onChange={onChangeright}
                        placeholder="Right Footer Name"
                      />

                      <input
                        style={{
                          background: "#f3f3f3",
                          border:
                            isRightFooterURLEmpty && !isRightFooterNameEmpty
                              ? "1px solid red"
                              : "",
                        }}
                        type="text"
                        className="form-control"
                        name="rightFooterURL"
                        aria-label="Text input with dropdown button"
                        value={rightFooter.rightFooterURL}
                        onChange={onChangeright}
                        placeholder="Right Footer URL"
                      />
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <button
                          style={{
                            background: "#DDDDDD",
                            border: "none",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            color: "#000",
                            borderLeft: "1px solid #ccc",
                          }}
                          type="button"
                          className="btn btn-primary shadow-none"
                          // onClick={handleAddright}
                          // disabled={!rightFooter.rightFooterURL}
                          onClick={handleClickRightFooter}
                          // onClick={() => {
                          //   mainSettings &&
                          //   mainSettings?.rightFooter[0]?.rightFooterURL
                          //     ? handleDeleteHeader(
                          //         mainSettings && mainSettings?.userid,
                          //         "rightFooter"
                          //       )
                          //     : handleAddright();
                          // }}
                          // disabled={
                          //   !(
                          //     rightFooter?.rightFooterURL &&
                          //     rightFooter?.rightFooterName
                          //   )
                          // }
                        >
                          {isEditRightFooter ? (
                            <i
                              style={{ color: "red" }}
                              className="fa-solid fa-minus"
                            ></i>
                          ) : (
                            <FaPlus
                              style={{
                                fontWeight: "bold",
                                color:
                                  rightFooter?.rightFooterURL &&
                                  rightFooter?.rightFooterName
                                    ? "#000"
                                    : "#aba9a9",
                              }}
                            />
                          )}
                        </button>
                        <button
                          style={{
                            marginLeft: "5px",
                            background: "#4F8B33",
                            border: "none",
                            borderRadius: "4px",
                          }}
                          className={
                            mainSettings?.rightFooter?.[0]?.rightFooterName ===
                            ""
                              ? "btn btn-secondary shadow-none"
                              : "btn btn-primary shadow-none"
                          }
                          type="button"
                          onClick={handleEditRightFooter}
                          // disabled={
                          //   mainSettings?.rightFooter?.[0]?.rightFooterName === ""
                          //     ? "disabled"
                          //     : ""
                          // }
                          disabled={
                            !mainSettings?.rightFooter?.[0]?.rightFooterName
                          }
                        >
                          <i className="fa-solid fa-gear"></i>
                        </button>
                        <span style={{ margin: "5px 0px 5px 5px" }}>
                          <Tooltip title="Edit right footer settings">
                            <FaInfoCircle
                              size={14}
                              cursor={"pointer"}
                              color="#4F8B33"
                            />
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                style={{ flexDirection: "column" }}
                className="d-flex justify-content-center my-1"
              >
                <div className="input-group">
                  <input
                    style={{ background: "#f3f3f3" }}
                    type="text"
                    className="form-control"
                    name="rightFooterURL"
                    aria-label="Text input with dropdown button"
                    value={domain}
                    onChange={onChangedomain}
                    placeholder="Add Domain URL"

                    // disabled={user?.paid ? "" : 'disabled'}
                  />
                  {shareUserName ? (
                    <Tooltip
                      title={"You do not have permission to add a domain."}
                    >
                      <button
                        style={{
                          background: "#d3d3d3",
                          border: "none",
                          color: "#a9a9a9",
                          borderRadius: "4px",
                          cursor: "default",
                        }}
                        type="button"
                        className="btn btn-primary shadow-none"
                        disabled={!domain}
                      >
                        <FaPlus
                          style={{
                            fontWeight: "bold",
                          }}
                        />
                      </button>
                    </Tooltip>
                  ) : (
                    <button
                      style={{
                        background: "#4F8B33",
                        border: "none",
                        borderRadius: "4px",
                      }}
                      type="button"
                      className="btn btn-primary shadow-none"
                      // onClick={() => {
                      //   user && user?.domain
                      //     ? handleDeleteDomain(user?._id)
                      //     : handleAddDomain(domain);
                      // }}
                      onClick={handleClickDomain}
                      // disabled={shareUserName}
                      // disabled={user?.paid ? "" : 'disabled'}
                      disabled={!domain}
                    >
                      {user && user?.domain ? (
                        <i
                          // style={{ color: "red" }}
                          className="fa-solid fa-minus"
                        ></i>
                      ) : (
                        <FaPlus
                          style={{
                            fontWeight: "bold",
                            // color: header ? "#000" : "#999",
                          }}
                        />
                      )}
                    </button>
                  )}
                  <span
                    style={{
                      margin: "5px 0px 5px 5px",
                      position: "relative",
                      zIndex: 100,
                    }}
                  >
                    <Tooltip title="Add or update your domain">
                      <FaInfoCircle
                        size={14}
                        cursor={"pointer"}
                        color="#4F8B33"
                      />
                    </Tooltip>
                  </span>
                </div>
                <p style={{ fontSize: "12px", marginTop: "5px", width: "98%" }}>
                  <span style={{ fontWeight: "500" }}>Note : </span>
                  Go to your domain hosting site (ex. GoDaddy, etc.) and go to
                  that particular domain and then in the Forwarding section,
                  edit the IP from the existing to - 103.209.144.215, until
                  these changes are not done above view link will not work.
                </p>
              </div>
              {/* {user?.paid ? "" :
        <i className="" style={{ color: 'red' }}>Please upgrade to <strong>Premium version</strong> to use your own domain</i>
      } */}

              <div className="my-4">
                {<AddSocialLink showAlert={props.showAlert} />}
              </div>

              <CustomModal
                show={isEditing}
                handleClose={handleClose}
                heading={"Edit Header Settings"}
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="fontSize"
                    name="fontSize"
                    className="form-control"
                    placeholder="Enter font size in pixel"
                    value={editedFontSize}
                    onChange={(e) => setEditedFontSize(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <select
                    required
                    className="form-control my-2"
                    id="fontFamily"
                    name="fontFamily"
                    value={editedFontFamily}
                    onChange={(e) => setEditedFontFamily(e.target.value)}
                  >
                    <option value="sans-serif">Sans-Serif</option>
                    <option value="Roboto">Roboto</option>
                    <option value="serif">Serif</option>
                    <option value="arial">Arial</option>
                    <option value="Open Sans">Open Sans</option>
                    <option value="Futura">Futura</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Calibri">Calibri</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Palatino">Palatino</option>
                  </select>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    id="fontColor"
                    className="form-control my-2"
                    name="fontColor"
                    placeholder="Enter color"
                    value={editedFontColor}
                    onChange={(e) => setEditedFontColor(e.target.value)}
                  />
                  <ColorPicker />
                </div>
                <div className="modal-footer px-0">
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      padding: "5px 10px",
                    }}
                    className="btn btn-primary px-2 shadow-none modalSaveBtn"
                    onClick={handleSaveClick}
                  >
                    Save Changes
                  </button>
                  <button
                    style={{
                      color: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      background: "#fff",
                      fontWeight: 500,
                      padding: "5px 10px",
                    }}
                    type="button"
                    className="btn btn-secondary shadow-none modalCloseBtn"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </CustomModal>
              <CustomModal
                show={isEditingDescription}
                handleClose={handleClosedescription}
                heading={"Edit Footer Settings"}
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="fontSize"
                    name="fontSize"
                    className="form-control"
                    placeholder="Enter font size in pixel"
                    value={editDescSize}
                    onChange={(e) => setEditDescSize(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <select
                    required
                    className="form-control my-2"
                    id="fontFamily"
                    name="fontFamily"
                    value={editDescFontFamily}
                    onChange={(e) => setEditDescFontFamily(e.target.value)}
                  >
                    <option value="sans-serif">Sans-Serif</option>
                    <option value="Roboto">Roboto</option>
                    <option value="serif">Serif</option>
                    <option value="arial">Arial</option>
                    <option value="Open Sans">Open Sans</option>
                    <option value="Futura">Futura</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Calibri">Calibri</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Palatino">Palatino</option>
                  </select>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    id="fontColor"
                    className="form-control my-2"
                    name="fontColor"
                    placeholder="Enter color"
                    value={editDescColor}
                    onChange={(e) => setEditDescColor(e.target.value)}
                  />
                  <ColorPicker />
                </div>
                <div className="modal-footer px-0">
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      padding: "5px 10px",
                    }}
                    className="btn btn-primary shadow-none modalSaveBtn"
                    onClick={handleSaveDescription}
                  >
                    Save Changes
                  </button>
                  <button
                    style={{
                      color: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      background: "#fff",
                      fontWeight: 500,
                      padding: "5px 10px",
                    }}
                    type="button"
                    className="btn btn-secondary shadow-none modalCloseBtn"
                    onClick={handleClosedescription}
                  >
                    Close
                  </button>
                </div>
              </CustomModal>
              <CustomModal
                show={isEditingLeftFooter}
                handleClose={handleCloseLeftFooter}
                heading={"Edit Left Footer Settings"}
              >
                <div className="form-group">
                  <input
                    type="text"
                    id="fontSize"
                    name="fontSize"
                    className="form-control "
                    placeholder="Enter font size in pixel"
                    value={editLeftFooterSize}
                    onChange={(e) => setEditLeftFooterSize(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <select
                    required
                    className="form-control my-2"
                    id="fontFamily"
                    name="fontFamily"
                    value={editLeftFooterFamily}
                    onChange={(e) => setEditLeftFooterFamily(e.target.value)}
                  >
                    <option value="sans-serif">Sans-Serif</option>
                    <option value="Roboto">Roboto</option>
                    <option value="serif">Serif</option>
                    <option value="arial">Arial</option>
                    <option value="Open Sans">Open Sans</option>
                    <option value="Futura">Futura</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Calibri">Calibri</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Palatino">Palatino</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control my-2"
                    id="background "
                    name="background "
                    placeholder="Enter background color "
                    value={editLeftFooterBackground}
                    onChange={(e) =>
                      setEditLeftFooterBackground(e.target.value)
                    }
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    id="fontColor"
                    className="form-control  my-2"
                    name="fontColor"
                    placeholder="Enter color"
                    value={editLeftFooterColor}
                    onChange={(e) => setEditLeftFooterColor(e.target.value)}
                  />
                  <ColorPicker />
                </div>
                <div className="modal-footer px-0">
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      padding: "5px 10px",
                    }}
                    className="btn btn-primary shadow-none modalSaveBtn"
                    onClick={handleSaveLeftFooter}
                  >
                    Save Changes
                  </button>
                  <button
                    style={{
                      color: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      background: "#fff",
                      fontWeight: 500,
                      padding: "5px 10px",
                    }}
                    type="button"
                    className="btn btn-secondary shadow-none modalCloseBtn"
                    onClick={handleCloseLeftFooter}
                  >
                    Close
                  </button>
                </div>
              </CustomModal>
              <CustomModal
                show={isEditingRightFooter}
                handleClose={handleCloseRightFooter}
                heading={"Edit Right Footer Settings"}
              >
                <div className="form-group">
                  <input
                    required
                    type="text"
                    id="fontSize"
                    name="fontSize"
                    className="form-control"
                    placeholder="Enter font size in pixel"
                    value={editRightFooterSize}
                    onChange={(e) => setEditRightFooterSize(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <select
                    required
                    className="form-control my-2"
                    id="fontFamily"
                    name="fontFamily"
                    value={editRightFooterFamily}
                    onChange={(e) => setEditRightFooterFamily(e.target.value)}
                  >
                    <option value="sans-serif">Sans-Serif</option>
                    <option value="Roboto">Roboto</option>
                    <option value="serif">Serif</option>
                    <option value="arial">Arial</option>
                    <option value="Open Sans">Open Sans</option>
                    <option value="Futura">Futura</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Calibri">Calibri</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Palatino">Palatino</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control my-2"
                    id="background "
                    name="background "
                    placeholder="Enter background color "
                    value={editRightFooterBackground}
                    onChange={(e) =>
                      setEditRightFooterBackground(e.target.value)
                    }
                  />
                </div>
                <div className="input-group">
                  <input
                    required
                    type="text"
                    id="fontColor"
                    className="form-control my-2"
                    name="fontColor"
                    placeholder="Enter color"
                    value={editRightFooterColor}
                    onChange={(e) => setEditRightFooterColor(e.target.value)}
                  />
                  <ColorPicker />
                </div>
                <div className="modal-footer px-0">
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      padding: "5px 10px",
                    }}
                    className="btn btn-primary btn-small shadow-none modalSaveBtn"
                    onClick={handleSaveRightFooter}
                  >
                    Save Changes
                  </button>
                  <button
                    style={{
                      color: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      background: "#fff",
                      fontWeight: 500,
                      padding: "5px 10px",
                    }}
                    type="button"
                    className="btn btn-secondary shadow-none modalCloseBtn"
                    onClick={handleCloseRightFooter}
                  >
                    Close
                  </button>
                </div>
              </CustomModal>
              <CustomModal
                show={isEditingPagination}
                handleClose={handleClosePagination}
                heading={"Edit Pagination Settings"}
              >
                <div className="form-group">
                  <input
                    required
                    type="text"
                    id="fontSize"
                    name="fontSize"
                    className="form-control"
                    placeholder="Enter font size in pixel"
                    value={editPaginationButtonSize}
                    onChange={(e) =>
                      setEditPaginationButtonSize(e.target.value)
                    }
                  />
                </div>

                <div className="input-group">
                  <select
                    required
                    className="form-control my-2"
                    id="fontFamily"
                    name="fontFamily"
                    value={editPaginationButtonFamily}
                    onChange={(e) =>
                      setEditPaginationButtonFamily(e.target.value)
                    }
                  >
                    <option value="sans-serif">Sans-Serif</option>
                    <option value="Roboto">Roboto</option>
                    <option value="serif">Serif</option>
                    <option value="arial">Arial</option>
                    <option value="Open Sans">Open Sans</option>
                    <option value="Futura">Futura</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Calibri">Calibri</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Palatino">Palatino</option>
                  </select>
                </div>
                <div className="input-group">
                  <input
                    required
                    type="text"
                    className="form-control "
                    id="background "
                    name="background "
                    placeholder="Enter background color "
                    value={editPaginationButtonBackground}
                    onChange={(e) =>
                      setEditPaginationButtonBackground(e.target.value)
                    }
                  />
                </div>
                <div className="input-group">
                  <input
                    required
                    type="text"
                    id="fontColor"
                    className="form-control my-2"
                    name="fontColor"
                    placeholder="Enter color"
                    value={editPaginationButtonColor}
                    onChange={(e) =>
                      setEditPaginationButtonColor(e.target.value)
                    }
                  />
                  <ColorPicker />
                </div>
                <div className="modal-footer px-0">
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      padding: "5px 10px",
                    }}
                    className="btn btn-primary shadow-none modalSaveBtn"
                    onClick={handleSavePagination}
                  >
                    Save Changes
                  </button>
                  <button
                    style={{
                      color: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      background: "#fff",
                      fontWeight: 500,
                      padding: "5px 10px",
                    }}
                    type="button"
                    className="btn btn-secondary shadow-none modalCloseBtn"
                    onClick={handleClosePagination}
                  >
                    Close
                  </button>
                </div>
              </CustomModal>
              <CustomModal
                show={isEditingLinks}
                handleClose={handleCloseLinks}
                heading={"Edit Links Settings"}
              >
                <div className="input-group" style={{ marginBottom: "10px" }}>
                  <input
                    style={{ background: "#f3f3f3" }}
                    type="number"
                    className="form-control"
                    name="links"
                    aria-label="Text input with dropdown button"
                    value={page}
                    onChange={(e) => setPage(e.target.value)}
                    placeholder="Enter showing links per page limit"
                  />
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "none",
                      borderRadius: "4px",
                    }}
                    type="button"
                    className="btn btn-primary shadow-none"
                    onClick={handleAddPageLimit}
                  >
                    ADD
                  </button>
                  <span
                    style={{
                      margin: "5px",
                      position: "relative",
                      zIndex: 100,
                    }}
                  >
                    <Tooltip title="Update showing links per page limit">
                      <FaInfoCircle
                        size={14}
                        cursor={"pointer"}
                        color="#4F8B33"
                      />
                    </Tooltip>
                  </span>
                </div>
                <div className="form-group">
                  <input
                    required
                    type="text"
                    id="fontSize"
                    name="fontSize"
                    className="form-control"
                    placeholder="Enter font size in pixel"
                    value={editLinksSize}
                    onChange={(e) => setEditLinksSize(e.target.value)}
                  />
                </div>

                <div className="input-group">
                  <select
                    required
                    className="form-control my-2"
                    id="fontFamily"
                    name="fontFamily"
                    value={editLinksFamily}
                    onChange={(e) => setEditLinksFamily(e.target.value)}
                  >
                    <option value="sans-serif">Sans-Serif</option>
                    <option value="Roboto">Roboto</option>
                    <option value="serif">Serif</option>
                    <option value="arial">Arial</option>
                    <option value="Open Sans">Open Sans</option>
                    <option value="Futura">Futura</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Calibri">Calibri</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Palatino">Palatino</option>
                  </select>
                </div>
                <div className="input-group">
                  <input
                    required
                    type="text"
                    className="form-control "
                    id="background "
                    name="background "
                    placeholder="Enter background color "
                    value={editLinksBackground}
                    onChange={(e) => setEditLinksBackground(e.target.value)}
                  />
                </div>
                <div className="input-group" style={{ marginTop: "10px" }}>
                  <input
                    required
                    type="text"
                    className="form-control "
                    id="borderRadius "
                    name="borderRadius "
                    placeholder="Enter border radius in pixel "
                    value={editLinksBorderRadius}
                    onChange={(e) => setEditLinksBorderRadius(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <input
                    required
                    type="text"
                    id="fontColor"
                    className="form-control my-2"
                    name="fontColor"
                    placeholder="Enter color"
                    value={editLinksColor}
                    onChange={(e) => setEditLinksColor(e.target.value)}
                  />
                  <ColorPicker />
                </div>
                <div className="modal-footer px-0">
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      padding: "5px 10px",
                    }}
                    className="btn btn-primary shadow-none modalSaveBtn"
                    onClick={handleSaveLinks}
                  >
                    Save Changes
                  </button>
                  <button
                    style={{
                      color: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                      background: "#fff",
                      fontWeight: 500,
                      padding: "5px 10px",
                    }}
                    type="button"
                    className="btn btn-secondary shadow-none modalCloseBtn"
                    onClick={handleCloseLinks}
                  >
                    Close
                  </button>
                </div>
              </CustomModal>
            </div>
          </div>
        </div>

        {/* Preview Component  */}
        <div
          className="previewSettingComponentDiv"
          style={{
            width: "50%",
            height: "100vh",
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        >
          <MobilePreview viewsetting={mainSettings} viewlinks={linkData} />
          {/* Add pasword modal */}
          <PasswordModal
            handleClose={handleCloseModal}
            open={open}
            userName={userName}
          />
          {/* Ganrate qr code */}
          <QRCodeModal
            open={openQRModal}
            handleClose={handleCloseQRModal}
            qrUrl={previewUrl}
            userName={userName}
          />
        </div>
      </div>
    </>
  );
};

export default Settings;
