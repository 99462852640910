import { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { message } from "antd";

export default function ColorPickers() {
  const ref = useRef();
  const [color, setColor] = useState("lightblue");
  const [hidden, setHidden] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const timerRef = useRef(null);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "-82px",
        left: "-13px",
        zIndex: "999",
      },
    },
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (hidden && ref.current && !ref.current.contains(e.target)) {
        setHidden(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [hidden]);

  const handleColorChange = (updatedColor) => {
    setColor(updatedColor.hex);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(updatedColor.hex)
          .then(() => {
            console.log("Copied");
            success("Copied");
          })
          .catch((err) => {
            console.error("Failed to copy color to clipboard", err);
            error("Failed to copy color to clipboard");
          });
      } else {
        console.error("Clipboard API not available.");
      }
    }, 500);
  };

  return (
    <div ref={ref}>
      {contextHolder}
      <div>
        {hidden && (
          <SketchPicker
            styles={pickerStyle}
            color={color}
            onChange={handleColorChange}
          />
        )}
      </div>

      <button
        style={{
          background: "#4F8B33",
          border: "1px solid #4F8B33",
        }}
        type="button"
        className="btn btn-primary my-2 modalSaveBtn"
        onClick={() => setHidden(!hidden)}
      >
        {hidden ? "Close Color Picker" : "Open Color Picker"}
      </button>
    </div>
  );
}
