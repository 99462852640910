import { useState, useContext, useEffect } from "react";
import { Button, Box, Typography, Container, IconButton } from "@mui/material";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import "./Auth.css";
import linkContext from "../../context/links/linkContext";

const Signup = (props) => {
  const context = useContext(linkContext);
  const { handleSubmit } = context;
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConformPassword = () => {
    setShowConformPassword(!showConformPassword);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          borderRadius: "16px",
          background:
            "linear-gradient(110deg, rgba(245, 253, 255, 0.2) 3.48%, rgba(255, 245, 255, 0.2) 81.52%)",
          alignItems: "center",
          // paddingTop: "65px",
        }}
      >
        <Box
          className="formContainer"
          sx={{
            height: "auto",
            display: "flex",
            justifyContent: "center ",
            gap: "100px",
            pt: "50px",
            pb: "50px",
          }}
        >
          <Container
            sx={{ width: "450px" }}
            component="main"
            maxWidth="xs"
            className="formControl"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "13px",
                  mb: 5,
                }}
              >
                <Box>
                  <img src="lock.png" alt="lock" />
                </Box>
                <Typography
                  component="h1"
                  variant="h5"
                  className="checkFormHeading"
                  sx={{ fontWeight: "500", color: "#0D0D0D", fontSize: "30px" }}
                >
                  Please Enter the Details{" "}
                  <span className="toSignUpText"> to Sign Up</span>
                </Typography>
              </Box>

              <Box
                component="form"
                onSubmit={(e) => handleSubmit(e, credentials, props)}
                sx={{ width: "100%" }}
              >
                <Box sx={{ mb: "25px" }}>
                  <input
                    className="signInInputs"
                    placeholder="Full Name*"
                    type="text"
                    name="name"
                    value={credentials.name}
                    onChange={onChange}
                    required
                  />
                </Box>
                <Box sx={{ mb: "25px" }}>
                  <input
                    className="signInInputs"
                    placeholder="Email Address*"
                    type="email"
                    name="email"
                    value={credentials.email}
                    onChange={onChange}
                    required
                  />
                  <Typography
                    className="secInfoText"
                    sx={{
                      color: "#6E7183",
                      fontSize: "14px",
                      ml: "38px",
                      mt: "4px",
                      fontWeight: 500,
                    }}
                  >
                    (We'll never share your email with anyone else.)
                  </Typography>
                </Box>

                <Box sx={{ position: "relative", mb: "25px" }}>
                  <input
                    className="signInInputs"
                    placeholder="Password*"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={credentials.password}
                    onChange={onChange}
                    minLength={5}
                    required
                  />
                  <Box
                    onClick={handleClickShowPassword}
                    sx={{
                      position: "absolute",
                      right: "3%",
                      bottom: "10px",
                    }}
                  >
                    <IconButton>
                      {showPassword ? (
                        <IoIosEye
                          size={20}
                          color="gray"
                          className="eyeIcon"
                          cursor="pointer"
                        />
                      ) : (
                        <IoIosEyeOff
                          size={20}
                          className="eyeIcon"
                          color="gray"
                          cursor="pointer"
                        />
                      )}
                    </IconButton>
                  </Box>
                </Box>

                <Box sx={{ position: "relative" }}>
                  <input
                    className="signupInputs"
                    placeholder="Confirm Password* "
                    type={showConformPassword ? "text" : "password"}
                    name="cpassword"
                    value={credentials.cpassword}
                    onChange={onChange}
                    minLength={5}
                    required
                  />
                  <Box
                    onClick={handleClickShowConformPassword}
                    sx={{
                      position: "absolute",
                      right: "3%",
                      bottom: "30px",
                    }}
                  >
                    {showConformPassword ? (
                      <IconButton>
                        <IoIosEye
                          size={20}
                          color="gray"
                          className="eyeIcon"
                          cursor="pointer"
                        />
                      </IconButton>
                    ) : (
                      <IconButton>
                        <IoIosEyeOff
                          size={20}
                          className="eyeIcon"
                          color="gray"
                          cursor="pointer"
                        />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                {/* Enter button */}
                <Box mt={5}>
                  <Button
                    className="enterButton"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mb: 5,
                      background:
                        credentials.name &&
                        credentials.email &&
                        credentials.password &&
                        credentials.cpassword
                          ? "#0FB3E2"
                          : "rgba(204, 200, 216, 0.25)",
                      color:
                        credentials.name &&
                        credentials.email &&
                        credentials.password &&
                        credentials.cpassword
                          ? "#fff"
                          : "#969FA1",
                      "&:hover": {
                        background:
                          credentials.name &&
                          credentials.email &&
                          credentials.password &&
                          credentials.cpassword
                            ? "#2FCCFF"
                            : "rgba(204, 200, 216, 0.25)",
                      },
                    }}
                  >
                    Enter
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
          {/* Right side container  */}
          <Box className="sideImgBox">
            <img src="/HomePage/themeImg.png" alt="Image" loading="lazy" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Signup;
