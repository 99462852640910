import React, { useContext, useEffect, useState } from "react";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { message } from "antd";
import linkContext from "../../../context/links/linkContext";
import Cookies from "js-cookie";

const PasswordModal = ({ open, handleClose, userName }) => {
  let host = "http://localhost:3000/api";
  if (document.location.href.includes("localhost")) {
    host = "http://localhost:3000/api";
  } else {
    host = "api";
  }

  let userInfo;
  const userInfoString = Cookies.get("userInfo");
  userInfo = userInfoString ? JSON.parse(userInfoString) : null;

  const context = useContext(linkContext);
  let { settings, getSettings } = context;
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (Array.isArray(settings)) {
      const data = settings?.filter((item) => item.userid === userName);
      setNewPassword(data[0]?.protectPassword);
    }
  }, [settings, userName]);

  useEffect(() => {
    getSettings();
  }, []);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (event) => {
    const id = userInfo && userInfo._id;
    event.preventDefault();

    try {
      const response = await fetch(`${host}/settings/protectPassword`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ newPassword, userName, id }),
      });

      const responseData = await response.json();
      if (response.ok) {
        setNewPassword("");
        handleClose();
        getSettings();
        success(responseData.message);
      } else {
        throw new Error(responseData.message);
      }
    } catch (err) {
      error("Something went wrong");
      console.log("Error:", err.message);
    }
  };

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "10px 20px",
  };

  return (
    <div>
      {contextHolder}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={style} className="modalContainer">
          <Typography
            style={{ fontWeight: "500", color: "#333" }}
            id="simple-modal-title"
            variant="h6"
            component="h2"
          >
            {newPassword ? "Edit Your Password" : "Add Your Password"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              mt: 0,
            }}
          >
            <TextField
              sx={{ position: "relative" }}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              value={newPassword}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setNewPassword(e.target.value)}
              id="password"
              autoComplete="current-password"
              InputLabelProps={{
                style: {
                  textTransform: "none",
                },
              }}
            />

            <Box
              onClick={handleClickShowPassword}
              sx={{
                position: "absolute",
                right: "35px",
                bottom: "87px",
              }}
            >
              {showPassword ? (
                <IoIosEye
                  size={20}
                  color="gray"
                  className="modalEyeIcon"
                  cursor="pointer"
                />
              ) : (
                <IoIosEyeOff
                  size={20}
                  color="gray"
                  className="modalEyeIcon"
                  cursor="pointer"
                />
              )}
            </Box>
            <Box sx={{ float: "inline-end", mt: 2 }}>
              <Button type="button" onClick={handleSubmit}>
                Save
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PasswordModal;
